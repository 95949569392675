import { cn } from '@bem-react/classname';
import { Translate } from 'localization';
import { Box, FormControlLabel } from 'muicomponents/src';
import { Checkbox } from 'muicomponents/src/Checkbox/Checkbox';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon } from 'muicomponents/src/Icons';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { TOption } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/ListParamsSelector.index';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TAdditionalPageFilter } from 'redux/search/interfaces';
import { checkResponseStatus } from 'utils/src';
import { list } from 'utils/src/requests/requests.tags';
import { IRequest } from 'utils/src/requests/service';
import { IFilterProps, SuggestersBox } from '../index';
import { FilterPagesUserSuggesterMui } from './FilterPages.styled';

export const cnClassName = cn('NewsSearchFilters');

type ISortFilters = {
    id: string;
    value: 'abc' | 'creationdate' | 'changedate' | 'position';
    sort: 'asc' | 'desc';
    title: string;
};

export type IShowWikiType = {
    id: string;
    label: string;
    value: 'all' | 'page' | 'wikilist' | 'url' | 'file';
};

const showWikiType: IShowWikiType[] = [
    { id: 'all', label: Translate.t({ i18nKey: 'pryaniky.list.wiki.filter.all' }), value: 'all' },
    { id: '1', label: Translate.t({ i18nKey: 'pryaniky.list.wiki.filter.pages' }), value: 'page' },
    { id: '2', label: Translate.t({ i18nKey: 'pryaniky.list.wiki.actions.folders' }), value: 'wikilist' },
    { id: '3', label: Translate.t({ i18nKey: 'pryaniky.list.wiki.actions.url' }), value: 'url' },
    { id: '4', label: Translate.t({ i18nKey: 'pryaniky.list.wiki.actions.file' }), value: 'file' },
];

const sortOption: TOption[] = [
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc_asc' }),
        value: 'abc_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc_desc' }),
        value: 'abc_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate_asc' }),
        value: 'creationdate_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate_desc' }),
        value: 'creationdate_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate_asc' }),
        value: 'changedate_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate_desc' }),
        value: 'changedate_desc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position_asc' }),
        value: 'position_asc',
    },
    {
        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position_desc' }),
        value: 'position_desc',
    },
];
let request: IRequest;

export const FilterPages: FC<IFilterProps<TAdditionalPageFilter>> = ({ filter, onChangeFilter, filterCleanButton }) => {
    const [categoriesState, setCategoriesState] = useState<any[]>([]);

    const getCategories = useCallback((search: string) => {
        if (request) request.ac.abort();
        request = list(0, 20, { search: search });
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                const dataArr = [
                    {
                        id: 'all',
                        label: Translate.t({ i18nKey: 'pryaniky.filter.wikilist.all' }),
                        type: 'catFilter',
                        value: 'all',
                    },
                    ...d.data.map((el: any) => ({
                        ...el,
                        label: Translate.t({ i18nKey: el.displayName }),
                        value: el.id,
                    })),
                ];
                return setCategoriesState(dataArr);
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, []);
    useEffect(() => {
        getCategories('');
    }, []);

    const materialTypeFilterComponent = useMemo(() => {
        return (
            <Box className={cnClassName('MatirealTypes')}>
                <CheckButtonSelector
                    value={(filter.materialType as any)?.value || 'all'}
                    onChange={(value) => {
                        if (value !== '') {
                            onChangeFilter((prev) => ({
                                ...prev,
                                materialType: showWikiType.find((el) => el.value === value),
                            }));
                        } else {
                            onChangeFilter((prev) => ({
                                ...prev,
                                materialType: showWikiType.find((el) => el.value === 'all'),
                            }));
                        }
                    }}
                    options={showWikiType as any}
                    defaultText={Translate.t({ i18nKey: 'pryaniky.LMS.tracks.trackStats.materialType' })}
                />
            </Box>
        );
    }, [filter]);

    const categoriesComponent = useMemo(() => {
        return (
            <>
                <CheckButtonSelector
                    value={(filter.categoryType as any)?.value || 'all'}
                    onChange={(value) => {
                        if (value !== '') {
                            onChangeFilter((prev) => ({
                                ...prev,
                                categoryType: categoriesState.find((el) => el.id === value),
                            }));
                        } else {
                            onChangeFilter((prev) => ({
                                ...prev,
                                categoryType: categoriesState.find((el) => el.id === 'all'),
                            }));
                        }
                    }}
                    options={categoriesState as any}
                    defaultText={Translate.t({ i18nKey: 'pryaniky.modal.wikipage.categories.filter' })}
                />
            </>
        );
    }, [categoriesState, filter]);

    const setSort = (item: string) => {
        const [key, value] = item.split('_');
        onChangeFilter((prev) => ({ ...prev, order: key as any, sort: value as any }));
    };

    const sortForceText = filter.order.startsWith('abc')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.abc' })
        : filter.order.startsWith('creationdate')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.creationdate' })
        : filter.order.startsWith('changedate')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.changedate' })
        : filter.order.startsWith('position')
        ? Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.position3' })
        : undefined;

    const sortComponent = useMemo(() => {
        return (
            <>
                <CheckButtonSelector
                    icon={filter.sort === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                    value={`${filter.order}_${filter.sort}`}
                    onChange={setSort}
                    forceText={sortForceText}
                    options={sortOption}
                    defaultText={Translate.t({ i18nKey: `pryaniky.shop.sort` })} //{i18n.t(`pryaniky.shop.sort${chosenSort ? `.${chosenSort}` : ''}`)}
                />
            </>
        );
    }, [filter]);

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() =>
                            onChangeFilter((prev) => ({ ...prev, searchOnlyTitle: !filter.searchOnlyTitle }))
                        }
                        checked={filter.searchOnlyTitle}
                    />
                }
                label={Translate.t({ i18nKey: 'pryaniky.list.wiki.search.in.name' })}
            />

            <SuggestersBox className={cnClassName('')}>
                {materialTypeFilterComponent}

                {sortComponent}

                {categoriesComponent}

                <FilterPagesUserSuggesterMui
                    TextFieldProps={{
                        label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.author' }),
                    }}
                    size={'small'}
                    value={filter.author}
                    onChange={(event, value: any) => onChangeFilter((prev) => ({ ...prev, author: value }))}
                    requestAdditionalParams={{ excludeMe: false }}
                    noAdornment={true}
                />
                {filterCleanButton}
            </SuggestersBox>
        </>
    );
};

// {/* <Box sx={{ margin: '10px 0' }}>
// <Typography><Translate i18nKey={'pryaniky.list.sortBy'} /></Typography>

// <SuggestersBox sx={{ alignItems: 'baseline', marginTop: '8px' }}>
//   {
//     sortFilterState.map((sortFilter, idx) => (
//       <Button
//         onClick={() => {
//           onChangeFilter((prev) => ({ ...prev, order: sortFilter.value }))
//           if (sortFilter.value === filter.order) {
//             setSort(sortFilter);
//             onChangeFilter((prev) => ({ ...prev, sort: filter.sort === 'asc' ? 'desc' : 'asc' }))
//           }
//         }}
//         sx={{
//           padding: "0 0 0 6px",
//           textTransform: "inherit",
//           fontSize: "16px",
//           fontWeight: "600",
//           color: "#000000cc",
//           backgroundColor: sortFilter.value === filter.order ? '#0066CC14' : ''
//         }}
//       >
//         {sortFilter.title}

//         {/* <IconButton
//           sx={{ padding: "5px", marginLeft: '5px' }}
//           onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//             event.stopPropagation();
//             onChangeFilter((prev) => ({ ...prev, sort: filter.sort === 'asc' ? 'desc' : 'asc' }))

//           }}
//         > */}
//         {sortFilter.sort === 'asc' ?
//           <ArrowDownwardIcon color={'action'} fontSize="small" /> :
//           <ArrowUpwardIcon color={'action'} fontSize="small" />}
//         {/* </IconButton> */}
//       </Button>
//     ))
//   }

// </SuggestersBox>
// </Box> */}
