/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import i18n from '../../../../localizations/i18n';
import { JFdictionary } from '../dictionary';

const { renderers } = JFdictionary;

export default {
    type: renderers.layout,
    elements: [
        {
            type: renderers.input,
            scope: '#/properties/name',
        },
        {
            type: renderers.number,
            scope: '#/properties/range',
        },
        {
            type: renderers.checkbox,
            label: i18n.t('pryaniky.widgets.settings.type.shop.noData'),
            scope: '#/properties/hideIfNoData',
        },
    ],
};
