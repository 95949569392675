import React, { FC, useState, useEffect } from 'react';
import { Translate } from 'localization';
import {
    GridItemBox,
    GridItemAvatar,
    GridItemButton,
    GridItemContent,
    GridItemDescription,
    GridItemCircleCount,
    GridItemTitle,
} from './GridItem.styled';
import { Avatar } from 'muicomponents/src/Avatar';
import { BadgeCountChip, Box, Typography } from 'muicomponents/src';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn';
import { IBadge, cnProfileBadgeTabList } from '../UserBadges.index';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Button } from 'muicomponents/src';
import { abortableFetch } from 'utils/src/requests.v4/abortableFetch';

export const GridItem: FC<{ badge: IBadge; countColumns: number; onRemove?: (arg: string) => void }> = ({
    badge,
    countColumns,
    onRemove,
}) => {
    const [badgeCount, setbBdgeCount] = useState<number>(badge.count);

    useEffect(() => {
        setbBdgeCount(badge.count);
    }, [badge.count]);
    return (
        <GridItemBox countColumns={countColumns} className={cnProfileBadgeTabList('GridItemBox')}>
            <GridItemButton href={`/badge/${badge.badgeUid}`}>
                <Box sx={{ position: 'relative' }}>
                    <GridItemAvatar src={badge.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
                </Box>
                <Box sx={{ position: 'absolute', bottom: '8px', left: '8px' }}>
                    {Boolean(badge.count) && badge.count > 1 && <BadgeCountChip countNumber={badgeCount} />}
                </Box>
            </GridItemButton>

            <GridItemContent>
                <GridItemTitle href={`/badge/${badge.badgeUid}`}>
                    <Tooltip title={badge?.name?.length > 20 ? badge.name : ''}>
                        <span>{badge.name}</span>
                    </Tooltip>
                </GridItemTitle>
                <GridItemDescription>
                    <Tooltip title={badge?.description?.length > 45 ? badge.description : ''}>
                        <span>{badge.description}</span>
                    </Tooltip>
                </GridItemDescription>

                {onRemove && (
                    <Button
                        sx={{ width: '100%', marginTop: '8px' }}
                        onClick={() => onRemove(badge.badgeUid)}
                        variant={'outlined'}
                        color={'primary'}
                        className={cnProfileBadgeTabList('GridItemBox-DeleteButton')}
                        children={<Translate i18nKey={'pryaniky.profile.badge.tab.list.badge.delete'} />}
                    />
                )}
            </GridItemContent>
        </GridItemBox>
    );
};
