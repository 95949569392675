import { cnWidgetMui } from "blocks/Widget/Widget.index";
import { Translate } from "localization";
import moment from "moment";
import { Box, UsersSuggester } from "muicomponents/src";
import { DateRangePicker } from "muicomponents/src/DatePicker";
import React, { ComponentProps, FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SSelectableObject } from "utils/src";
import { getCurrentUser } from "utils/src/CommonRedux/base/selectors";
import { UsersEventesHead } from "./Head/UsersEventesHead";
import { initUsersEventsTableAddSettings, initUsersEventsTableSettings } from "./Settings/UsersEventsTableSettings";
import { UserEventsTable } from "./Table/UsersEventsTable";
import { UsersEventsContextProvider } from "./UsersEvents.context";
import { HeadBottomBox, HeadBox, TableBox } from "./UsersEvents.styled";

initUsersEventsTableAddSettings();
initUsersEventsTableSettings();

export const UsersEvents: FC<ComponentProps<typeof UsersEventsContextProvider> & {
}> = ({
    calendars,
    settings,
    userId,
    cIds
}) => {

        const currentUser = useSelector(getCurrentUser);
        const showUserSuggester = useMemo(() => {
            const fields: any = currentUser?.profileData?.userInfoFields?.entities?.fields ?? {};
            const founded = Object.values(fields).find((field: any) => field?.field.fieldType === 'SubstituteForUser') as any;
            return founded?.users?.length || false;
        }, [currentUser?.profileData?.userInfoFields]);

        const [user, setUser] = useState<SSelectableObject | null>(null);
        const [dates, setDates] = useState<ComponentProps<typeof DateRangePicker>['value']>([moment().toISOString(), moment().add(1, 'month').toISOString()]);

        const prepairedUserId = useMemo(() => {
            return user?.id || userId;
        }, [userId, user?.id]);

        return (
            <UsersEventsContextProvider userId={prepairedUserId} cIds={cIds} calendars={calendars} settings={settings}>
                <HeadBox className={cnWidgetMui()}>
                    <UsersEventesHead />
                    <HeadBottomBox>
                        <DateRangePicker value={dates} onChange={(value) => setDates(value)} />
                        {
                            showUserSuggester
                            && <UsersSuggester
                                fullWidth
                                value={user}
                                onChange={(e, value) => {
                                    if (!Array.isArray(value)) {
                                        setUser(value);
                                    }
                                }}
                                requestAdditionalParams={{
                                    module: 'substituteByMeUsers'
                                }}
                                TextFieldProps={{
                                    label: <Translate i18nKey={'pryaniky.usersevents.header.substitute'} />,
                                    sx: {
                                        maxWidth: '400px'
                                    }
                                }}
                            />
                        }
                    </HeadBottomBox>
                </HeadBox>
                <TableBox className={cnWidgetMui()}>
                    <UserEventsTable dateEnd={dates[1] as string} dateStart={dates[0] as string} />
                </TableBox>
            </UsersEventsContextProvider>
        )
    };