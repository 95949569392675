import urls, { exactPathnames } from "./urls";
import { RouteProps, Redirect } from 'react-router';
import { Dash } from 'blocks/Dash/Dash';
import { Users } from 'blocks/Users/Users';
import { User } from 'blocks/User/User';
import { Invite } from 'blocks/Invite/Invite';
import { Groups } from 'blocks/Groups/Groups';
import { Group } from 'blocks/Group/Group';
import { Messages } from 'blocks/Messages/Messages';
import { News } from 'blocks/News/News';
import { Tag } from 'blocks/Tag/Tag';
import { Badge } from 'blocks/Badge/Badge';
import { Rating } from 'blocks/Rating/Rating';
import { MountainRating } from 'blocks/MountainRating/MountainRating';
import { Shop } from 'blocks/Shop/Shop';
import { Orders } from 'blocks/Orders/Orders';
import { Logout } from 'blocks/Logout/Logout';
import { Birthdays } from 'blocks/Birthdays/Birthdays';
import { NotificationsSettings } from 'blocks/NotificationsSettings/NotificationsSettings';
import { PagesNew } from 'blocks/Pages/PagesNew/PagesNew';
import { Widgets } from 'blocks/Widgets/Widgets';
import { Ideas } from 'blocks/Ideas/Ideas';
import { Competitions } from 'blocks/Pages/Competitions/Competitions';

import { Courses } from '../LMSModule/LMS/List/Courses';
import { Course } from '../LMSModule/LMS/Course/Course';
import { Course as LmsTest } from '../LMSModule/LMS/Single/Course';
import { Results } from '../LMSModule/LMS/Results/Results';
import { LMSTeacherOffice } from '../LMSModule/LMSTeacherOffice/LMSTeacherOffice/LMSTeacherOffice';
import LmsStatView from '../LMSModule/LMSTeacherOffice/StatisticsFull/StatView';
import LmsFullStatUser from '../LMSModule/LMSTeacherOffice/StatisticsFull/Users/User';
import { CourseView } from '../LMSModule/LMSTeacherOffice/CourseView/CourseView';
import { CourseCreate } from '../LMSModule/LMSTeacherOffice/CourseCreate/CourseCreate';
import ManageTrack from '../LMSModule/LMSTeacherOffice/ManageTrack';
import TrackView from '../LMSModule/LMS/TrackView';
import TrackListUser from '../LMSModule/LMS/TrackList';
import TrackList from '../LMSModule/LMSTeacherOffice/TracksList';

import { PhotoalbumContainer } from 'blocks/PhotoalbumContainer/PhotoalbumContainer';
import { AlbumView } from 'blocks/PhotoalbumContainer/AlbumView';
import { Integrate } from 'blocks/Integrate/Integrate';
import { WDialog } from 'blocks/WDialog/WDialog';
import { questsmoderate } from 'blocks/QuestsModerate/QuestsModerate';
import { Events } from 'blocks/Events/Events';
import { Polls } from 'blocks/Polls/Polls';
import { CreativeTasks } from 'blocks/CreativeTasks/CreativeTasks';
import { Calendar } from "blocks/Calendar/Calendar";
import { Notices } from "blocks/Notices/Notices";
import { Page } from "blocks/Page/Page";
import { wikilist } from "blocks/WIKIList/WIKIList";
import { Search } from 'blocks/Search/Search';
import { SearchMui } from 'blocks/SearchMui/SearchMui';
import { Quiz } from 'blocks/Quiz/Quiz';
import { ProductCard } from 'blocks/Shop/ProductCard/ProductCard';
import { NotFound } from 'blocks/NotFound/NotFound';
import { Error500 } from 'blocks/500/500';
import { NotifiSettings } from 'blocks/NotifiSettings/NotifiSettings';
import { Welcome } from '../blocks/Welcome/Welcome';
import { QuestsPage } from 'blocks/QuestsPage/QuestsPage';
import { OrgChartTree } from 'blocks/Orgchart/Orgchart';
import { Workflows } from 'blocks/Workflows/Workflows';
import { Dashboard } from 'blocks/Dashboard/Dashboard';
import { SecretSantaWidgetPage } from 'blocks/SecretSantaPage/SecretSantaWidgetPage';
import { StatisticsPage } from 'blocks/StatisticsPage/StatisticsPage';
// import { Survey } from 'blocks/Survey/Survey';
import { Survey } from 'blocks/SurveyMui/Survey';
import { BadgesPage } from "blocks/BadgesPage/BadgesPage";
import { VacanciesPage } from "blocks/Vacancies/Pages/VacanciesPage";
import { VacancyPage } from "blocks/Vacancies/Pages/VacancyPage";
import { TValue } from 'utils/src';
import { UserNew } from "blocks/UserMui5/User";
import { ProfileEdit } from "blocks/InfoBlock/UserNew/_edit/ProfileEdit";
import { AchievementsPage } from "blocks/Achievements";
import { DevelopPage } from 'blocks/_Develop';
import { ProductCardMui } from "blocks/ShopMui/ProductCardMui/ProductCardMui";
import { pageNames } from "redux/pageNames";
import { DeviceInfo } from 'blocks/DeviceInfo';
import { ExternalRedirect } from "blocks/ExternalRedirect";

interface IPageItem {
  c: React.ComponentType<any>;
  props?: Record<string, any>;
}

interface IRoute {
  path: TValue<typeof urls>;
  props?: RouteProps;
  item: IPageItem;
  accessOnlyAdmin?: boolean;
  pageName?: string;
}

export const routes: IRoute[] = [
  {
    path: urls.deviceInfo,
    item: {
      c: DeviceInfo
    }
  },
  {
    path: urls._widgetdebug,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames._widgetdebug
        }
      }
    }
  },
  {
    path: urls._develop,
    item: {
      c: DevelopPage
    }
  },
  {
    path: urls._appsDebug,
    item: {
      c: Page,
      props: {
        className: 'appsDebug',
        page: {
          name: pageNames._appsDebug
        }
      }
    }
  },
  {
    path: urls.dash,
    item: {
      c: Dash
    }
  },
  {
    path: urls.users,
    item: {
      c: Users
    }
  },
  {
    path: urls.user,
    item: {
      c: User
    }
  },
  {
    path: urls.profileEdit,
    item: {
      c: ProfileEdit
    }
  },
  {
    path: urls.userNew,
    item: {
      c: UserNew
    }
  },
  {
    path: urls.invite,
    item: {
      c: Invite
    }
  },
  {
    path: urls.groups,
    item: {
      c: Groups
    }
  },
  {
    path: urls.group,
    item: {
      c: Group
    }
  },
  {
    path: urls.messages,
    item: {
      c: Messages
    }
  },
  {
    path: urls.news,
    item: {
      c: News
    }
  },
  {
    path: urls.tag,
    item: {
      c: Tag
    }
  },
  {
    path: urls.badge,
    item: {
      c: Badge
    }
  },
  {
    path: urls.notificationsSettings,
    item: {
      c: NotificationsSettings
    }
  },
  {
    path: urls.pages,
    item: {
      c: PagesNew
    }
  },
  {
    path: urls.rating,
    item: {
      c: Rating
    }
  },
  {
    path: urls.mountainRating,
    item: {
      c: MountainRating
    }
  },
  {
    path: urls.shop,
    item: {
      c: Shop
    }
  },
  {
    path: urls.orders,
    item: {
      c: Orders
    }
  },
  {
    path: urls.competitions,
    item: {
      c: Competitions
    }
  },
  {
    path: urls.ideas,
    item: {
      c: Ideas
    }
  },
  {
    path: urls.birthdays,
    item: {
      c: Birthdays
    }
  },
  {
    path: urls.birthdaysNoMonth,
    item: {
      c: Redirect,
      props: {
        to: `${urls.birthdaysNoMonth}/${new Date().getMonth() + 1}`
      }
    }
  },
  {
    path: urls.lms,
    item: {
      c: Courses
    }
  },
  {
    path: urls.lmsTest,
    item: {
      c: LmsTest
    }
  },
  {
    path: urls.lmsResult,
    item: {
      c: Results
    }
  },
  {
    path: urls.lmsCourse,
    item: {
      c: Course
    }
  },
  {
    path: urls.lmsTeacherCourse,
    item: {
      c: CourseView
    }
  },
  {
    path: urls.lmsCourseCreate,
    item: {
      c: CourseCreate
    }
  },
  {
    path: urls.lmsTeacherOffice,
    item: {
      c: LMSTeacherOffice
    }
  },
  {
    path: urls.lmsStatistics,
    item: {
      c: LmsStatView
    },
    accessOnlyAdmin: true
  },
  {
    path: urls.lmsStatisticsUser,
    item: {
      c: LmsFullStatUser
    },
    accessOnlyAdmin: true
  },
  {
    path: urls.tracksManage,
    item: {
      c: TrackList
    }
  },
  {
    path: urls.trackManage,
    item: {
      c: ManageTrack
    }
  },
  {
    path: urls.trackView,
    item: {
      c: TrackView
    }
  },
  {
    path: urls.learningTracks,
    item: {
      c: TrackListUser
    }
  },
  {
    path: urls.photoalbum,
    item: {
      c: PhotoalbumContainer
    }
  },
  {
    path: urls.album,
    item: {
      c: AlbumView
    }
  },
  {
    path: urls.widgets,
    item: {
      c: Widgets
    }
  },
  {
    path: urls.integrate,
    item: {
      c: Integrate
    }
  },
  {
    path: urls.wdialogs,
    item: {
      c: WDialog
    }
  },
  {
    path: urls.questsmoderate,
    item: {
      c: questsmoderate
    }
  },
  {
    path: urls.events,
    item: {
      c: Events
    }
  },
  {
    path: urls.polls,
    item: {
      c: Polls
    }
  },
  {
    path: urls.creativetasks,
    item: {
      c: CreativeTasks
    }
  },
  {
    path: urls.calendar,
    item: {
      c: Page,
      props: {
        className: 'CalendarPage',
        page: {
          name: pageNames.calendar
        }
      }
    }
  },
  {
    path: urls.settingsDesign,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.settingsDesign
        }
      }
    }
  },
  {
    path: urls.notices,
    item: {
      c: Notices
    }
  },
  {
    path: urls.wikilist,
    item: {
      c: wikilist
    }
  },
  {
    path: urls.board,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.board
        }
      }
    }
  },
  {
    path: urls.search,
    item: {
      c: SearchMui,
    }
  },
  {
    path: urls.quiz,
    item: {
      c: Quiz,
    }
  },
  {
    path: urls.shopProduct,
    item: {
      c: ProductCardMui,
    }
  },
  {
    path: urls.notifiSettings,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.notifiSettings
        }
      }
    }
  },
  {
    path: urls.notfound,
    item: {
      c: NotFound,
    }
  },
  {
    path: urls.error500,
    item: {
      c: Error500
    }
  },
  {
    path: urls.welcome,
    item: {
      c: Welcome
    }
  },
  {
    path: urls.quests,
    item: {
      c: QuestsPage
    }
  },
  {
    path: urls.workflows,
    item: {
      c: Workflows
    }
  },
  {
    path: urls.orgchart,
    item: {
      c: OrgChartTree
    }
  },
  {
    path: urls.dashboard,
    item: {
      c: Dashboard
    }
  },
  {
    path: urls.secretsanta,
    item: {
      c: SecretSantaWidgetPage
    }
  },
  {
    path: urls.statistics,
    item: {
      c: StatisticsPage
    }
  },
  {
    path: urls.survey,
    item: {
      c: Survey,
    }
  },
  {
    path: urls.badges,
    item: {
      c: BadgesPage
    }
  },
  {
    path: urls.vacancies,
    item: {
      c: VacanciesPage
    }
  },
  {
    path: urls.vacancy,
    item: {
      c: VacancyPage
    }
  },
  {
    path: urls.achievements,
    item: {
      c: AchievementsPage
    }
  },
  {
    path: urls.widgets_v2,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.widgets_v2
        }
      }
    }
  },
  {
    path: urls.widgets_horizontal_v2,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.widgets_horizontal_v2
        }
      }
    }
  },
  {
    path: urls.external,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.external
        }
      }
    }
  },
  {
    path: urls.tasks,
    item: {
      c: Page,
      props: {
        page: {
          name: pageNames.tasks
        }
      }
    }
  },
  {
    path: urls.externalRedirect,
    item: {
      c: ExternalRedirect
    }
  }
].map((el: IRoute) => {
  if (!el.props) el.props = {};
  if (typeof el.path === 'string') {
    el.props.exact = exactPathnames.includes(el.path)
  }
  return el;
})

export default routes