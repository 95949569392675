import { put, takeEvery } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';

import {
    GET_MY_INFO,
    GET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST,
    GET_SECRET_SANTA_WIDGET_SETTINGS,
    GET_USER_TO_GIVE_PRESENT,
    IS_PRESENT_SENT,
    IS_USER_SECRET_SANTA_PARTICIPATE,
    MAIN_SECRET_SANTA_SETTINGS,
    PRESENT_SENT,
    SECRET_SANTA_JOIN,
    SECRET_SANTA_UN_JOIN,
    SSPastisipantsListToggle,
    setIsInfoLoading,
    setIsPresentSentAction,
    setMyInfo,
    setNoRights,
    setSSParticipantsListToRedux,
    setSecretSantaWidgetSettings,
    setUserDistributedToggleAction,
    setUserParticipateAction,
    setUserToGivePresent,
} from './actions';

import {
    IGetMainSecretSantaSettings,
    IGetMyInfoAction,
    IGetSecretSantaListWidgetSettings,
    IGetSecretSantaParticipantsPageListAction,
    IGetUserToGivePresentAction,
    IIsPresentSentAction,
    IIsUserParticipateAction,
    IPresentSentAction,
    ISecretSantaJoinAction,
    ISecretSantaUnJoinActionAction,
} from './actions.interfaces';

import { getPartisipantsList, getSecretSantaJoinInfo, getSecretSantaWidgetSettings } from './selectors';

import {
    ISecretSantaIsParticipateRespons,
    ISecretSantaListRespons,
    ISecretSantaResponse,
    ISecretSantaResponses,
    ISecretSantaUserToGivePresentRespons,
} from 'utils/src/requests/models/admin.secretSanta';

import { Actions } from '@jsonforms/core';
import { JFdictionary } from 'blocks/WSettings/structures/dictionary';
import uischema from 'blocks/WSettings/structures/secretSanta/uischema';
import i18n from 'localizations/i18n';
import { toast } from 'react-toastify';
import {
    getSecreteSantaListNew,
    getSecreteSantaMain,
    getSecreteSantaParticipants,
    getUserToGivePresentApi,
    giftSend,
    isGiftSend,
    isUserParticipate,
    secreteSantaJoin,
    secreteSantaUnJoin,
} from 'utils/src/requests/admin.secreteSanta';
import { IBasicResponse } from 'utils/src/requests/models/api.base';
import { checkResponseStatus, getErrorText } from 'utils/src/utils';

const { renderers, types } = JFdictionary;

function* getSantaParticipantsListFromServer(action: IGetSecretSantaParticipantsPageListAction) {
    try {
        // const listData = yield* select(getJournalDataSelector);
        const { queryString } = action.payload;

        // yield put(journalToggle('isFinished', false));
        yield put(SSPastisipantsListToggle({ data: { variable: 'isLoading', value: true }, id: queryString.drawId }));
        const response: ISecretSantaResponses.ISecretSantaParticipantsListResponse = yield* call(
            getSecreteSantaParticipants,
            queryString
        );
        if (checkResponseStatus(response)) {
            let data = response.data;
            let count = 10;
            let isFinished = response.data.length < count;
            yield put(
                SSPastisipantsListToggle({
                    data: { variable: 'isFinished', value: isFinished },
                    id: queryString.drawId,
                })
            );
            const oldData: any = yield* select(getPartisipantsList(queryString.drawId));
            data = [...(oldData || []), ...data];
            yield put(setSSParticipantsListToRedux({ data, id: queryString.drawId }));
            // yield put(SSPastisipantsListToggle('isFinished', data.isFinished));
        } else {
            toast.error(i18n.t('pryaniky.list.journal.error.text'));
            yield put(
                SSPastisipantsListToggle({ data: { variable: 'isFinished', value: true }, id: queryString.drawId })
            );
        }
        yield put(SSPastisipantsListToggle({ data: { variable: 'isLoading', value: false }, id: queryString.drawId }));

        // yield put(journalToggle('isFinished', data.data.length < pageCount));
    } catch (e) {
        console.warn('get secret santa participants error', e);
    }
}

// получает настройки виджета тайного санты
function* getSecretSantaSaga(action: IGetSecretSantaListWidgetSettings) {
    try {
        const { count, skipCount, context } = action.payload;
        const response: ISecretSantaListRespons = yield* call(getSecreteSantaListNew, skipCount, count, context);

        if (checkResponseStatus(response)) {
            // почему то не соответствует JsonSchema...
            const schema: any = {
                type: types.object,
                properties: {
                    type: {
                        type: types.string,
                        placeholder: i18n.t('pryaniky.widgets.settings.placeholder.chooseCompetition'),
                        opts: response.data.map((e: any) => ({
                            title: e.displayName,
                            value: e,
                        })),
                        data: [],
                        require: true,
                    },
                },
            };
            const secretSantaData = {
                type: '',
            };
            if (response.data.length > 0) {
                yield put(
                    Actions.init(
                        Object.keys(secretSantaData).reduce(
                            (acc: any, cur: any) => ({ ...acc, [cur]: response.data && response.data[cur] }),
                            {} as any
                        ),
                        schema,
                        uischema
                    )
                );
            }
            if (response.data.length === 0) {
                toast.error(i18n.t('pryaniky.administration.no.santa.widgets1'));
            }
        } else {
            if (response.error_code === 27007) {
                toast.error(i18n.t('pryaniky.administration.no.santa.widgets1'));
                yield put(
                    Actions.init(
                        {
                            empty: '',
                        },
                        {
                            type: types.object,
                            properties: {},
                        },
                        {
                            type: renderers.layout,
                            elements: [
                                {
                                    type: renderers.textInfo,
                                    scope: '#/properties/empty',
                                    text: i18n.t('pryaniky.administration.no.santa.widgets1'),
                                },
                            ],
                        } as any
                    )
                );
            } else {
                toast.error(getErrorText(response));
            }
        }
    } catch (e) {
        console.error('get SecretSanta Widget settings from server error', e);
    }
}

function* getSecretSantaMainSaga(action: IGetMainSecretSantaSettings) {
    try {
        yield put(setIsInfoLoading({ id: action.payload, isLoading: true }));
        const response: ISecretSantaResponse = yield* call(getSecreteSantaMain, action.payload);

        if (checkResponseStatus(response)) {
            yield put(setSecretSantaWidgetSettings({ data: response.data, id: action.payload }));

            yield put(setIsInfoLoading({ id: action.payload, isLoading: false }));

            const responseIsPart: ISecretSantaIsParticipateRespons = yield* call(isUserParticipate, {
                drawId: response.data.id,
            });
            if (checkResponseStatus(responseIsPart)) {
                yield put(setUserParticipateAction({ id: action.payload, data: responseIsPart.data }));

                // если дата будет тру сразу запросим данные которые оставлял о себе юзер и поместим их в descriptionGift
                if (responseIsPart.data) {
                    // сразу проверяем был ли скрыт виджет(отправлен подарок) что бы предотваратить мелькание виджета COMMON-7458
                    const respIsPredsentSend: ISecretSantaIsParticipateRespons = yield* call(isGiftSend, {
                        drawId: response.data.id,
                    });
                    if (checkResponseStatus(respIsPredsentSend)) {
                        yield put(setIsPresentSentAction({ data: respIsPredsentSend.data, id: action.payload }));
                    } else {
                        toast.error(getErrorText(responseIsPart));
                    }
                    if (respIsPredsentSend.data === false) {
                        // и запрашиваем данные юзеров
                        const responseMyData = yield* call(getUserToGivePresentApi, { drawId: response.data.id });
                        const respMyData = responseMyData as any as ISecretSantaUserToGivePresentRespons;
                        if (checkResponseStatus(respMyData)) {
                            // помещаем данные самого юзера в стэйт(нужно для дальнейшего редактирования данных)
                            if (respMyData.data.result.descriptionGift)
                                yield put(
                                    setMyInfo({ data: respMyData.data.result.descriptionGift, id: action.payload })
                                );

                            // если есть юзер которому дарит подарок санта сразу записываем его в через редюсер в стэйт
                            if (respMyData.data.result.toUser) {
                                yield put(
                                    setUserToGivePresent({ data: respMyData.data.result.toUser, id: action.payload })
                                );
                                yield put(
                                    setUserDistributedToggleAction({
                                        data: { variable: 'isDistributLoading', value: false },
                                        id: action.payload,
                                    })
                                );
                            }
                        } else {
                            toast.error(getErrorText(responseIsPart));
                        }
                    }
                } else {
                    // не участиник тайного санты ставлю false на вопрос отправлен ли подарок. тогда виджет сможет отображаться для неучастников,
                    // пока таймер не посчитает, что время дистрибьюции прошло
                    // минус в том что для не участников виджет все равно будет проскальзывать
                    yield put(setIsPresentSentAction({ data: false, id: action.payload }));
                }
            } else {
                toast.error(getErrorText(response));
                yield put(setIsInfoLoading({ id: action.payload, isLoading: false }));
            }
        } else {
            yield put(setNoRights({ id: action.payload, noRights: response.error_code }));
            if (response.error_code !== 27009 && response.error_code !== 27007) {
                toast.error(getErrorText(response));
                yield put(setIsInfoLoading({ id: action.payload, isLoading: false }));
            }
            // if () toast.error(getErrorText(response));
            if (response.error_code === 27007 || response.error_code === 27009) {
                console.error('У вас нет прав');
                yield put(setIsInfoLoading({ id: action.payload, isLoading: false }));
            }
        }
    } catch (e) {
        console.error('get SecretSanta main from server error', e);
    }
}

// узнать собственные данные это я использую для обнуления
// данных пользователя если он вызвал окно редактирования внес изменения а потом нажал отмена
function* getMyInfoSaga(action: IGetMyInfoAction) {
    try {
        const secretSantaId = yield* select(getSecretSantaWidgetSettings(action.payload));
        if (!secretSantaId) return;
        const resp = yield* call(getUserToGivePresentApi, { drawId: secretSantaId.id });
        const response = resp as any as ISecretSantaUserToGivePresentRespons;

        if (checkResponseStatus(response)) {
            yield put(setMyInfo({ data: response.data.result.descriptionGift, id: action.payload }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta join settings from server error', e);
    }
}

// отправляет запрос на участие в тайном санте
function* secreteSantaJoinSaga(action: ISecretSantaJoinAction) {
    try {
        const secretSantaId = yield* select(getSecretSantaWidgetSettings(action.payload));
        if (!secretSantaId) return;
        const joinInfo = yield* select(getSecretSantaJoinInfo(action.payload));
        if (!joinInfo) return;
        const response: IBasicResponse = yield* call(secreteSantaJoin, secretSantaId.id, joinInfo);

        if (checkResponseStatus(response)) {
            yield put(setUserParticipateAction({ id: action.payload, data: true }));
            toast.success(i18n.t('pryaniky.administration.toast.success'));
            const responsData: ISecretSantaResponse = yield* call(getSecreteSantaMain, action.payload);
            if (checkResponseStatus(responsData)) {
                yield put(setSecretSantaWidgetSettings({ data: responsData.data, id: secretSantaId.id }));
            } else {
                toast.error(getErrorText(response));
            }
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta join settings from server error', e);
    }
}

// отменяет запрос на участие в тайном санте
function* secreteSantaUnJoinSaga(action: ISecretSantaUnJoinActionAction) {
    try {
        const secretSantaId = yield* select(getSecretSantaWidgetSettings(action.payload));
        if (!secretSantaId) return;
        // const joinInfo: ISecretSantaDescriptionGift = yield* select(getSecretSantaJoinInfo);
        const response: IBasicResponse = yield* call(secreteSantaUnJoin, secretSantaId.id);

        if (checkResponseStatus(response)) {
            yield put(setUserParticipateAction({ id: action.payload, data: false }));
            yield put(
                setMyInfo({
                    data: {
                        deliveryAddress: '',
                        deliveryComment: '',
                        deliveryPhone: '',
                    },
                    id: action.payload,
                })
            );
            toast.success(i18n.t('pryaniky.administration.toast.success'));
            const responsData: ISecretSantaResponse = yield* call(getSecreteSantaMain, action.payload);
            if (checkResponseStatus(responsData)) {
                yield put(setSecretSantaWidgetSettings({ data: responsData.data, id: secretSantaId.id }));
            } else {
                toast.error(getErrorText(response));
            }
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta join settings from server error', e);
    }
}

// проверяет участие в тайном санте
function* isUserParticipateSaga(action: IIsUserParticipateAction) {
    try {
        const { queryString } = action.payload;
        const response: ISecretSantaIsParticipateRespons = yield* call(isUserParticipate, queryString);

        if (checkResponseStatus(response)) {
            yield put(setUserParticipateAction({ id: queryString.drawId, data: response.data }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta Widget settings from server error', e);
    }
}

// плучает юзера которому отправить надо подарок теперь это по сути вызывается в том
// случае если юзер зашел на страницу до распределения и не перезагружал страницу после.
function* getUserToGivePresentSaga(action: IGetUserToGivePresentAction) {
    try {
        const { queryString } = action.payload;
        const resp = yield* call(getUserToGivePresentApi, queryString);
        const response = resp as any as ISecretSantaUserToGivePresentRespons;

        if (checkResponseStatus(response)) {
            // toast.success(i18n.t('pryaniky.administration.toast.success'))
            if (response.data.result.toUser) {
                yield put(setUserToGivePresent({ data: response.data.result.toUser, id: queryString.drawId }));
                // сообщает о том что юзер записался в стейт и загрузка закончена
                yield put(
                    setUserDistributedToggleAction({
                        data: { variable: 'isDistributLoading', value: false },
                        id: queryString.drawId,
                    })
                );
            } else {
                // должно сработать в том случае если юзер по каким-то прчином не получен
                // при первом запросе на сервер(распределение задержалось на бэке)
                yield put(
                    setUserDistributedToggleAction({
                        data: { variable: 'isDistributLoading', value: true },
                        id: queryString.drawId,
                    })
                );
            }
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta getUserToGivePresentSagafrom server error', e);
    }
}

// проверяетс было ли заявлено что подарок отправлен
function* isGiftSentSaga(action: IIsPresentSentAction) {
    try {
        const { queryString } = action.payload;
        const response: ISecretSantaIsParticipateRespons = yield* call(isGiftSend, queryString);

        if (checkResponseStatus(response)) {
            yield put(setIsPresentSentAction({ data: response.data, id: queryString.drawId }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta Widget settings from server error', e);
    }
}

// отправляет запрос на подтверждение отправки подарка
function* presentSentConfirmSaga(action: IPresentSentAction) {
    try {
        const secretSantaId = yield* select(getSecretSantaWidgetSettings(action.payload));
        if (!secretSantaId) return;
        const response: IBasicResponse = yield* call(giftSend, secretSantaId.id);

        if (checkResponseStatus(response)) {
            yield put(setIsPresentSentAction({ data: true, id: secretSantaId.id }));
        } else {
            toast.error(getErrorText(response));
        }
    } catch (e) {
        console.error('get SecretSanta confirm send present action settings from server error', e);
    }
}
const SecretSanta = function* SecretSanta() {
    yield takeEvery(GET_SECRET_SANTA_WIDGET_SETTINGS, getSecretSantaSaga);
    yield takeEvery(IS_USER_SECRET_SANTA_PARTICIPATE, isUserParticipateSaga);
    yield takeEvery(SECRET_SANTA_JOIN, secreteSantaJoinSaga);
    yield takeEvery(SECRET_SANTA_UN_JOIN, secreteSantaUnJoinSaga);
    yield takeEvery(GET_USER_TO_GIVE_PRESENT, getUserToGivePresentSaga);
    yield takeEvery(IS_PRESENT_SENT, isGiftSentSaga);
    yield takeEvery(PRESENT_SENT, presentSentConfirmSaga);
    yield takeEvery(GET_SECRET_SANTA_PARTICIPANTS_PAGE_LIST, getSantaParticipantsListFromServer);
    yield takeEvery(GET_MY_INFO, getMyInfoSaga);
    yield takeEvery(MAIN_SECRET_SANTA_SETTINGS, getSecretSantaMainSaga);
};

export default SecretSanta;
