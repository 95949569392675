import { cnWidgetMui } from 'blocks/Widget/Widget.index';
import { Translate } from 'localizations/Translate';
import { Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'utils/src/hooks';

type SearchParamsType = {
    url: string
}

export const ExternalRedirect = () => {

    const [values] = useSearchParams<SearchParamsType>();


    return (
        <div className={cnWidgetMui()}>
            <Typography variant='h4'>
                <Translate i18nKey='pryaniky.title.externalredirect' />
            </Typography>
            <Typography variant='body1'>
                <Translate i18nKey='pryaniky.externalredirect.text' />
            </Typography>
            <Typography variant='body1'>
                {
                    values.url
                        ? <>
                            <Translate i18nKey='pryaniky.externalredirect.text.link' />
                            <Link
                                href={values.url}
                                sx={{
                                    textTransform: 'lowercase'
                                }}
                            >
                                <Translate i18nKey='onwards' />
                            </Link>
                        </>
                        : <Translate i18nKey='pryaniky.externalredirect.text.nolink' />
                }
            </Typography>
        </div>
    );
};