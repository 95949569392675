import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserIsAdmin } from "utils/src/CommonRedux/base/selectors";
import { getNewsCreateItemFields, getNewsCreateItemNewsFields } from "../redux/selectors.newsCreate";
import { NewsCreateReducer } from "../redux/types.newsCreator";
import { newsCreatorItemNewsChange, newsCreatorItemChange, newsCreatorPostNews, newsCreatorValidateNews } from "../redux/actions.newsCreator";
import { useCallback } from "react";

export const useNewsSender = (id: keyof NewsCreateReducer) => {

    const dispatch = useDispatch();

    const data = useSelector(getNewsCreateItemNewsFields(['group', 'publishAt', 'attachments', 'newstype'])(id));

    const creatorFields = useSelector(getNewsCreateItemFields(['isValid', 'newsSending', 'errors', 'validFile', 'hideSender'])(id));

    const isAdmin = useSelector(getCurrentUserIsAdmin);

    const newsCreatorChange = useCallback((item: Partial<typeof creatorFields>) => {
        dispatch(newsCreatorItemChange({ id, item }));
    }, []);

    const newsCreatorNewsChange = useCallback((news: Partial<typeof data>) => {
        dispatch(newsCreatorItemNewsChange({ id, news }));
    }, []);

    const validateNews = useCallback(() => {
        dispatch(newsCreatorValidateNews(id));
    }, []);

    const postNews = useCallback(() => {
        dispatch(newsCreatorPostNews(id));
    }, []);

    // pathCreator,
    // pathCreatorNews,
    // validateCreatorNews,
    // sendNews

    return {
        ...data,
        ...creatorFields,
        isAdmin,
        newsCreatorChange,
        newsCreatorNewsChange,
        validateNews,
        postNews
    }
};