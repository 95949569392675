import { basePostNews } from 'News/creator/baseNews';
import { NewsCreateItem, NewsCreateReducer } from './types.newsCreator';

export const baseNewsCreateItem: NewsCreateItem = {
    selectedNews: null,
    selectedComponentRenderName: null,
    news: basePostNews,
    clearEditor: false,
    hideSender: false,
    isValid: true,
    validFile: true,
    newsSending: false,
    sended: null,
    errors: {},
    inited: false
};

export const baseNewsCreateReducer: NewsCreateReducer = {};