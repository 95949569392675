import actions from 'redux/actionsTypes/notification';
import * as utils from 'utils/src/utils';

export interface INotificationsState {
    data: string[];
    isLoading: boolean;
    notifications: {
        [s: string]: {
            id: number;
            newsUid: string;
            notViewed: boolean;
            linkedUrl: string;
            user: any;
            text: string;
            eventType: number;
            eventDate: string;
            objectType: number;
            objectId: string;
        };
    };
}
const defaultNotifications: INotificationsState = {
    data: [],
    isLoading: false,
    notifications: {},
};
export function notificationsReducer(state: INotificationsState = defaultNotifications, action: any) {
    switch (action.type) {
        case actions.SET_READED:
            if (state.notifications[action.payload].newsUid && state.notifications[action.payload].notViewed) {
                utils.API.notifications.setReaded(
                    state.notifications[action.payload].newsUid,
                    state.notifications[action.payload].objectType
                );
            } else if (state.notifications[action.payload].id && state.notifications[action.payload].notViewed) {
                utils.API.notifications.setReaded(
                    String(state.notifications[action.payload].id),
                    state.notifications[action.payload].objectType
                );
            }

            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.payload]: {
                        ...state.notifications[action.payload],
                        notViewed: false,
                    },
                },
            };

        case actions.SET_READED_ALL:
            return {
                ...state,
                notifications: Object.keys(state.notifications).reduce((acc, cur) => {
                    const not = state.notifications[cur];
                    not.notViewed = false;
                    return { ...acc, [cur]: not };
                }, {}),
            };
        case actions.SET_DATA:
            const { payload } = action.payload;
            return {
                ...state,
                data: payload.data,
                notifications: payload.notifications,
            };
        case actions.ADD_DATA:
            return {
                ...state,
                data: [...state.data, ...action.payload.data],
                notifications: {
                    ...state.notifications,
                    ...action.payload.notifications,
                },
                isLoading: false,
            };
        case actions.TOGGLE_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        default:
            return state;
    }
}
