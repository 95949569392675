import React, { useMemo, useState } from 'react';
import { cnHierarchyWidgetNew, IDialogBodyShowAllProps } from '../Widget_type_hierarchyNew.index';
import { DialogBody } from 'muicomponents/src/DialogParts';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { Box, styled } from 'muicomponents/src';
import { Translate } from 'localization';
import { ListLite } from 'uielements';
import { ContentItem } from './ContentItem';
import { StructureHeader } from './Structure';

const ContentBox = styled(Box)({
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
}) as typeof Box;

const DialogBodyShowAll = ({
    handleClose,
    items,
    loadMore,
    hierarchyName,
    onDelete,
    defaultIsFinished,
    title,
    setItems,
    ...props
}: IDialogBodyShowAllProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<{ [key: string]: any }>({ isFinished: defaultIsFinished, data: items });

    const currentOnDelete = (v: any) => {
        const onDel = (item: any) => {
            setData((prev) => {
                return { ...prev, data: prev.data.filter((val: any) => val.id !== item.id) };
            });
        };
        onDelete(v, onDel);
    };

    const itemsInDialog = useMemo(() => {
        if (Array.isArray(data.data)) {
            return data.data.map((item: any) => {
                return (
                    <ContentItem item={item} hierarchyName={hierarchyName} onDelete={currentOnDelete} key={item.id} />
                );
            });
        }
        return [];
    }, [data.data]);

    const currentLoadMore = () => {
        loadMore(Array.isArray(data.data) ? data.data.length : 0, setIsLoading, (v) => {
            setData((prev) => {
                const prevDataArr = Array.isArray(prev.data) ? prev.data : [];
                const newDataArr = Array.isArray(v.data) ? v.data : [];
                return { isFinished: v.isFinished, data: [...prevDataArr, ...newDataArr] };
            });
        });
    };
    const onChangeItems = (v: any[]) => {
        setItems(v);
        setData({ ...data, data: v });
    };
    return (
        <DialogBody
            className={cnHierarchyWidgetNew('DialogBodyShowAll')}
            closeText={<Translate i18nKey="close" />}
            onClose={handleClose}
            contentProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                },
            }}
            header={<StructureHeader {...props} items={data.data} setItems={onChangeItems} title={title} />}
            titleProps={{
                disableTypography: false,
                xsInnerWrapper: {
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    minHeight: '56px',
                },
            }}
        >
            <Box className={cnHierarchyWidgetNew('DialogBodyShowAll')} sx={{ width: '100%' }}>
                <ListLite
                    isFinished={typeof data.isFinished === 'boolean' ? data.isFinished : false}
                    loadMore={currentLoadMore}
                    isLoading={isLoading}
                >
                    <ContentBox className={cnHierarchyWidgetNew('Content')}>{itemsInDialog}</ContentBox>
                </ListLite>
            </Box>
        </DialogBody>
    );
};

export const openDialogShowAll = createModal(DialogBodyShowAll, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
        },
    },
    TransitionComponent,
    scroll: 'body',
});
