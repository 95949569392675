import { Reducer } from 'redux';
import {
    VACANCIES_APPEND_TO_LIST,
    VACANCIES_PREPEND_TO_LIST,
    VACANCIES_SET_FULL_ACCESS,
    VACANCIES_SET_LIST,
    VacanciesActions,
} from './actions.vacancies.types';
import { baseVacanciesReducer } from './constants.vacancies';
import { VacanciesReducer } from './reducer.vacancies.types';

export const vacanciesReducer: Reducer<VacanciesReducer, VacanciesActions.Actions> = (
    state = baseVacanciesReducer,
    action
) => {
    switch (action.type) {
        case VACANCIES_SET_FULL_ACCESS: {
            return {
                ...state,
                fullAccess: action.payload,
            };
        }
        case VACANCIES_SET_LIST:
        case VACANCIES_APPEND_TO_LIST:
        case VACANCIES_PREPEND_TO_LIST: {
            let data: typeof state.list = [];
            switch (action.type) {
                case VACANCIES_SET_LIST: {
                    data = action.payload;
                    break;
                }
                case VACANCIES_APPEND_TO_LIST: {
                    data = [...state.list, ...action.payload];
                    break;
                }
                case VACANCIES_PREPEND_TO_LIST: {
                    data = [...action.payload, ...state.list];
                    break;
                }
            }
            return {
                ...state,
                list: data,
            };
        }
        default: {
            return state;
        }
    }
};
