import * as React from 'react';
import i18n from 'localizations/i18n';
import { v1 as uuid } from 'uuid';
import { widgets, IWidgetProps, generateWidget, generateColumn, IWidget } from '../../../../i.widgets';
import { wrapDialogToRedux } from 'blocks/Dialogs/DialogWrapper';
import { Button, Icon } from 'uielements/src';
import {
    IWidgetsAddState,
    IWidgetsAddProps,
    IWidgetToSelect,
    cnWidgetsAdd,
    mapStateToProps,
    IWidgetsAddStateProps,
    WidgetsAddMapActionstoProps,
    IWidgetsAddActionsToProps,
    IWidgetsAddOwnProps,
} from './WidgetsAdd.index';
import { WSettings } from 'blocks/WSettings/WSettings';
import { getStructure } from '../../../WSettings/structures/index';
import './WidgetsAdd.scss';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import { PKeyEvent, PKeyCodes } from 'utils/src/key.events';
import { ISliderLinkItem } from 'blocks/WSettings/structures/sliderlink/uischema';
import { connect } from 'react-redux';
import { checkResponseStatus, widgetSubtype } from 'utils/src/utils';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { IStateType as IState } from 'redux/store';
import { withComponentEnjector } from 'utils/src/ComponentInjector';
import { pageHeader } from 'pageStructures/pageHeaders';
import { widgetsBaseSettings } from '../../../../widgets/constants';
import DialogTitle from 'uielements/src/MaterialElements/DialogParts/DialogTitle';
import DialogActions from 'uielements/src/MaterialElements/DialogParts/DialogActions';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { getExtendedWigets } from 'utils/src/requests/requests.widgets';

export const wdg = (
    schema: string,
    type: any,
    title?: string,
    settings?: IWidgetToSelect['settings']
): IWidgetToSelect => ({
    schema,
    title: title || i18n.t(`pryaniky.widgets.create.name.${type}`),
    type,
    id: uuid(),
    settings,
});

class WidgetsAddPresenter extends React.Component<IWidgetsAddProps, IWidgetsAddState> {
    private TYPES_TO_SHOW: IWidgetToSelect[] = [
        wdg('timeline', widgets.types.timeline),
        // wdg('layout', widgets.types.layout),
        wdg('list', widgets.types.list),
        wdg('virtcurrency', widgets.types.virtcurrency),
        wdg('shop', `${widgets.types.list}/shop`),
        wdg('mobileApp', widgets.types.mobileApp),
        // wdg('info', widgets.types.info),
        // wdg('html', widgets.types.html),
        wdg('wiki', widgets.types.wiki),
        wdg('sliderlink', widgets.types.sliderlink),
        wdg('ratingmini', widgets.types.ratingmini),
        wdg('usersGroup', widgets.types.users + '/group'),
        wdg('filters', widgets.types.filters),
        wdg('birthdays', widgets.types.birthdays),
        wdg('ratings', widgets.types.ratings),
        wdg('myBadges', widgets.types.userRating),
        wdg('quiz', widgets.types.quiz),
        wdg('quests', widgets.types.quests),
        wdg('layout', widgets.types.layout),
        wdg('userAbout', widgets.types.userAbout),
        wdg('hierarchy', `${widgets.types.users}/hierarchy`),
        wdg('coursesMy', `${widgets.types.coursesMy}`),
        wdg('usersList', `${widgets.types.users}/list`),
        wdg('timelineonce', widgets.types.timeline, i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.content')),
        wdg('timelinehead', widgets.types.timeline, i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.head')),
        wdg(
            'eventstoday',
            widgets.types.timeline,
            i18n.t('pryaniky.widgets.create.name.pryaniky/timeline.eventstoday')
        ),
        wdg('banner', widgets.types.banner),
        wdg('userfields', widgets.types.userfields),
        wdg('tagsCloud', widgets.types.tagsCloud),
        wdg('secretwidget', widgets.types.secretwidget),
        wdg('secretSanta', widgets.types.secretSanta),
        wdg('survey', widgets.types.survey),
        wdg('html', widgets.types.html),
        wdg('referral', widgets.types.referral),
        wdg('blocks', widgets.types.blocks),
        wdg('badgesList', widgets.types.badgesList),
        wdg('calendarmini', widgets.types.calendarmini),
        wdg('eventslist', widgets.types.eventslist),
        wdg('adventCalendar', widgets.types.adventCalendar),
        wdg('skills', widgets.types.skills),
        wdg('tagBlock', widgets.types.tagBlock),
        wdg('tabs', widgets.types.tabs),
        wdg('tabsControl', widgets.types.tabsControl),

        this.props.enabledModules.endorsements && wdg('skills', widgets.types.skills),
    ].filter((v: any) => Boolean(v));

    public state: IWidgetsAddState = {
        typeSelector: true,
        isValid: true,
        widgetsList: this.TYPES_TO_SHOW,
    };
    public evFcId: string = '';
    public selectorRef = React.createRef<HTMLDivElement>();
    public settingsRef = React.createRef<HTMLDivElement>();

    private filtersWidgetTypes = [`${widgets.types.list}/groups`, `${widgets.types.list}/shop`];

    constructor(props: IWidgetsAddProps) {
        super(props);
        if (window.PRN_SERVICE.dev_features) {
            this.state.widgetsList = [
                ...this.state.widgetsList,
                wdg('mindmap', widgets.types.mindMap),
                wdg('notes', widgets.types.notes),
                wdg('topbutton', widgets.types.topbutton),
                wdg('reactions', widgets.types.reactions),
                wdg('context', widgets.types.context),
                wdg('orders', `${widgets.types.list}/orders`),
                wdg('charts', widgets.types.charts),
                wdg('album', widgets.types.album),
                // wdg('reactions', widgets.types.reactions),
                wdg('wikilist', `${widgets.types.list}/wikilist`),
                wdg('subscriptions', `${widgets.types.users}/subscriptions`),
                // wdg('layout', widgets.types.layout),
                wdg('journal', widgets.types.journal),
                // wdg('skills', widgets.types.skills),
                wdg('license', widgets.types.license),
                wdg('info', widgets.types.info),
                wdg('kpi', `${widgets.types.kpi}`),
                wdg('currencyTimeline', widgets.types.currencyTimeline),
                wdg('virtualUser', widgets.types.virtualUser),
                wdg('iframe', widgets.types.iframe),
                wdg('presents', widgets.types.presents),
                wdg('statistic', widgets.types.statistic),
                wdg('statisticFilters', widgets.types.statisticFilters),
                wdg('mood', widgets.types.mood),
                wdg('files', widgets.types.files),
                wdg('card', widgets.types.card),
                // wdg('referral', widgets.types.referral)
                // wdg('grid', widgets.types.grid)
            ];
        }
        this.state.widgetsList.sort(this.sortWidgets);
    }

    private sortWidgets(
        a: typeof this['state']['widgetsList'][number],
        b: typeof this['state']['widgetsList'][number]
    ) {
        let titleA = a.title.toLowerCase();
        let titleB = b.title.toLowerCase();
        if (titleA < titleB)
            //сортируем строки по возрастанию
            return -1;
        if (titleA > titleB) return 1;
        return 0; // Никакой сортировки
    }

    /**
     * componentDidMount
     */
    public componentDidMount() {
        const { selected } = this.state;
        getExtendedWigets()
            .then((d) => {
                if (checkResponseStatus(d)) {
                    const prepairedWidget = d.data.map((e) => {
                        return wdg('external', e.type, e.displayName, e.settings);
                    });
                    this.setState({
                        ...this.state,
                        widgetsList: [...this.state.widgetsList, ...prepairedWidget].sort(this.sortWidgets),
                    });
                }
            })
            .catch((e) => {
                console.error(e);
            });
        this.evFcId = PKeyEvent.inst.register(PKeyCodes.esc, this.backOnEsc, {
            stopImmediatePropagation: () => {
                return Boolean(this.state.selected);
            },
        });
    }

    /**
     * backOnEsc - set select item undefined on press esc
     */
    public backOnEsc = (e: KeyboardEvent) => {
        if (this.state.selected) this.setState({ selected: undefined });
    };

    /**
     * componentWillUnmount
     */
    public componentWillUnmount() {
        PKeyEvent.inst.unregister(this.evFcId);
    }

    public render() {
        const { state, props, selectorRef, settingsRef } = this;
        const { onClose, onConfirm, tariff, getComponent } = props;
        const { selected, typeSelector, isValid } = state;
        let startingData;
        if (
            selected &&
            this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur.type : acc), '') ===
                widgets.types.filters
        ) {
            const currentStructureWidgets = (props as any).getActiveStructureWidgets();
            if (currentStructureWidgets) {
                startingData = {
                    type: (props as any)
                        .getActiveStructureWidgets()
                        .reduce((acc: string | undefined, widget: IWidget) =>
                            this.filtersWidgetTypes.includes(widget.type)
                                ? widgetSubtype(widget.type) || widget.type.split('/')[1]
                                : acc
                        ),
                };
            }
        }
        // если есть кастомный компонент - юзаем его.
        // используеся в том случае, если не хватает функциональности от дефолтного JsonForms
        // DI...
        const type = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc)).type;
        const CustomComponent = type && getComponent(type);
        const WSettingsComponent = CustomComponent || WSettings;

        return (
            <>
                <DialogTitle onClose={onClose}>
                    {selected ? (
                        <>
                            <Button
                                className={cnWidgetsAdd('Back')}
                                noBackground
                                onClick={() => this.setState({ selected: undefined })}
                                title={i18n.t('pryaniky.widgets.add.unsetTitle')}
                                children={<Icon icon={'chevron-left'} />}
                            />
                            {i18n.t('pryaniky.widgets.add.selected', {
                                data: this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc))
                                    .title,
                            })}
                        </>
                    ) : (
                        i18n.t('pryaniky.widgets.add.title')
                    )}
                    {tariff !== 'MagistrPremice' ? (
                        <Tooltip className={cnWidgetsAdd('HelpTooltip')} title={i18n.t('pryaniky.widgets.add.help')}>
                            <div>
                                <Button
                                    target="_blank"
                                    type="link"
                                    noBackground
                                    noBorder
                                    href={
                                        'https://pryaniky.com/ru-helps/1-%d1%87%d1%82%d0%be-%d1%82%d0%b0%d0%ba%d0%be%d0%b5-%d0%b2%d0%b8%d0%b4%d0%b6%d0%b5%d1%82%d1%8b/'
                                    }
                                >
                                    <Icon className={cnWidgetsAdd('Icon')} icon={'question-circle'} />
                                </Button>
                            </div>
                        </Tooltip>
                    ) : undefined}
                </DialogTitle>
                <div className={cnWidgetsAdd('Body')}>
                    {!selected && (
                        <div ref={selectorRef} className={cnWidgetsAdd('Selector')}>
                            {this.state.widgetsList.map((el) => (
                                <Button
                                    key={el.id}
                                    className={cnWidgetsAdd('Selector-Item', { selected: el.id === selected })}
                                    onClick={this.selectItem.bind(null, el.id)}
                                    children={el.title}
                                />
                            ))}
                        </div>
                    )}
                    {selected && (
                        <div ref={settingsRef} className={cnWidgetsAdd('Settings')}>
                            <WSettingsComponent
                                setValid={(value) => this.setState((s) => ({ ...s, isValid: value }))}
                                data={startingData}
                                selected={this.state.widgetsList.reduce((acc, cur) =>
                                    cur.id === selected ? cur : acc
                                )}
                                onChange={CustomComponent ? this.onChange : this.makeRez}
                            />
                        </div>
                    )}
                </div>
                <DialogActions
                    acceptText={selected ? i18n.t('add') : undefined}
                    closeText={i18n.t('pryaniky.cancel.dialog')}
                    disableAccept={!selected || !isValid}
                    onAccept={selected ? onConfirm : undefined}
                    onClose={onClose}
                />
            </>
        );
    }

    private checkVariableReqiure = (
        require: (data: { [s: string]: any }) => boolean | boolean,
        value: { [s: string]: any }
    ) => {
        switch (typeof require) {
            case 'boolean':
                return require;
            case 'function':
                return require(value);
        }
    };
    private fieldsInfo = (value: { [s: string]: any }) => {
        const { selected } = this.state;
        if (!selected) return;
        const item = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc));
        const schema = getStructure(item.schema);
        const arrRequire: string[] = [];
        const relations: Record<string, string[]> = {};
        Object.keys(schema.schema.properties).forEach((name) => {
            if (this.checkVariableReqiure(schema.schema.properties[name].require, value)) arrRequire.push(name);
            if (schema.schema.properties[name].relations) relations[name] = schema.schema.properties[name].relations;
        });
        let numberofNotEmpty = 0;
        arrRequire.forEach((name) => {
            const fieldValue = value[name];
            if (typeof fieldValue === 'string' && fieldValue !== '') {
                numberofNotEmpty += 1;
            } else if (typeof fieldValue === 'number' && fieldValue > 0) {
                numberofNotEmpty += 1;
            } else if (Array.isArray(fieldValue) && fieldValue.length > 0) {
                numberofNotEmpty += 1;
            } else if (fieldValue && Object.keys(fieldValue).length > 0) {
                numberofNotEmpty += 1;
            } else if (typeof fieldValue === 'boolean' && relations[name]) {
                if (fieldValue || relations[name].reduce((a, crname) => a || value[crname], false as boolean))
                    numberofNotEmpty += 1;
            }
        });
        this.setState({ isValid: numberofNotEmpty === arrRequire.length });
    };

    private validate = (value: { [s: string]: any }) => {
        this.setState({ isValid: false });
        const { selected } = this.state;
        if (!selected) return;
        const item = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc));
        switch (item.type) {
            case `${widgets.types.users}/hierarchy`: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.secretSanta: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.timeline: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.ratingmini: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.filters: {
                this.fieldsInfo(value);
                return null;
            }
            case `${widgets.types.users}/group`: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.sliderlink: {
                value.slider.elements.findIndex((i: any) => i.imgId !== GUID_EMPTY) !== -1 &&
                    this.setState({ isValid: true });
                return null;
            }
            case widgets.types.list: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.charts: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.quests: {
                this.fieldsInfo(value);
                return null;
            }
            case widgets.types.birthdays: {
                this.fieldsInfo(value);
                return null;
            }
        }
        this.setState({ isValid: true });
    };

    private selectItem = (selected: string, ev: React.SyntheticEvent<HTMLButtonElement>) => {
        const item = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc));
        const settingMethod = item && this.props.getMethod(item.type);

        if (settingMethod) {
            settingMethod({
                selected: item,
                onChange: (value: any) => {},
            })
                .then((result: any) => {
                    const opts: IWidgetProps = {
                        type: item.type,
                        id: item.id,
                        ...result,
                    };
                    store.dispatch(modalChangeDate({ id: this.props.id || '', data: generateWidget(opts) }));
                    this.validate(result.data);
                    this.props.onConfirm && this.props.onConfirm();
                })
                .catch(() => {
                    // this.props.onClose()
                });

            return;
        }
        this.setState({ selected }, () => {
            // setTimeout(() => this.setState({ typeSelector: false }), 500);
        });
    };

    /**
     * И - инкапсуляция...
     * @param value
     */
    private onChange = (value: { [s: string]: any }) => {
        const { selected } = this.state;
        if (!selected) return;
        const item = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc));
        const opts: IWidgetProps = {
            type: item.type,
            id: item.id,
            ...value,
        };
        store.dispatch(modalChangeDate({ id: this.props.id || '', data: generateWidget(opts) }));
        this.validate(value.data);
    };

    private makeRez = (value: { [s: string]: any }) => {
        const { selected } = this.state;
        if (!selected) return;
        const item = this.state.widgetsList.reduce((acc, cur) => (cur.id === selected ? cur : acc));
        const schema = getStructure(item.schema);
        const opts: IWidgetProps = {
            type: item.type,
            id: item.id,
        };

        switch (item.type) {
            case widgets.types.timeline:
                if (value.data.selectedNews) {
                    if (!opts.settings) opts.settings = {};
                    opts.newsType = value.data.selectedNews;
                    opts.settings.newsTypes = value.data.selectedNews;
                    opts.settings.selectedNews = value.data.selectedNews;
                }
                if (value.data?.selectedTags) {
                    if (!opts.settings) opts.settings = {};
                    opts.settings.selectedTags = value.data.selectedTags;
                }

                ['selectedUsers', 'selectedTags', 'selectedGroups'].forEach((key) => {
                    if (!opts.settings) opts.settings = {};
                    if (Array.isArray(value.data[key]) ? value.data[key].length : value.data[key]) {
                        const settingsKey =
                            key === 'selectedUsers'
                                ? 'uId'
                                : key === 'selectedTags'
                                ? 'tId'
                                : key === 'selectedGroups'
                                ? 'gId'
                                : '';
                        if (this.checkVariableReqiure(schema.schema.properties[key].require, value.data)) {
                            opts.settings[settingsKey] = Array.isArray(value.data[key])
                                ? value.data[key].map((el: { [s: string]: any }) => el.pkid | el.id).join(',')
                                : value.data[key].pkid || value.data[key].id;
                            if (key === 'selectedUsers') opts.newsType = 'my';
                        }
                    }
                });

                if (value.data.once) {
                    if (!opts.settings) opts.settings = {};
                    opts.settings.name = value.data.name;
                    opts.settings.once = value.data.once;
                    opts.settings.count = value.data.count;
                    opts.settings.viewType = value.data.viewType;
                    opts.settings.timelineType = value.data.timelineType;
                    opts.settings.tagId = value.data.selectedTags?.id;
                    if (value.data.dateNow) {
                        opts.settings.dateNow = value.data.dateNow;
                    }
                }

                break;
            case widgets.types.list:
                opts.type = `${item.type}/${
                    value.data.users ? 'users' : value.data.groups ? `groups${value.data.groupsTree ? 'Tree' : ''}` : ''
                }`;
                break;
            case widgets.types.info:
                if (value.data.type === 'ideas') opts.data = pageHeader.ideas;
                if (value.data.type === 'creativetasks') opts.data = pageHeader.creativetasks;
                if (value.data.type === 'notices') opts.data = pageHeader.notices;
                if (value.data.type === 'events') opts.data = pageHeader.events;
                if (value.data.type === 'polls') opts.data = pageHeader.polls;
                opts.settings = {
                    type: value.data.type,
                };
                break;
            case `${widgets.types.list}/shop`:
                if (value.data.mini) opts.type = widgets.types.shop;
                break;
            case widgets.types.html:
                opts.data = '';
                break;
            case widgets.types.userfields:
                opts.settings = {
                    fieldsIds: value.data.fieldsIds,
                };
                break;
            case widgets.types.ratingmini:
                opts.settings = value.data;
                break;
            case widgets.types.album:
                opts.settings = value.data;
                break;
            case `${widgets.types.users}/group`:
                opts.settings = value.data;
                break;
            case `${widgets.types.users}/hierarchy`:
                if (value.data.hierarchy) opts.settings = value.data;
                break;
            case `${widgets.types.users}/list`:
                opts.settings = value.data;
                opts.settings.list = opts.settings.list.filter((el: any) => !el.deleted);
                break;
            case widgets.types.filters:
                opts.type = `${widgets.types.filters}${
                    value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''
                }`;
                break;
            case widgets.types.charts:
                opts.type = `${widgets.types.charts}${
                    value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''
                }`;
                opts.link = value.data.link;
                break;
            case widgets.types.ratings:
                // opts.type = `${widgets.types.filters}${value.data.type && value.data.type !== '' ? `/${value.data.type}` : ''}`;
                break;
            case widgets.types.tabs:
                opts.data = [];
                break;
            case widgets.types.tabsControl:
                opts.data = [];
                break;
            case widgets.types.license:
                opts.settings = value.data;
                break;
            case widgets.types.sliderlink:
                opts.settings = {
                    slider: {
                        aspect: value.data.slider.aspect,
                        width: value.data.slider.width,
                        elements: (value.data.slider.elements as ISliderLinkItem[]).filter(
                            (el) => !el.deleted && el.imgId !== GUID_EMPTY
                        ),
                    },
                };
                break;
            case widgets.types.grid: {
                opts.settings = widgetsBaseSettings.grid;
                break;
            }
            case widgets.types.secretSanta: {
                opts.settings = value.data;
                break;
            }
            case widgets.types.virtualUser:
                opts.settings = {
                    virtualUser: {
                        imgId: value.data.virtualUser.imgId,
                        imgUrl: value.data.virtualUser.imgUrl,
                        startText: value.data.virtualUser.startText,
                        isShuffle: value.data.virtualUser.isShuffle,
                        elements: (value.data.virtualUser.elements as ISliderLinkItem[]).filter((el) => !el.deleted),
                    },
                };
                break;
            case widgets.types.tagsCloud:
                opts.settings = {
                    tagsCloud: {
                        collection: value.data?.tagsCloud?.collection,
                        color: value.data?.tagsCloud?.color,
                        isGroupTags: value.data?.tagsCloud?.isGroupTags,
                    },
                };
                break;
            case widgets.types.statistic:
                opts.settings = {
                    data: { ...value.data.data },
                };
                break;
            case widgets.types.statisticFilters:
                opts.settings = {
                    data: [...value.data.data],
                };
                break;
            case widgets.types.quests:
                if (!value.data.mini) opts.type = `${widgets.types.list}/quests`;
                break;
            case widgets.types.birthdays:
                opts.settings = value.data;
                break;
            case widgets.types.mobileApp:
                opts.settings = value.data;
                break;
            case widgets.types.layout:
                opts.data = [generateColumn({})];
                break;
            case widgets.types.iframe:
                opts.settings = value.data;
                break;
            case widgets.types.badgesList:
                opts.settings = value.data;
                break;
        }
        if (item.type.match(/^pryaniky\/external/)) {
            opts.id = uuid();
            opts.title = item.title;
            opts.settings = item.settings;
        }
        store.dispatch(modalChangeDate({ id: this.props.id || '', data: generateWidget(opts) }));
        this.validate(value.data);
    };
}

export const WidgetsAdd = withComponentEnjector(
    wrapDialogToRedux<IWidgetsAddOwnProps>(
        connect<IWidgetsAddStateProps, IWidgetsAddActionsToProps, IWidgetsAddOwnProps, IState>(
            mapStateToProps,
            WidgetsAddMapActionstoProps
        )(WidgetsAddPresenter)
    ),
    ['WAddition']
);
