import { Box, IconButton, styled } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link';

export const VacancyBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    padding: 24,
    border: '1px solid #d3d9e0',
    backgroundColor: '#f7f7f7',
    borderRadius: 8,
}) as typeof Box;

export const TopInfoBox = styled('div')({
    fontSize: 12,
    lineHeight: '166%',
    letterSpacing: '0.4px',
});

export const HeadersBox = styled('h4')({
    margin: 0,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '160%',
    letterSpacing: '0.15px',
});

export const DataBox = styled(Box)({}) as typeof Box;

export const TitlesBox = styled('h5')({
    fontSize: 16,
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: 0,
    lineHeight: '150%',
    letterSpacing: '0.15px',
});

export const ValueBox = styled('div')({
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    display: 'flex',
    gap: 4,
    flexWrap: 'wrap',
});

export const ActionsBox = styled('div')({
    display: 'flex',
    justifyContent: 'end',
});

export const EditButton = styled(IconButton)({
    position: 'absolute',
    top: 8,
    right: 8,
}) as any as typeof IconButton;

export const UserLink = styled(Link)({
    textDecoration: 'none',
}) as typeof Link;
