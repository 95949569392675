import { connect } from 'react-redux';
import * as React from 'react';
import { withRouter } from 'react-router-dom'
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { omit, pick } from 'lodash';
import {
  cnTimeline,
  ITimelineProps,
  ITimelineState,
  mapStateToProps,
  mapDispatchToProps,
  ITimelineDefaultProps,
} from './Timeline.index';
import { NewsCreator } from 'News/creator/Creator';
import './Timeline.scss';

import { TimelineFilter } from 'blocks/Filters/_type/Filters_type_timeline_h'

import NTl from 'News/containers/Timeline'
import Slider from 'News/containers/Slider'
import HeadList from 'News/containers/HeadList'
import Kanban from 'News/containers/Kanban';
import { VoteView } from 'News/containers/Vote';
import WorkflowTable from 'News/containers/WorkflowTable/WorkflowTable';
import { CustomSettings } from 'utils/src';
import { Calendar } from 'blocks/Calendar/Calendar'

import { useDispatch, useSelector } from "react-redux";
import { getAllowPostTypeById } from "redux/sagas/AppSettings/selectors";
// (window as any).hideNewsCreator = true;


const WrapCalendar = ({wfid, context}: any) => {
  const postType = useSelector(getAllowPostTypeById(wfid || '', -1, wfid || ''));
  const ctx = React.useMemo(() => ({...context, cIds: [postType?.renderParams?.calendar?.id]}), [context, postType?.renderParams?.calendar?.id])
  return <Calendar 
  context={ctx} 
  newsId={wfid} /*updateParentContext={(newValue) => this.updateParentContext(newValue)}*/ />
}

export class TimelinePresenter extends React.Component<ITimelineProps, ITimelineState> {
  public static defaultProps: ITimelineDefaultProps = {
    requestOptions: {
      newsTypes: 'all',
      skipCount: 0,
      count: 15,
      userId: '',
      groupId: -1,
      tagId: '',
      search: '',
      network: '',
      timelineType: 'default',
      viewType: 'default'
    },
  };


  // public utils: any = utils;
  // public el: Element;
  // public request: ReturnType<typeof utils.API.news.list>;

  constructor(props: ITimelineProps) {
    super(props);
  }


  private requestOptions = () => {
    // const ro: any = {};
    let { context } = this.props;

    const co = omit(context, ['__parent', 'wId', 'oneNewsType', "tId", 'gId', 'filter', 'isGroupAdmin'])
    const result: Record<string, any> = {
      ...this.props.requestOptions,
      tagId: context.tId,
      groupId: context.gId,
      // ...ro,
      ...co
    }
    result.moderation = result.moderation ? true : false;
    return pick(result, [
      'userId',
      'groupId',
      "tagId",
      'newsTypes',
      'search',
      'statusIds',
      'moderation',
      'invested',
      'tagIds',
      'status',
      'authorIds',
      'userIds',
      'order',
      'sort',
      'calfilter',
      'network',
      'lastObjectId',
      'searchText',
      'statusGuidIds',
      'count',
      'beginDate',
      'endDate',
      'typeGroupIds',
      'typePkids'
    ])
  }

  private get settings() {
    const settings = ['once', 'viewType'];
    let { context } = this.props;
    context = utils.cloneObject(context);
    return pick(context, settings);
  }


  public get postOff() {
    return !!(
      this.props.context.postOff
      || this.props.context.uId
      || this.props.context.tId
      || this.props.context.timelineType === 'table'
      || this.props.context.timelineType === 'kanban'
      || this.props.context.timelineType === 'vote'
      || this.props.context.once
      || (
        /**
         * if content is disabled then disable it only if user is not admin and is not groud admin
         */
        this.props.context.disallowUserPublications
        && !this.props.context.isAdmin
        && !this.props.context.isGroupAdmin
      )
    )
  }

  public render() {
    let { tag: TagName = 'div', context, hideNews, relations = [], widget } = this.props;
// console.log('timeline props', this.props)
    // const hideCreator = CustomSettings.hideNewsCreator()
    
console.log('this.requestOptions()', this.requestOptions())

    return <TagName {...this.props}>

      {!this.postOff && <div className={cnTimeline("Form")}>
        <NewsCreator groupId={context.gId} workflowId={context.workflowId} newsType={context.oneNewsType} />
      </div>}

      {widget?.settings?.showFilter && <div className='Widget'>
        <TimelineFilter
          context={context}
          relations={relations}
        />
      </div>}

      {({
        'calendar': <WrapCalendar context={context} wfid={context.workflowId} />,
        'kanban': <Kanban params={this.requestOptions()} />,
        'table': <WorkflowTable params={this.requestOptions()} settings={this.settings} />,
        'vote': <VoteView params={this.requestOptions()} />,
        'slider': <Slider widgetId={this.props.widgetId} hideNews={hideNews} header={context.name || ''} params={this.requestOptions()} context={context} settings={this.settings} />,
        'headlist': <HeadList hideNews={hideNews} context={context} header={context.name || ''} params={this.requestOptions()} settings={this.settings} />,
        'list': <NTl params={this.requestOptions()} settings={this.settings} />
      })[context.timelineType || '']
        || <NTl params={this.requestOptions()} settings={this.settings} />}

    </TagName>
  }

}

export const Timeline = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelinePresenter));
