import { Translate } from 'localizations/Translate';
import React, { createRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentUser } from 'utils/src/CommonRedux/users/hooks';
import ErrorBoundary from 'utils/src/ErrorBoundary';
import { useGlobalStoreForWidget, useWidget } from 'Widgets_v2/hooks';
import { WidgetsTypes } from 'utils/src/widgets/types';
import { cnWidgetv2 as cnWidget, NWidget, NWidgetMenu } from './Widget.index';
import {
    updateWidget,
    addTabToWidget,
    changeWidgetSettings,
    changeWidgetData
} from 'redux/actions/Widgets';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'uielements/src/Button/Button';
import { Icon } from 'uielements/src/Icon/Icon';
import * as utilsDep from 'utils.project/utils.project';
import * as utils from 'utils/src/utils';
import i18n from 'localizations/i18n';
import { WidgetsSettings } from 'blocks/Dialogs/Widgets/Settings/WidgetsSettings';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { BoxMenu, BoxName, BoxStyled, BoxSliderSize } from './Widget.styled';
import { prepareWidget } from 'i.widgets';
import { useWidgetComponent } from './Widget.component.hook';
import { generateWidget } from 'Widgets_v2/utils';
import { useWidgetContext, WidgetContext } from 'Widgets_v2/context';
import { TabsSettings } from 'blocks/Dialogs/Widgets/TabsSettingsMui';
import { getWidgetContextById } from 'Widgets_v2/selectors';


const Menu: FC<NWidgetMenu.Props> = ({
    widget
}) => {

    const {
        id,
        type
    } = widget;

    const dispatch = useDispatch();

    const [state, setState] = useState({
        activeModal: '',
        editHTMLData: false,
        // overlay on edit menu hover, make with use portal
        ovelay: false
    });

    // to contenteditable widgets triegger change edit content
    useEffect(() => {
        /**
         * @todo rebuild actions
         */
        dispatch(updateWidget({
            ...widget,
            HTMLWidgetContentEdit: state.editHTMLData
        } as any));
    }, [state.editHTMLData]);

    const settingMethod = useMemo(() => ComponentInjector.getInstance().getMethodById(['WSettings'], widget.type), [widget]);

    const showSettings = useMemo(() => {
        switch (type) {
            case WidgetsTypes['users/group']:
            case WidgetsTypes.ratingmini:
            case WidgetsTypes.sliderlink:
            case WidgetsTypes.license:
            case WidgetsTypes.iframe:
            case WidgetsTypes.virtualUser:
            case WidgetsTypes.tagsCloud:
            case WidgetsTypes.statistic:
            case WidgetsTypes.statisticFilters:
            case WidgetsTypes.userfields:
            case WidgetsTypes.blocks:
            case WidgetsTypes.badgesList:
            case WidgetsTypes['users/list']: {
                return true;
            }
            case WidgetsTypes.layout:
            case WidgetsTypes['layout/horizontal']: {
                if (widget.relations.includes('common')) return true;
                break;
            }
        }
        return false;
    }, [type]);

    const showEdit = useMemo(() => {
        switch (type) {
            case WidgetsTypes.html:
            case WidgetsTypes.wiki:
            case WidgetsTypes.license: {
                return true;
            }
        }
        return false;
    }, [type]);

    if (!widget) return null;

    return (
        <BoxMenu id={id + '-menu'} key={id + '-menu'} className={cnWidget('Menu')}>

            {/* {
                type === widgets.types.layout &&
                <Translate i18nKey={`title:pryaniky.widgets.change.columns.settings`}>
                    <Button name={id} onClick={layoutSelector}>
                        <Icon icon="columns" />
                    </Button>
                </Translate>
            } */}

            {
                (type === WidgetsTypes.tabs || type === WidgetsTypes.tabsControl) &&
                (
                    <>
                        <Translate i18nKey={'title:pryaniky.widgets.tabs.settings'}>
                            <Button
                                name={id}
                                onClick={() => {
                                    TabsSettings({
                                        tabs: (widget.data || []) as any
                                    })
                                    .then((rezult) => {
                                        // as any because types for action is wrong
                                        dispatch(addTabToWidget({
                                            wId: widget.id,
                                            columns: rezult
                                        } as any));
                                    })
                                    .catch(() => {});
                                }
                            }>
                                <Icon icon="browser" />
                            </Button>
                        </Translate>
                    </>
                )
            }

            {
                showSettings && (
                    <>
                        <WidgetsSettings
                            isShown={state.activeModal === 'widgetSettings'}
                            data={{
                                wId: id,
                                settings: widget.settings,
                            }}
                            onConfirm={
                                (...args: Parameters<typeof changeWidgetSettings>) => {
                                    if(widget.type === WidgetsTypes.usersevents) {
                                        const tArgs = args as [any];
                                        changeWidgetData(...tArgs);
                                        changeWidgetSettings(...args);
                                    } else if (widget.type === WidgetsTypes['users/list']) {
                                        const tArgs = args as [any];
                                        dispatch(changeWidgetData(...tArgs));
                                    } else {
                                        dispatch(changeWidgetSettings(...args));
                                    }
                                }
                            }
                            onClose={() => setState({ ...state, activeModal: '' })}
                        />
                        <Translate i18nKey={'title:pryaniky.widgets.widget.settings'} >
                            <Button name={id} onClick={() => {
                                // this.hideOverlay();
                                setState({ ...state, activeModal: 'widgetSettings' })
                            }}>
                                <Icon icon="cog" />
                            </Button>
                        </Translate>
                    </>
                )
            }

            {
                settingMethod &&
                <Translate i18nKey={'title:pryaniky.widgets.widget.settings'}>
                    <Button name={id} onClick={() => {
                        // this.hideOverlay();
                        settingMethod({
                            onChange: (...args: Parameters<typeof updateWidget>) => dispatch(updateWidget(...args)),
                            selected: widget.type,
                            data: widget
                        }).then((result: any) => {
                            dispatch(updateWidget({
                                ...widget,
                                ...result
                            }));
                        })
                    }}>
                        <Icon icon="cog" />
                    </Button>
                </Translate>
            }

            {
                showEdit && (
                    <Translate i18nKey={'title:pryaniky.widgets.widget.settings'}>
                        <Button
                            name={id}
                            onClick={() => {
                                setState({
                                    ...state,
                                    editHTMLData: !state.editHTMLData
                                });
                            }}>
                            <Icon icon="edit" />
                        </Button>
                    </Translate>
                )
            }

        </BoxMenu>
    )
}

/**
 * base simple widget to render blocks by widget type
 * @param param0 
 * @returns 
 */
const WidgetPresenter: FC<NWidget.Props> = ({
    id,
    className,
    hide,
    isHidden,
    children
}) => {

    const ref = createRef<HTMLElement>();

    useEffect(() => {
    }, []);

    const {
        edit,
        editType
    } = useGlobalStoreForWidget();

    const {
        widget,
        type,
        context,
        settings,
        viewType
    } = useWidget(id);

    const smallView = useMemo(() => {
        return widget.type !== WidgetsTypes.layout && widget.type !== WidgetsTypes.tabs && viewType === 'small'
    }, [viewType]);

    const Component = useWidgetComponent(id, { className, children });

    if (!widget || (!edit && (widget.isHidden || isHidden))) return null;

    // id widget version not '2' use prepare widget from version '1'
    /**
     * @todo rebuild actions
     */
    if (widget.version !== '2') return prepareWidget(widget as any);

    if (!hide || edit) {
        return (
            <BoxStyled ref={ref} className={cnWidget({ edit, small: smallView, type }, [className])} data-id={id} id={id}>
                {
                    edit &&
                    <>
                        <BoxName className={cnWidget('Name')}>
                            <Translate i18nKey={`pryaniky.widget.type.${type}${(widget.settings as any)?.once ? `.${(widget.settings as any)?.dateNow ? 'eventstoday' : (widget.settings as any)?.viewType}` : ""}`} />
                            {
                                // show name from settings if widget type is statictis
                                // показываем имя из настроек у виджета статистики
                                type === WidgetsTypes.statistic &&
                                <span>
                                    {`(${(widget.settings as any)?.data?.title || <Translate i18nKey={`pryaniky.statistics.title.${(widget.settings as any)?.data?.module}.${(widget.settings as any)?.data?.statistic}`} />})`}
                                </span>
                            }
                        </BoxName>
                        {
                            // show recommend size to slider widget
                            // вывод рекомендуемого размера для слайдера
                            type === WidgetsTypes.sliderlink &&
                            <BoxSliderSize className={cnWidget('SliderSize')}>
                                <Translate i18nKey={'pryaniky.widgets.settings.slider.size'} /> {ref.current?.clientWidth}x{ref.current?.clientHeight}
                            </BoxSliderSize>
                        }
                        <Menu widget={widget} />
                    </>
                }
                {Component}
                {
                    typeof children === 'function' ?
                        children() :
                        children
                }
            </BoxStyled>
        );
    };

    return null;
}

export const Widget: typeof WidgetPresenter = (props) => {

    const context = useSelector(getWidgetContextById(props.id));

    const [ contextState ] = useState<WidgetContext>({
        widgetId: props.id,
        context
    });
    
    return (
        <ErrorBoundary>
            <WidgetContext.Provider value={{
                ...contextState,
                context
            }}>
                <WidgetPresenter {...props} />
            </WidgetContext.Provider>
        </ErrorBoundary>
    )
}