import { IClassNameProps } from '@bem-react/core';
import { WidgetCalendarMiniSettings } from 'utils/src';
import { cn } from '@bem-react/classname';

export interface IEventsListWidgetProps extends IClassNameProps {
    widgetContext?: any;
    settings?:
        | (WidgetCalendarMiniSettings & {
              title?: string;
          })
        | null;
    isEdit?: boolean;
}

export const EventsListWidgetClassName = cn('EventsListWidget');
