import React, { FC, memo, useCallback } from 'react';
import { cnClassName, mapDispatchToProps, mapStateToProps, News } from './interfaces';
import { connect } from 'react-redux';
import './style.scss';
import { withNewsContext } from '../../contexts/news';

import { Attachments } from 'uielements/src/Attachments/Attachments';
import { Preview } from 'uielements/src/Preview/Preview';
import * as utils from 'utils/src/utils';
import { LinkLayout } from 'blocks/NewsTypes/common/LinkLayout/LinkLayout';
import { NewsEdit } from 'blocks/NewsEdit_new/NewsEdit';

import NewsHeader from '../NewsHeader';
import NewsActions from '../NewsActions';
import NewsComments from '../NewsComments';
import ActionHeader from '../../components/ActionHeader';
import PositiveVote from 'assets/png/positive-vote.png';
import NegativeVote from 'assets/png/negative-vote.png';
import { Box, Typography } from 'muicomponents/src';
import { TextItem } from 'muicomponents/src/TextItem/TextItem';
import { Translate } from 'localization';

type IPresenterProps = News.FullView.IPresenterProps;
type IOwnProps = News.FullView.IOwnProps;
type IDispatchProps = News.FullView.IDispatchProps;
type IStateProps = News.FullView.IStateProps;
type IState = News.FullView.IState;

const noopFn = () => {};
const noopArray: any[] = [];

export const Presenter: FC<IPresenterProps> = ({
    className,
    children,
    edit,
    toggleEdit,
    attachments,
    id,
    linkPreviewImgUrl,
    systemNewsActionHtml,
    newstype,
    componentRenderName,
    actionHeader,
    feedback,
    achievement,
    isNeedApprove,
}) => {
    const cancleEdit = useCallback(() => toggleEdit(false), []);

    const editType = componentRenderName === 'workflow' ? 'workflow' : utils.newNewsType(newstype);

    return (
        <div className={cnClassName({ actionHeader: Boolean(actionHeader) }, [className])}>
            {actionHeader && <ActionHeader actionHeader={actionHeader} />}
            <div className={'NewsLayout'}>
                <NewsHeader />

                {edit ? (
                    <NewsEdit cancleEdit={cancleEdit} compliteEdit={noopFn} newsId={id} type={editType} />
                ) : newstype === 10 && feedback ? (
                    <div className={cnClassName('Content-FeedbackInfo')}>
                        <img
                            className={cnClassName('Content-FeedbackInfo-Image')}
                            src={feedback.value === 1 ? PositiveVote : NegativeVote}
                            alt=""
                        />
                        <span>{children}</span>
                    </div>
                ) : (
                    <div className={cnClassName('Content')}>{children}</div>
                )}

                {!edit && (
                    <LinkLayout
                        linkPreviewImgUrl={linkPreviewImgUrl}
                        newstype={newstype}
                        systemNewsActionHtml={systemNewsActionHtml}
                    />
                )}

                {!edit && (
                    <Preview
                        className={cnClassName('Preview', ['horizontalPadding'])}
                        newsid={id}
                        files={attachments ? attachments : noopArray}
                    />
                )}

                {!edit && (
                    <Attachments
                        className={cnClassName('Attachments', ['horizontalPadding'])}
                        files={attachments ? attachments : noopArray}
                        onDelete={noopFn}
                        state="view"
                    />
                )}
                {achievement && achievement.achievementOnCorrect && isNeedApprove && achievement.comment && (
                    <Box
                        sx={{
                            padding: '24px',
                        }}
                    >
                        <TextItem keyData={<Translate i18nKey={'pryaniky.news.achievement.commentLabel'} />}>
                            {achievement.comment}
                        </TextItem>
                    </Box>
                )}
                <NewsActions />
            </div>
            <NewsComments />
        </div>
    );
};

export default withNewsContext(
    connect<IStateProps, IDispatchProps, IOwnProps, IState>(mapStateToProps, mapDispatchToProps)(memo(Presenter))
);
