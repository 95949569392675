import React, { useEffect, useState, ComponentProps } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps, IFiltersStateProps, mapDispatchToProps, IFiltersDispatchProps, IFiltersProps, cnFilter } from './Filters_type_workflows_bar.index'
import { Button, Icon, SearchInput } from 'uielements/src'
import { filtersModal } from '../../Workflows/Dialogs/Filters/Filters';
import { createNewsModal } from 'blocks/Dialogs/News/Create/Create';
import { Translate } from 'localizations/Translate';
import i18n from 'localizations/i18n';
import { NewsViewSelector } from 'blocks/Timeline/NewsViewSelector/NewsViewSelector';
import { HelpTooltip } from 'uielements/src';
import './Filters_type_workflows_bar.scss';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';
import { SendNewsDialog } from 'News/creator/Creator/SendDialog/SendDialog';
import { NewsCreatorButton } from 'News/creator/Creator/CreatorButton/CreatorButton';
import { useLocalStorage } from 'utils/src/hooks'

const defaultMention = {
  tag: undefined,
  author: undefined,
  user: undefined
}

const Presenter: React.FC<IFiltersProps> = ({ context, changeContextv3, changeField, renderParams, viewType, ...props }) => {
  // const timelineViewType = context.timelineType?.toLowerCase() || 'feed'
  const [defaultViewType, changeDefaultViewType] = useLocalStorage<{ [key: string]: string }>('workflows_defaultViewType', {
    // [context.workflowId]: renderParams?.defaultViewType?.toLowerCase() || timelineViewType
  })
  useEffect(() => {
    if (!defaultViewType[context.workflowId])
      changeTimelineType(renderParams?.defaultViewType?.toLowerCase())
  }, [renderParams]);


  const Tag = props.tag || 'div';
  const contextVariables: string[] = props.relations;
  const isKanban = defaultViewType[context.workflowId] === 'kanban';
  const isVote = defaultViewType[context.workflowId] === 'vote';
  const isTable = defaultViewType[context.workflowId] === 'table';
  const isСalendar = defaultViewType[context.workflowId] === 'calendar';
  const isPreview = window.location.href.indexOf('settingsPreview=true') !== -1;
  const layout = document.getElementsByClassName('Layout-Inner')[0];

  const [inputMention, setInputMention] = useState(defaultMention)

  const changeSearch: ComponentProps<typeof SearchInput>['onChange'] = (search) => {
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { search }
      return acc
    }, {} as { [s: string]: any }))
  }

  const changeTimelineType = (timelineType: string) => {
    changeDefaultViewType({ ...defaultViewType, [context.workflowId]: timelineType })
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = {
        timelineType,
        serch: undefined
      }
      return acc
    }, {} as { [s: string]: any }))
  }

  const checkedHandler = (data: boolean) => {
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { moderation: data }
      return acc
    }, {} as { [s: string]: any }))
  }

  const handleChangeInputMention = (type: string, data: any) => {
    const key = type.slice(0, type.length - 3) // 3 - Ids
    setInputMention({ ...inputMention, [key]: data });
    const dataIds = data && data?.map((item: any) => item.id)

    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { [type]: dataIds }
      return acc
    }, {} as { [s: string]: any }))
  }

  const onShowFilters = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    filtersModal({
      context,
      inputMention,
      checkedHandler,
      handleChangeInputMention,
    })
  }

  const onShowCreate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createNewsModal({
      oneNewsType: context.oneNewsType,
      workflowId: context.oneNewsType
    }).catch(() => { })
  }

  const clearContext = () => {
    setInputMention(defaultMention);
    changeContextv3(contextVariables.reduce((acc: any, conID: any) => {
      acc[conID] = { tagIds: [], userIds: [], authorIds: [], moderation: false }
      return acc
    }, {} as { [s: string]: any }))
  }

  useEffect(() => {
    let type;
    if (defaultViewType) {
      type = defaultViewType[context.workflowId]?.toLowerCase();
    }
    if (!type) return;
    // if (type === 'feed' || type === 'list') {
    //   changeTimelineType('list');
    //   changeField({
    //     field: 'viewType',
    //     // value: type === 'list' ? 'short' : 'full'
    //     value: 'full'
    //   })
    // } else {
    changeTimelineType(type);
    // }
  }, [context.workflowId]);


  useEffect(() => {
    if (isKanban || isTable) layout.classList.add('FullWidth')
    // layout.setAttribute('style', 'max-width: none');
    else layout.classList.remove('FullWidth');
    // layout.setAttribute('style', 'max-width: 1170px');
    // при переключении на конбан и ленту сброс фильтров
    clearContext();
    // если при предпросмотре канбан, перенести на ленту и ебрать её
    if (isPreview) {
      if (isKanban) changeTimelineType('list');
      const ntl: HTMLDivElement | null = document.querySelector('.NewsTimeline');
      if (ntl) ntl.style.display = 'none';
    }

    return () => {
      layout.classList.remove('FullWidth');
    }
  }, [isKanban])

  return (
    <>
      {/* @ts-ignore */}
      <Tag data={{}} tag='div' context={'ideas'} relations={[]} {...props} className={cnFilter({}, [props.className])} >
        {
          !isPreview ?
            <>
              {
                isVote &&
                <Translate i18nKey={'placeholder:pryaniky.post.workflows.bar.search'}>
                  <SearchInput value={context.search} onChange={changeSearch} />
                </Translate>
              }
              <NewsViewSelector highlight={!isKanban && !isVote && !isTable && !isСalendar} onChange={() => changeTimelineType('list')}>

                <Button main={isKanban} onClick={() => changeTimelineType('kanban')}>
                  <Tooltip
                    title={<Translate i18nKey={'help:pryaniky.post.workflows.bar.kanban'} />}>
                    <span><Icon icon={'tasks'}></Icon></span>
                  </Tooltip>
                </Button>

                <Button main={isVote} onClick={() => changeTimelineType('vote')}>
                  <Tooltip
                    title={<Translate i18nKey={'help:pryaniky.post.workflows.bar.vote'} />}>
                    <span><Icon icon={'vote-yea'}></Icon></span>
                  </Tooltip>
                </Button>

                <Button onClick={() => changeTimelineType('table')} main={isTable}>
                  <Tooltip
                    title={<Translate i18nKey={'help:pryaniky.post.workflows.bar.table'} />}>
                    <span><Icon icon={'table'}></Icon></span>
                  </Tooltip>
                </Button>

                <Button onClick={() => changeTimelineType('calendar')} main={isСalendar}>
                  <Tooltip
                    title={<Translate i18nKey={'help:pryaniky.post.workflows.bar.calendar'} />}>
                    <span><Icon icon={'calendar'}></Icon></span>
                  </Tooltip>
                </Button>

              </NewsViewSelector>

              {
                (isKanban || isVote || isTable) &&
                <>
                  <NewsCreatorButton
                    newsType={context.oneNewsType}
                    workflowId={context.oneNewsType}
                  />
                </>
              }
              {
                isKanban &&
                <Button onClick={onShowFilters}>
                  <Translate i18nKey='pryaniky.post.workflows.bar.filters' />
                </Button>
              }
            </> :

            <div className={cnFilter('Preview')}>
              <Translate i18nKey='pryaniky.post.workflows.bar.preview' />
            </div>

        }

      </Tag>
    </>
  )
}

export const FiltersWorkflowsBar = connect<IFiltersStateProps, IFiltersDispatchProps, IFiltersProps>(
  mapStateToProps,
  mapDispatchToProps
)(Presenter)
