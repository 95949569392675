import React from 'react';
import i18n from '../../../localizations/i18n';
import * as UIElements from 'uielements/src';
import * as PryanikyForms from '../../PryanikyForms';
import { PrnUserInput } from "../../PrnUserInput/PrnUserInput";
import { Actions, rankWith, uiTypeIs, mapStateToLayoutProps, ControlProps } from '@jsonforms/core';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { JFdictionary } from "../../WSettings/structures/dictionary";
import { IRenderer, get_variable_name, get_variable_schema_data } from './utils';
import { InputMention } from '../../InputMention/InputMention';
import { UsersSuggester } from 'muicomponents/src/Suggester';
import { ISelectableObject } from 'utils/src/requests/models/api.base';

// UserInputControl
export const usersInput = (props: any) => {
  if (!props.visible) return null;
  const schema = get_variable_schema_data(props.schema, props.uischema.scope) || {};
  const additionalRequestOpts = (schema.dataRelations || []).reduce((acc: any, curr: any) => ({ ...acc, [curr.to]: curr.from.split('.').reduce((a: any, c: any) => a?.[c], props.data) }), {} as Record<string, any>);

  return <UsersSuggester
    TextFieldProps={{
      label: schema.placeholder || i18n.t('pryaniky.placeholders.select'),
      helperText: (props.data?.group && props.data.group.length === 0) ? i18n.t('pryaniky.placeholders.select.help') : ""
    }}
    // isAdmin={isAdmin}
    // withCheckboxes
    multiple
    // TODO check types
    requestAdditionalParams={{
      ...schema.additionalRequestOpts,
      ...additionalRequestOpts
    }}
    disabled={props.data?.group ? props.data.group.length === 0 : false}
    value={props.data[get_variable_name(props.uischema.scope)]}
    // TODO check types
    onChange={(e, value) => {
      if (Array.isArray(value)) {
        props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => value))
      }

    }}
  // onChange={(sss, users) => {
  //     if(Array.isArray(users)) {
  //         const usersList = users.filter(el => el.type === 'user');
  //         const groupsList = users.filter(el => el.type === 'group');
  //         changeNews({
  //             users: (usersList || []) as any,
  //             groups: (groupsList || []) as any
  //         });
  //     }
  // }}
  />
}

  // <InputMention
  //   type='users'
  //   value={props.data[get_variable_name(props.uischema.scope)]}
  //   placeholder={schema.placeholder || i18n.t('pryaniky.placeholders.select')}
  //   noMulti={true}
  //   onChange={(selected: any) => {
  //     props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
  //   }}
  //   {...(schema.props || {})}
  //   additionalRequestOpts={{
  //     ...schema.additionalRequestOpts,
  //     ...additionalRequestOpts
  //   }} />


  // <PrnUserInput
  //   onChange={(selected: any) => {
  //     props.dispatch(Actions.update(get_variable_name(props.uischema.scope), (oldData) => selected))
  //   }}
  //   value={props.data[get_variable_name(props.uischema.scope)]}
  //   excludeUsers={[]} />
// }
// end UserInputControl