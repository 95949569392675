/**
 * @packageDocumentation
 * @module Widget_Settings
 */
import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { bindActionCreators, Action, Dispatch } from "redux"
import { IDispatchProps } from 'redux/connector';
import { IWidget } from 'i.widgets';
import { IWidgetToSelect } from 'blocks/Modal/Body/_type/Modal-Body_type_addWidget.index';
import { getUserHieararchyFromServer } from "redux/sagas/Hierarchy/actions";

export interface IWSettingsProps extends IClassNameProps, IWSettingsStateProps, IDispatchProps, IWSettingsOnlyProps, IWSettingsDispatchProps {
  tag?: 'div';
  type?: string;
}

export interface IWSettingsOnlyProps {
  selected: IWidgetToSelect;
  onChange: (data: any) => void;
  data?: { [s: string]: any };
  widgetData?: { [s: string]: any };
  settings?: { [s: string]: any } | null;
  setValid: (value: boolean) => void
}

export interface IWSettingsState {
  text?: string;
}

export interface IWSettingsStateProps {
  any?: any;
  // store: any
}

export const mapStateToProps = (state: any) => ({
  // store: state.store

})
export type IWSettingsDispatchProps = ReturnType<typeof mapDispatchToProps>
export const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({
  getUserHieararchyFromServer
}, dispatch)

export const cnWSettings = cn('WSettings');