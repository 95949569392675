import { Translate } from 'localizations/Translate';
import moment from 'moment';
import { Tabs } from 'muicomponents/src';
import { adminbasename } from 'muicomponents/src/Button/Button.hooks';
import { Check as CheckIcon, Edit } from 'muicomponents/src/Icons';
import { Link } from 'muicomponents/src/Link/Link';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation, useParams } from 'react-router';
import urls from 'routes/urls';
import { Loading } from 'uielements/src/Loading/Loading';
import { mainUrls } from 'utils/src';
import { getCurrentUser, getCurrentUserIsAdmin, moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { VacancyMainTab } from './tabs/main/VacancyMainTab';
import { VacancyRequestedUsers } from './tabs/requested/VacancyRequestedUsers';
import { VacancySuggestedUsers } from './tabs/suggested/VacancySuggestedUsers';
import { useVacancy } from './Vacancy.hooks';
import { VacancyProps } from './Vacancy.index';
import { EditButton, HeadersBox, TopInfoBox, VacancyBox } from './Vacancy.styled';

const vacancyTabs = [
    {
        id: 'main',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.main'} />,
    },
    {
        id: 'requestedUsers',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.requestedUsers'} />,
    },
    {
        id: 'sudgestedUsers',
        label: <Translate i18nKey={'pryaniky.vacancy.tab.sudgestedUsers'} />,
    },
];

export const Vacancy: FC<VacancyProps> = ({ id: paramId }) => {
    const currentUser = useSelector(getCurrentUser);
    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const isAllowedEndorsements = useSelector(moduleIsEnabled('endorsements'));

    const filteredVacancyTabs = vacancyTabs.filter((tab) => isAllowedEndorsements || tab.id !== 'sudgestedUsers');
    const [activeTab, setActiveTab] = useState(filteredVacancyTabs[0].id);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/responders')) {
            setActiveTab(filteredVacancyTabs[1].id);
        }
    }, [location.pathname]);

    let { id } = useParams<{ id: string }>();

    if (paramId) id = paramId;

    const { item, currentUserRequestSended, isLoading } = useVacancy(id);

    const viewDate = useMemo(() => {
        if (!item?.changeDate && !item?.creationDate) return null;
        const dates = [
            moment(item?.changeDate || 0)
                .toDate()
                .getTime(),
            moment(item?.creationDate || 0)
                .toDate()
                .getTime(),
        ];
        const hightestDate = Math.max(...dates);
        const withEdit = dates.findIndex((el) => el === hightestDate) === 0;
        return (
            <>
                {withEdit && (
                    <>
                        <Translate i18nKey={'pryaniky.vacancy.date'} />{' '}
                    </>
                )}
                {moment(withEdit ? item?.changeDate : item?.creationDate).format('L HH:mm')}
            </>
        );
    }, [item?.changeDate, item?.creationDate]);

    if (!id) {
        console.error(`widget vacancy doesn't have require param "id", check settings or url params`);
        return null;
    }

    const activeTabView = useMemo(() => {
        switch (activeTab) {
            case 'main':
                return <VacancyMainTab id={id} />;
            case 'requestedUsers':
                return <VacancyRequestedUsers id={id} />;
            case 'sudgestedUsers':
                return <VacancySuggestedUsers id={id} />;
            default:
                return null;
        }
    }, [activeTab, id]);

    const tabsView = useMemo(() => {
        if (!(isAdmin || item?.isEditable)) return null;

        return (
            <Tabs
                value={activeTab}
                tabs={filteredVacancyTabs}
                onChange={(e, tabId) => {
                    setActiveTab(tabId);
                }}
            />
        );
    }, [activeTab, isAdmin, item?.isEditable]);

    const editLink = useMemo(() => {
        if (!(isAdmin || item?.isEditable)) return null;
        return (
            <EditButton {...({ href: generatePath(`${adminbasename}${mainUrls.vacancy.id}`, { id }) } as any)}>
                <Edit />
            </EditButton>
        );
    }, [isAdmin, id, item?.isEditable]);

    if (isLoading)
        return (
            <VacancyBox>
                <Loading />
            </VacancyBox>
        );

    if (!item) return null;

    return (
        <VacancyBox>
            <Link to={urls.vacancies}>
                <Translate i18nKey={'pryaniky.vacancy.returntolist'} />
            </Link>
            {editLink}
            <HeadersBox
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div>
                    {item.name}
                    <TopInfoBox>{viewDate}</TopInfoBox>
                </div>
                {currentUserRequestSended && (
                    <Tooltip title={<Translate i18nKey="pryaniky.vacancy.request.sended" />}>
                        <CheckIcon color={'success'} />
                    </Tooltip>
                )}
            </HeadersBox>
            {tabsView}
            {activeTabView}
        </VacancyBox>
    );
};
