import React, { FC, useEffect, useMemo, useState } from 'react';
import { IEventsListWidgetProps } from './EventsListWidget.index';
import {
    initWidgetAddition,
    initWidgetSettings,
} from './EventsListWidgetSettingsDialog/EventsListWidgetSettingsDialog';
import { Button, LoadingButton, Paper, Typography } from 'muicomponents/src';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker';
import { getCalendarEventsRequestV4 } from 'utils/src/requests/requests.calendar';
import { ICalendarEventData, IGetCalendarEventsRequest } from 'utils/src/requests/models/api.calendar';
import { TableCellProps, TableContainer } from '@material-ui/core';
import {
    DateLabel,
    DatesBox,
    FooterBox,
    HeaderBox,
    MainBox,
    PageBox,
    PageButton,
    RangeDatesBox,
    StyleTable,
    StyleTableBody,
    StyleTableHead,
    StyledHeadTableCell,
    StyledTableCell,
    StyledTableRow,
} from './EventsListWidget.style';
import { Translate } from 'localization';
import moment from 'moment';

initWidgetAddition();
initWidgetSettings();

type ColumnName = ['date', 'title', 'place'];
const columnsEventsListTable: ({
    key: ColumnName[number];
} & TableCellProps)[] = [
    {
        key: 'date',
    },
    {
        key: 'title',
    },
    {
        key: 'place',
    },
];

export const EventsListWidget: FC<IEventsListWidgetProps | any> = ({
    contexts,
    className,
    isEditable,
    HTMLWidgetContentEdit,
    settings,
    data,
}) => {
    const { title, calendar } = settings as {
        title?: string;
        calendar: { id: string; [k: string]: any }[];
    };
    const nowDate = useMemo(() => {
        return moment(new Date()).startOf('day').toISOString();
    }, []);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabelPrevPageButton, setIsDisabelPrevPageButton] = useState<boolean>(false);
    const [isDisabelNextPageButton, setIsDisabelNextPageButton] = useState<boolean>(false);
    const [errorDates, setErrorDates] = useState<{
        start?: boolean;
        end?: boolean;
    }>({});
    const [eventsListState, setEventsListState] = useState<({ isFirstEvent?: boolean } & ICalendarEventData)[]>([]);
    const [errorGetData, setErrorGetData] = useState<any>();
    const [page, setPage] = useState(0);
    const [rangeDatesState, setRangeDatesState] = useState<{
        start?: Date | null | string;
        end?: Date | null | string;
    }>({});
    const [lastRequestDates, setLastRequestDates] = useState<{
        start?: Date | string;
        end?: Date | string;
    }>({});

    async function getEventsList(
        startDate: Date | string,
        endDate: Date | string,
        sort: IGetCalendarEventsRequest['sort'],
        count: number = 11,
        skipCount: number = 0,
        isFirstRequest: boolean = false
    ) {
        const start = typeof startDate === 'string' ? new Date(startDate).toISOString() : startDate.toISOString();
        const end = typeof endDate === 'string' ? new Date(endDate).toISOString() : endDate.toISOString();
        setLastRequestDates({
            start,
            end,
        });
        setIsLoading(true);
        try {
            const response = await getCalendarEventsRequestV4({
                start,
                end,
                sort,
                cIds: calendar.map((c) => c.id),
                count,
                skipCount,
            });
            setIsLoading(false);
            let currentData: ({ isFirstEvent?: boolean } & ICalendarEventData)[] = Array.isArray(response.data?.events)
                ? response.data.events
                : [];
            if (isFirstRequest)
                currentData[0] = {
                    ...currentData[0],
                    isFirstEvent: true,
                };
            if (!isFirstRequest && currentData.length > 0) {
                currentData = currentData.slice(1);
            }
            const newEventsList =
                sort === 'desc' ? [...currentData.reverse(), ...eventsListState] : [...eventsListState, ...currentData];
            setEventsListState(newEventsList);
            return newEventsList;
        } catch (error: any) {
            setIsLoading(false);
            setErrorGetData(error);
        }
        return eventsListState;
    }

    const firstRequest = async function () {
        const result = await getEventsList(nowDate, moment(nowDate).add(3, 'months').toISOString(), 'asc', 10, 0, true);
        if (result.length < 10) {
            setIsDisabelNextPageButton(true);
        }
    };

    useEffect(() => {
        firstRequest();
    }, []);

    const getEventsForPage = (currentPage: number, currentEventsList: typeof eventsListState) => {
        if (!Array.isArray(currentEventsList) || currentEventsList.length === 0) return [];
        const indexFirstEvent = currentEventsList.findIndex((event) => {
            return event.isFirstEvent;
        });
        if (indexFirstEvent < 0) return currentEventsList;
        const currentStartIndex = indexFirstEvent + currentPage * 10;
        return currentEventsList.slice(
            Math.max(currentStartIndex, 0),
            Math.min(currentStartIndex + 10, currentEventsList.length)
        );
    };

    const onPrevPage = async function () {
        setIsDisabelNextPageButton(false);
        const currentPage = page - 1;
        setPage(currentPage);

        if (getEventsForPage(currentPage, eventsListState).length < 10) {
            const date = eventsListState[0]?.start || nowDate;
            const newEventsList = await getEventsList(moment(date).add(-3, 'months').toISOString(), date, 'desc');
            if (getEventsForPage(currentPage, newEventsList).length < 10) {
                setIsDisabelPrevPageButton(true);
            }
        }
    };
    const onNextPage = async function () {
        setIsDisabelPrevPageButton(false);
        setPage(page + 1);

        if (getEventsForPage(page + 1, eventsListState).length < 10) {
            const date = eventsListState[eventsListState.length - 1]?.start || nowDate;
            const newEventsList = await getEventsList(date, moment(date).add(3, 'months').toISOString(), 'asc');
            if (getEventsForPage(page + 1, newEventsList).length < 10) {
                setIsDisabelNextPageButton(true);
            }
        }
    };

    const rowsEventsListTable: (Record<ColumnName[number], any> & { id: string })[] = useMemo(() => {
        const val =
            eventsListState.length > 0
                ? getEventsForPage(page, eventsListState).map((event) => {
                      return {
                          id: event.id,
                          date: moment(event.start).format('L LT'),
                          //   date: formatDateSTD(event.start, true).split(' в').join(','),
                          title: event.title,
                          place: event.place || 'Не указано',
                      };
                  })
                : [];
        return val;
    }, [eventsListState, page]);

    return (
        <MainBox>
            <HeaderBox>
                <Typography variant={'subtitle1'}>{title}</Typography>
                <PageBox>
                    <PageButton color="inherit" onClick={onPrevPage} disabled={isDisabelPrevPageButton || isLoading}>
                        <ArrowBackIosNewIcon fontSize={'small'} />
                    </PageButton>
                    <div>
                        <Typography color={'rgba(0, 0, 0, 0.6)'} variant={'caption'}>
                            <Translate i18nKey={'pryaniky.eventslist.pagesTitle'} />
                        </Typography>
                    </div>
                    <PageButton color="inherit" onClick={onNextPage} disabled={isDisabelNextPageButton || isLoading}>
                        <ArrowForwardIosIcon fontSize={'small'} />
                    </PageButton>
                </PageBox>
            </HeaderBox>
            <TableContainer component={Paper}>
                <StyleTable>
                    <StyleTableHead>
                        <StyledTableRow>
                            {columnsEventsListTable.map((cell) => {
                                const { key, padding, ...props } = cell;
                                return (
                                    <StyledHeadTableCell key={key} {...props}>
                                        <Translate i18nKey={'pryaniky.eventslist.tableColumnTitle.' + key} />
                                    </StyledHeadTableCell>
                                );
                            })}
                        </StyledTableRow>
                    </StyleTableHead>
                    <StyleTableBody>
                        {isLoading || errorGetData ? (
                            <LoadingButton
                                loading={isLoading}
                                fullWidth
                                onClick={() => {
                                    lastRequestDates.start && lastRequestDates.end
                                        ? getEventsList(lastRequestDates.start, lastRequestDates.end, 'asc')
                                        : firstRequest();
                                }}
                            >
                                {isLoading ? <Translate i18nKey={'loading'} /> : 'Ошибка загрузки, повторить попытку'}
                            </LoadingButton>
                        ) : rowsEventsListTable.length === 0 ? (
                            <Typography
                                sx={{
                                    boxSizing: 'border-box',
                                    padding: '24px',
                                    textAlign: 'center',
                                }}
                            >
                                <Translate
                                    i18nKey={'pryaniky.eventslist.table.notDataMessage'}
                                    values={{
                                        start: moment(lastRequestDates.start).format('L LT'),
                                        end: moment(lastRequestDates.end).format('L LT'),
                                        // start: formatDateSTD(lastRequestDates.start, true).split(' в').join(''),
                                        // end: formatDateSTD(lastRequestDates.end, true).split(' в').join(''),
                                    }}
                                />
                            </Typography>
                        ) : (
                            rowsEventsListTable.map((row) => {
                                return (
                                    <StyledTableRow key={row.id}>
                                        {columnsEventsListTable.map((cell) => {
                                            return <StyledTableCell key={cell.key}>{row[cell.key]}</StyledTableCell>;
                                        })}
                                    </StyledTableRow>
                                );
                            })
                        )}
                    </StyleTableBody>
                </StyleTable>
            </TableContainer>

            <FooterBox>
                <DatesBox>
                    <Typography variant={'subtitle1'}>
                        <Translate i18nKey={'pryaniky.eventslist.rangeDatesTitle'} />
                    </Typography>
                    <RangeDatesBox>
                        <DateLabel>
                            <Typography variant={'body2'}>
                                <Translate i18nKey={'pryaniky.eventslist.rangeDatesLabel.start'} />
                            </Typography>
                            <DatePicker
                                value={rangeDatesState.start || null}
                                onChange={(v) => {
                                    setRangeDatesState({ ...rangeDatesState, start: v });
                                }}
                                type={'date'}
                                TextFieldProps={{
                                    size: 'small',
                                    label: <Translate i18nKey={'pryaniky.eventslist.rangeDatesPlaceholder'} />,
                                }}
                                onError={(e) => {
                                    setErrorDates({ ...errorDates, start: e !== null });
                                }}
                            />
                        </DateLabel>
                        <DateLabel>
                            <Typography variant={'body2'}>
                                <Translate i18nKey={'pryaniky.eventslist.rangeDatesLabel.end'} />
                            </Typography>
                            <DatePicker
                                value={rangeDatesState.end || null}
                                onChange={(v) => {
                                    setRangeDatesState({ ...rangeDatesState, end: v });
                                }}
                                type={'date'}
                                TextFieldProps={{
                                    size: 'small',
                                    label: <Translate i18nKey={'pryaniky.eventslist.rangeDatesPlaceholder'} />,
                                }}
                                onError={(e) => {
                                    setErrorDates({ ...errorDates, end: e !== null });
                                }}
                            />
                        </DateLabel>
                    </RangeDatesBox>
                    <Typography variant={'caption'} color={'rgba(0, 0, 0, 0.6)'}>
                        <Translate i18nKey={'pryaniky.eventslist.rangeDatesDescription'} />
                    </Typography>
                </DatesBox>
                <Button onClick={() => window.open(`/ru/data/v4/calendars/render/html?cIds=${calendar.map((c)=>c.id)}&start=${rangeDatesState.start}&end=${rangeDatesState.end}`)}
                    variant={'contained'}
                    disabled={!rangeDatesState.end || !rangeDatesState.start || errorDates.start || errorDates.end}
                >
                    <Translate i18nKey={'pryaniky.eventslist.lookButton'} />
                </Button>
            </FooterBox>
        </MainBox>
    );
};
