import React from 'react';
import { Box, Typography, styled } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { Avatar } from 'muicomponents/src/Avatar';
import { Delete } from 'muicomponents/src/Icons';
import { ContentItemProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { AvatarSize } from 'muicomponents/src/Avatar/Avatar';
import { Link } from 'muicomponents/src/Link';
import { UserMentionTooltipS } from 'muicomponents/src/UserMention/UserMention';

const PositionText = styled(Typography)({
    color: ' rgba(0, 0, 0, 0.60)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}) as typeof Typography;

const InfoBox = styled(Box)({
    display: 'flex',
    gap: '8px',
    gridGap: '8px',
}) as typeof Box;

export const ContentItem = ({ item, hierarchyName, onDelete }: ContentItemProps) => {
    const authUser: any = useSelector(getAuthUser);

    return (
        <Box className={cnHierarchyWidgetNew('Item')}>
            <UserMentionTooltipS id={`${item.id}`}>
                <Link href={item.url}>
                    <Avatar size={AvatarSize.m} srcSet={item.imgUrl} alt={item.displayName} />
                </Link>
            </UserMentionTooltipS>
            <InfoBox className={cnHierarchyWidgetNew('Item-Info')}>
                <Box sx={{ width: '90%' }}>
                    <UserMentionTooltipS id={`${item.id}`}>
                        <Link href={item.url} className={cnHierarchyWidgetNew('Item-Info-Name')}>
                            {item.title || item.displayName}
                        </Link>
                    </UserMentionTooltipS>
                    <PositionText variant={'body2'}>{item.position}</PositionText>
                </Box>
                {authUser.baseData.isAdmin && hierarchyName && (
                    <IconButton
                        size={'small'}
                        className={cnHierarchyWidgetNew('Delete')}
                        onClick={() => onDelete(item)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </InfoBox>
        </Box>
    );
};
