import { CircularProgress } from '@mui/material';
import { Translate } from 'localization';
import { Button } from 'muicomponents/src';
import { IconButton } from 'muicomponents/src/IconButton';
import { Check, Notifications as NotificationsIcon, Settings } from 'muicomponents/src/Icons';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { IStateType as IState } from 'redux/store';
import { ListItemWithRouter } from './NotificationItem/Item';
import {
    cnNotifications,
    INotificationsActionsToProps,
    INotificationsOwnProps,
    INotificationsProps,
    INotificationsStateProps,
    mapDispatchToProps,
    mapStateToProps,
} from './Notifications.index';
import { MenuButtonBox, MenuLoadingBox, MenuNotNotificationBox } from './Notifications.styled';

export type TFlexSettingsBox = {
    position?: 'sticky' | 'static';
};

const NotificationsPresenter: React.FC<INotificationsProps> = ({
    dataArray,
    isLoading,
    notificationCount,
    loadData,
    className,
    children,
    setAllNotificationsViewed,
    currentUserId,
}) => {
    const history = useHistory();

    const setReadedAll = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (event) event.nativeEvent.stopImmediatePropagation();
        setAllNotificationsViewed();
    };

    useEffect(() => {
        if (!dataArray.length) {
            loadData(0, 20);
        }
    }, [notificationCount]);

    const FlexSettingsBox = useCallback<React.FC<TFlexSettingsBox>>(({ children, ...props }) => {
        return (
            <MenuButtonBox {...props}>
                {children}
                <IconButton
                    onClick={() => {
                        history.push(`/notifiSettings/${currentUserId}/common`);
                    }}
                >
                    <Settings />
                </IconButton>
            </MenuButtonBox>
        );
    }, []);

    return (
        <div className={cnNotifications({}, [className])}>
            {isLoading ? (
                <MenuLoadingBox>
                    <CircularProgress size={24} />
                </MenuLoadingBox>
            ) : dataArray.length ? (
                <>
                    {dataArray.map((id: number) => (
                        <ListItemWithRouter key={id} id={id} />
                    ))}

                    <FlexSettingsBox position="sticky">
                        <Button
                            className="Button-LikeRead"
                            onClick={setReadedAll}
                            sx={{ textTransform: 'none !important', justifyContent: 'start' }}
                            endIcon={<Check />}
                        >
                            <Translate i18nKey={'pryaniky.header.mark.like.read'} />
                        </Button>
                    </FlexSettingsBox>
                </>
            ) : (
                <FlexSettingsBox>
                    <MenuNotNotificationBox>
                        <NotificationsIcon sx={{ color: '#bdbdbd' }} />
                        <Translate i18nKey={'pryaniky.header.menu.noNotifications'} />
                    </MenuNotNotificationBox>
                </FlexSettingsBox>
            )}
            {children}
        </div>
    );
};

export const Notifications = connect<
    INotificationsStateProps,
    INotificationsActionsToProps,
    INotificationsOwnProps,
    IState
>(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsPresenter);

export default Notifications;
