import { DialogTitle, DialogContent, DialogActions } from 'muicomponents/src/DialogParts';
import { Translate } from 'localization';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { TextField } from '@material-ui/core';
import { SendRespondProps } from './SendRespond.index';
import { useFileUploader, useReffedState } from 'utils/src/hooks';
import { checkResponseStatus, ResponseError, Vacancy } from 'utils/src';
import moment from 'moment';
import { v1 as uuid } from 'uuid';
import { vacancyUserRequestAction } from 'utils/src/requests/admin.vacancies';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';
import { FilesUploader } from 'muicomponents/src';

const SendRespondPr: FC<SendRespondProps> = ({
    vacancyId,
    user,
    handleAccept,
    handleClose,
}) => {

    const { maxFileSizeLimit } = useSelector<any, { maxFileSizeLimit: number }>(getAppSettings);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ comment, setComment, commentRef ] = useReffedState('');

    const {
        isLoading: filesIsLoading,
        progress,
        startUpload,
        files,
        onFileChange,
        dndRef,
        dragEntered,
        dndTriggerRef,
        removeFile,
        totalSize,
        inputAccept,
        uploadError,
        setFiles,
        getAttachmentsList,
    } = useFileUploader({
        // allowTypes: [],
        // TODO check types
        disablePaste: true,
        uploadOnAdd: true,
        maxFileSize: maxFileSizeLimit || undefined,
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code) {
                    switch (response?.error_code) {
                        case 4050:
                            toast.error(<Translate i18nKey={'pryaniky.file.size.error'} values={{
                                fileName: item.name,
                                maxSize: maxFileSizeLimit
                            }} />);
                            break;
                        case 4052:
                            toast.error(response?.error_text);
                            break;
                        default:
                            if (response?.error_text)
                                toast.error(response?.error_text);
                            break;
                    }
                }
            },
        }
    });
    const filesRef = useRef(files);
    filesRef.current = files;

    const sendRequest = useCallback(async function() {
        try {
            setIsLoading(true);
            const requestData: Vacancy['requests'][number] = {
                timeStamp: moment().toISOString(),
                hrNote: '',
                comment: commentRef.current,
                id: uuid(),
                user,
                file: filesRef.current[0]?.response?.data[0] as any,
                status: 'Apply'
            };
            const response = await vacancyUserRequestAction({
                id: vacancyId,
                action: 'Apply',
                model: requestData
            });
            if(checkResponseStatus(response)) {
                toast.success(<Translate i18nKey={'pryaniky.vacancy.request.success'} />)
                handleAccept({
                    sended: true,
                    request: requestData
                });
            } else {
                throw new ResponseError(response);
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                toast.error(<Translate i18nKey={'pryaniky.vacancy.request.error'} />)
            }
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [vacancyId, user]);

    return (
        <>
            <DialogTitle onClose={handleClose}>
                <Translate i18nKey={'pryaniky.vacancy.request.comment.header'} />
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: '16px'
                }}
            >
                <TextField
                    label={<Translate i18nKey={'comment'} />}
                    fullWidth
                    multiline
                    minRows={4}
                    value={comment}
                    variant={'outlined'}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                />
                <FilesUploader
                    multiple={false}
                    selectButtonProps={{
                        inputId: 'createNewsUploadFile',
                        variant: 'outlined',
                        children: <Translate i18nKey={'pryaniky.news.create.selectFile'} />
                    }}
                    removeFile={(item) => {
                        // if (item.response?.data?.[0].id) API.files.remove(item.response?.data?.[0].id);
                        removeFile(item);
                    }}
                    files={files}
                    onFileChange={(...args) => {
                        files[0] && removeFile(files[0]);
                        return onFileChange(...args);
                    }}
                    inputAccept={inputAccept}
                />
            </DialogContent>
            <DialogActions
                acceptText={<Translate i18nKey={'send'} />}
                isLoading={filesIsLoading || isLoading}
                onAccept={sendRequest}
                onClose={handleClose}
            />
        </>
    );
};

export const SendRespond = createModal(SendRespondPr, {
  maxWidth: 'sm',
  fullWidth: true,
  PaperProps: {
    style: {
      backgroundColor: '#fff',
      // overflowY: 'unset'
    }
  },
  TransitionComponent,
  scroll: 'body',
});