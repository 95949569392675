import { cn } from '@bem-react/classname';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { Translate } from 'localization';
import { Box, styled } from 'muicomponents/src';
import { DatePicker } from 'muicomponents/src/DatePicker/DatePicker';
import { ArrowDropDown, ArrowDropUp } from 'muicomponents/src/Icons/Icons';
import { ActionButton } from 'muicomponents/src/ItemsListDialog/ActionButton/ActionButton';
import { CheckboxSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckboxForm';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TAdditionalNewsFilter } from 'redux/search/interfaces';
import { IRequest } from 'utils/src/requests/service';
import { API, checkResponseStatus } from 'utils/src/utils';
import { formatDateNumbers } from 'utils/src/utils.dates';
import { IFilterProps, SuggestersBox } from '../index';
import { FilterNewsUserSuggesterMui } from './FilterNews.styled';

export const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}) as typeof MenuItem;

export const cnClassName = cn('NewsSearchFilters');
let request: IRequest;
const optionMapper = (v: any) => ({ label: v.displayName, value: v.type });

export const FilterNews: FC<IFilterProps<TAdditionalNewsFilter>> = ({
    filter,
    onChangeFilter,
    filterCleanButton,
    confirmFiltersByButton,
    filterConfirmButton,
}) => {
    const [newsTypesState, setNewsTypesState] = useState<any[]>([]);

    // const catSelected = newsTypesState ? newsTypesState.filter(v => chosenCatFilters.includes(v.id)).map(optionMapper) : []
    const getNewsTypes = useCallback((search: string) => {
        if (request) request.ac.abort();
        request = API.suggesters.newsTypes(search);
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                // const dataArr = d.data.map(optionMapper);
                const dataArr = d.data.map((el: any) => ({
                    ...el,
                    label: Translate.t({ i18nKey: el.displayName }),
                    value: el.type,
                }));
                // const dataArr = [{ displayName: "Все публикации", type: "all" }, ...d.data].map((el: any) => ({ ...el, label: Translate.t({ i18nKey: el.displayName }), value: el.type }));
                return setNewsTypesState(dataArr);
                // return d.data;
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, []);

    useEffect(() => {
        getNewsTypes('');
    }, []);

    // заготовка под множественный выбор типов публикаций
    // const NewsTypesSelectorComponent = useMemo(() => {
    //   return <CheckboxSelector
    //     onChange={(value)=> console.log("value", value)}
    //     enableSearch
    //     value={filter.type as any}
    //     text={Translate.t({ i18nKey: 'pryaniky.filter.sort.publicationtype' })}
    //     options={newsTypesState}
    //   />
    // }, [newsTypesState])

    const newsFilterComponent = useMemo(() => {
        return (
            <>
                <CheckboxSelector
                    onChange={(value) => {
                        onChangeFilter((prev) => ({ ...prev, types: value.length !== 0 ? value : [] }));
                    }}
                    enableSearch
                    value={filter.types}
                    // icon={<GridViewIcon />}
                    checkBoxAll
                    text={Translate.t({ i18nKey: 'pryaniky.LMS.tracks.trackStats.materialType' })}
                    options={newsTypesState}
                />
                {/* <CheckButtonSelector
        value={(filter?.type as any)?.type || "all"}
        onChange={(value) => {
          onChangeFilter((prev) => ({ ...prev, type: newsTypesState.find((el) => el.type === value) }))

        }}
        options={newsTypesState}
        defaultText={Translate.t({ i18nKey: 'pryaniky.filter.sort.publicationtype' })}

      /> */}
            </>
        );
    }, [newsTypesState, filter]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const datePickerComponent = useMemo(() => {
        return (
            <>
                <ActionButton
                    textColor={'gray'}
                    endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
                    onClick={handleClick}
                    sx={{ minWidth: '200px' }}
                >
                    {filter.startDate && filter.endDate
                        ? `${formatDateNumbers(filter.startDate, false)} — ${formatDateNumbers(filter.endDate, false)}`
                        : Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.period' })}
                </ActionButton>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: '12px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <Box>
                                <DatePicker
                                    type="date"
                                    value={filter.startDate}
                                    onChange={(date) => {
                                        onChangeFilter((prev) => ({ ...prev, startDate: date || '' }));
                                        confirmFiltersByButton && confirmFiltersByButton(true);
                                    }}
                                    TextFieldProps={{ size: 'medium', sx: { marginBottom: '4px' } }}
                                    label={<Translate i18nKey={'pryaniky.rating.rules.list.item.startDate'} />}
                                    maxDate={filter.endDate as any}
                                    disableFuture
                                />
                            </Box>
                            <Box>
                                <DatePicker
                                    type="date"
                                    value={filter.endDate}
                                    onChange={(date) => {
                                        onChangeFilter((prev) => ({ ...prev, endDate: date || '' }));
                                        confirmFiltersByButton && confirmFiltersByButton(true);
                                    }}
                                    TextFieldProps={{ size: 'medium' }}
                                    label={<Translate i18nKey={'pryaniky.rating.rules.list.item.endDate'} />}
                                    minDate={filter.startDate as any}
                                    disableFuture
                                />
                            </Box>
                        </Box>
                        {filterConfirmButton}
                    </Box>
                </Popover>
            </>
        );
    }, [newsTypesState, filter, open, filterConfirmButton]);

    return (
        <SuggestersBox className={cnClassName('')}>
            {newsFilterComponent}

            <FilterNewsUserSuggesterMui
                className="primaryColor3-borderColor primaryColor3-text"
                TextFieldProps={{
                    label: Translate.t({ i18nKey: 'pryaniky.modal.wikipage.filter.author' }),
                }}
                noAdornment={true}
                size={'small'}
                requestAdditionalParams={{ excludeMe: false }}
                value={filter.author}
                onChange={(event, value: any) => {
                    onChangeFilter((prev) => ({ ...prev, author: value }));
                }}
            />

            {datePickerComponent}

            {filterCleanButton}
        </SuggestersBox>
    );
};
