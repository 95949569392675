import React, { FC, useState, createContext, useContext, useMemo } from 'react'
import { Box, Button, Chip, Typography } from 'muicomponents/src';
import { generatePath, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from 'utils/src/CommonRedux/users/selectors';
import { Translate } from 'localizations/Translate';
import { ArrowBlock, BlackTypography, GrayTypography, NameTypography, PaperWrap, SeparatedBox } from './styled';
import DataBlockSkeleton from './Skeleton/DataBlockSkeleton';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { setStatus, setUserStatus } from 'utils/src/CommonRedux/users/actions';
import { StatusPopover } from './StatusPopover/StatusPopover';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { cnInfoBlock } from '../../InfoBlock.index'
import { FieldRenderProfileView } from "./FieldRenderProfileView"
import { Link } from 'muicomponents/src/Link/Link';
import urls from 'routes/urls';
import { SOCUnitShort, SOCUserOrgChart } from 'utils/src/BaseTypes/units.types';
import { PartOrgChartAccordion } from 'muicomponents/src/ItemsListDialog/UserListItem/UserListItem';
import { UserOrgchartBreadcrumbs } from 'muicomponents/src/Breadcrumbs';
import { cn } from '@bem-react/classname';

const cnUserDataBlock = cn('UserDataBlock');

export const Data: FC = () => {

  const dispatch = useDispatch()
  const { id }: { id: string } = useParams()
  const userId = id
  const user = useSelector(getUserById(userId, true))
  const authUser = useSelector(getAuthUser)
  const isAuthUserAdmin = authUser.baseData.isAdmin
  const isUserPageOwner = userId === authUser.baseData.id

  const [isInfoBlockCollapsed, setIsInfoBlockCollapsed] = useState(false)
  const Arrow = ({ up }: { up: boolean }) => {
    return (
      <ArrowBlock onClick={() => setIsInfoBlockCollapsed(!isInfoBlockCollapsed)} >
        <Button
          className={cnUserDataBlock('AdditionalFields-More')}
          sx={{
            textTransform: "inherit",
            "&:hover": {
              backgroundColor: "inherit",
            }
          }}
          variant={"text"}
          color='primary'
        >
          {up ? <Box display='flex' alignItems='center' sx={{ marginLeft: '-14px' }}>
            <ExpandLessIcon className={cnInfoBlock("IconLess CustomStyle primaryColor3-text")} color='primary' sx={{ marginRight: "4px", width: '34px', height: '34px' }} />
            <Typography color='primary'>{Translate.t({ i18nKey: 'pryaniky.profile.hide.else' })}</Typography>
          </Box>
            : <Box display='flex' alignItems='center' sx={{ marginLeft: '-14px' }}>
              <ExpandMoreIcon className={cnInfoBlock("IconMore CustomStyle primaryColor3-text")} sx={{ marginRight: "4px", width: '34px', height: '34px' }} />
              <Typography color='primary'>{Translate.t({ i18nKey: 'pryaniky.profile.else' })}</Typography>
            </Box>}
        </Button>
      </ArrowBlock>
    )
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleStatusClose = () => {
    setAnchorEl(null);
  }
  const saveStatus = (text: string) => {
    dispatch(setUserStatus(userId, text))
    handleStatusClose();
  }
  const onChangeStatus = (e: any) => {
    dispatch(setStatus(userId, e.target.value));
  }

  const mainUserOrgChart = useMemo(() => {
    return user?.baseData.userOrgChart?.[0] as SOCUserOrgChart | undefined;
  }, [user?.baseData.userOrgChart]);

  const partUserOrgChart = useMemo(() => {
    return (user?.baseData.userOrgChart?.slice(1) || []) as SOCUserOrgChart[];
  }, [user?.baseData.userOrgChart]);

  if (!user) return <DataBlockSkeleton />

// console.log("user.profileData.userHeadFieldsOriginal", user.profileData.userHeadFieldsOriginal)

  // const showCollapseble = !user.profileData ? false : user.profileData.userHeadFieldsOriginal
  //   .filter((v: any) => !(v.field.fieldType === 'Files'))
  //   .filter((v: any) => !(v.field.fieldType === 'AdditionalUserId' && !isAuthUserAdmin))
  //   .some((c: any) => !!c.value)

  const showCollapseble = !user.profileData ? false : user.profileData.userHeadFieldsOriginal
    .filter((v: any) => !(v.field.fieldType === 'Files'))
    .filter((v: any) => !(v.field.fieldType === 'AdditionalUserId' && !isAuthUserAdmin))
    .filter((v: any) =>!( v.field.showInHeadType === "ShowInHeadFields"))
    .some((c: any) => !!c.value)

// console.log("showCollapseble", showCollapseble)

  return <PaperWrap className={cnUserDataBlock()}>

    <NameTypography variant='h6' color='ActiveCaption' sx={(user.baseData.statusText || isUserPageOwner) && { mb: 0 }} >
      {user.baseData.displayName}
    </NameTypography>

    <Typography variant='body1' sx={{ mb: 2 }}>
      {user.baseData.statusText}
    </Typography>

    {
      isUserPageOwner &&
      <>
        <Button id={popoverId} size='small' variant='text' sx={{ mb: 1, p: 0 }}
          onClick={(e) => isUserPageOwner && handleStatusClick(e)} >
          <Typography textTransform={'none'} variant='body1' color='primary' textAlign='left'>
            {Translate.t({ i18nKey: 'pryaniky.profile.addStatus' })}
          </Typography>
        </Button>

        <StatusPopover
          popoverId={popoverId}
          anchorEl={anchorEl} open={open}
          handleStatusClose={handleStatusClose}
          saveStatus={saveStatus}
          statusText={user.baseData.statusText}
          onChangeStatus={onChangeStatus} />
      </>
    }

    {
      !!mainUserOrgChart
      ? <>
          <SeparatedBox>
            {
              !!mainUserOrgChart.position
              && <>
                <GrayTypography variant='body1' color='GrayText'>
                  <Translate i18nKey={"pryaniky.profile.position"} />
                </GrayTypography>
                <BlackTypography variant='body1'>
                  {mainUserOrgChart.position.displayName}
                </BlackTypography>
              </>
            }
            {
              !!mainUserOrgChart.unitHierarchy.length
              && <>
                <GrayTypography variant='body1' color='GrayText'>
                  <Translate i18nKey={"pryaniky.profile.department"} />
                </GrayTypography>
                <UserOrgchartBreadcrumbs>
                {
                  mainUserOrgChart.unitHierarchy.map(el => <Link href={generatePath(urls.users) + `?units=${el.id}`}>{el.displayName}</Link>)
                }
                </UserOrgchartBreadcrumbs>
              </>
            }
          </SeparatedBox>
      </>
      : <>
        {!!user.baseData.positionTags?.length &&
          <SeparatedBox>
            <GrayTypography variant='body1' color='GrayText'>
              <Translate i18nKey={"pryaniky.profile.position"} />:
            </GrayTypography>
            <BlackTypography variant='body1'>
              {user.baseData.positionTags.map((v: any) => v.displayName).join(', ')}
            </BlackTypography>
          </SeparatedBox>
        }
    
        {!!user.baseData.divisionTags?.length  &&
          <SeparatedBox>
            <GrayTypography variant='body1' color='GrayText'>
              <Translate i18nKey={"pryaniky.profile.department"} />:
            </GrayTypography>
            <BlackTypography variant='body1'>
              {user.baseData.divisionTags.map((v: any) => v.displayName).join(', ')}
            </BlackTypography>
          </SeparatedBox>
        }
      </>
    }

    {
      !!partUserOrgChart.length
      && 
      <PartOrgChartAccordion
        title={
          <Typography color={'primary'} variant='body1'>
            <Translate i18nKey={'pryaniky.users.orgChart.partTime'} />
          </Typography>
        }
        sx={{
          [`&:before`]: {
            content: 'none'
          }
        }}
      >
        {
          partUserOrgChart.map(chart => {
            return (
              <SeparatedBox>
                {
                  !!chart.position
                  && <>
                    <GrayTypography variant='body1' color='GrayText'>
                      <Translate i18nKey={"pryaniky.profile.position"} />:
                    </GrayTypography>
                    <BlackTypography variant='body1'>
                      {chart.position.displayName}
                    </BlackTypography>
                  </>
                }
                {
                  !!chart.unitHierarchy.length
                  && <>
                    <GrayTypography variant='body1' color='GrayText'>
                      <Translate i18nKey={"pryaniky.profile.department"} />:
                    </GrayTypography>
                    <UserOrgchartBreadcrumbs className={'User'}>
                      {chart.unitHierarchy.map(el => <Link href={generatePath(urls.users) + `?units=${el.id}`}>{el.displayName}</Link>)}
                    </UserOrgchartBreadcrumbs>
                  </>
                }
                
              </SeparatedBox>
            )
          })
        }
      </PartOrgChartAccordion>
    }

    <FieldRenderProfileView
      isAuthUserAdmin={isAuthUserAdmin}
      fields={user.profileData.userHeadFieldsOriginal.filter((el: any) => el.field.showInHeadType === "ShowInHeadFields")}
    />


    {showCollapseble && <>
      {!isInfoBlockCollapsed ? <Arrow up={isInfoBlockCollapsed} />
        :
        <>

          <FieldRenderProfileView
            isAuthUserAdmin={isAuthUserAdmin}
            fields={user.profileData.userHeadFieldsOriginal.filter((el: any) => el.field.showInHeadType !== "ShowInHeadFields")}
          />

          <Arrow up={isInfoBlockCollapsed} />

        </>
      }
    </>}

  </PaperWrap>
};