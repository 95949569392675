/**
 * @packageDocumentation
 * @module List_type_wiki
 */
import Box from '@mui/material/Box';
import { DialogWikiEdit } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { DropUploader } from 'blocks/WIKIList/DropUploader/DropUploader';
import { Item as WikiItem } from 'blocks/WikiListNew/Items/Item/Item';
import { ItemList as WikiItemList } from 'blocks/WikiListNew/Items/ItemList/ItemList';
import i18n from 'localizations/i18n';
import { Translate } from 'localizations/Translate';
import { Dictionary } from 'lodash';
import { IconButton } from 'muicomponents/src';
import { FilePreviewActionsIconButton } from 'muicomponents/src/FileViewerNew/Item/FileViewerItem.styled';
import { Bookmark, BookmarkBorder, FileCopy, FileOpen } from 'muicomponents/src/Icons';
import { ImagesCollage } from 'muicomponents/src/ImagesCollage';
import { InfinityListWithCardbox } from 'muicomponents/src/InfinityList/InfinityListWithCardbox';
import { Link } from 'muicomponents/src/Link/Link';
import { Tooltip } from 'muicomponents/src/Tooltip';
import React, { ComponentProps, FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { getCurrentPageType, getCurrentPageTypeSettings, getPageType } from 'redux/actions/Widgets';
import { analyticWikiPageView } from 'redux/analytic/actions';
import { CustomSettings, PageTypes, TValue } from 'utils/src';
import { ContentViewTemplatePreview } from 'utils/src/BaseTypes/wikilist.types';
import { useDebouncedQueryState, useScrollListState } from 'utils/src/hooks';
import { getWikiPagesV4 } from 'utils/src/requests/requests.wikilist';
import { IRequest } from 'utils/src/requests/service';
import * as utils from 'utils/src/utils';
import { API, checkResponseStatus, generateLinkData } from 'utils/src/utils';
import { FilePreview } from '../../Files/Preview/FilePreview';
import { ContextMenu } from './ContextMenu/ContextMenu';
import { HeaderWikiWithFilters } from './HeaderWikiWithFilters/HeaderWikiWithFilters';
import { cnItem } from './Items/Item/Item.index';
import { IListTypeWikiProps, cnWikiList, mapDispatchToProps, mapStateToProps } from './List_type_wiki.index';
import './List_type_wiki.scss';
import { preapreCollectionAliasByContext } from './List_type_wiki.utils';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

async function getWikiListPromise(collectionAlias: string, opt: any) {
    const response = await getWikiPagesV4(collectionAlias, opt);
    return await response.r;
}

const method = (collectionAlias: string, opts: any, search?: string) =>
    async function (skipCount: number, count: number) {
        return (await getWikiListPromise(collectionAlias, { skipCount, count, search, ...opts })).data;
    };

/**
 * @todo
 * для галереи в список надо возвращать сам объект файла
 */

let request: IRequest;
export const ListWikiNewPresenter: FC<IListTypeWikiProps> = ({
    context,
    isAdmin,
    requestOptions,
    widget,
    layoutWikilist,
    ...props
}) => {
    const dispatch = useDispatch();

    let collectionAlias = preapreCollectionAliasByContext(context);

    const basePageType = useSelector(getCurrentPageType);
    const pageTypeSettings = useSelector(getCurrentPageTypeSettings);
    const pageType = pageTypeSettings?.type || basePageType;

    const [search, searchQuery, setSearch] = useDebouncedQueryState('search');
    const [hasRights, setHasRights] = useState<boolean>(false);
    const [dashFolderInfo, setDashFolderInfo] = useState<any[]>([]);

    const opts = {
        order: context.order
            ? context.order
            : CustomSettings.wikiFoldersDefaultOrder()?.[collectionAlias] || 'position',
        sort: context.sort ? context.sort : CustomSettings.wikiFoldersDefaultSort()?.[collectionAlias] || 'asc',
        type: context.type ? context.type : 'all',
        authors: context.author?.id,
        archived: context.archived,
        catFilter: context.catFilter !== '' ? context.catFilter : undefined,
        searchOnlyTitle: context.searchOnlyTitle,
        draft: context.draft,
        showFavorites: context.showFavorites ? context.showFavorites : false,
    };

    const getDashFolder = useCallback(() => {
        request = API.suggesters.search('pagecollections', 'dash', -1, true);
        return request.r.then((d: any) => {
            if (checkResponseStatus(d)) {
                return setDashFolderInfo(d.data);
            } else {
                if (d.error_code === 404 || d.dom_error === 'AbortError') throw d;
            }
        });
    }, []);

    useEffect(() => {
        if (collectionAlias === 'dash') {
            getDashFolder();
        }
    }, []);

    const {
        isFinished,
        isLoading,
        loadMore,
        triggerRef,
        values,
        error: { withError },
        actions: { updateItem, removeItems, prependItems },
    } = useScrollListState(method(collectionAlias, opts, searchQuery as string), [
        searchQuery,
        context.catFilter,
        context.searchOnlyTitle,
        context.order,
        context.sort,
        context.type,
        context.author,
        context.archived,
        collectionAlias,
        context.draft,
        context.showFavorites,
    ]);

    useLayoutEffect(() => {
        getRights();
    }, []);

    /**
     *
     * получает права, возвращает boolean
     * @returns
     */
    const getRights = () =>
        utils.API.system.sagas
            .getCheckRights('PagesV2')
            .then((value) => {
                if (value.error_code === 0) {
                    setHasRights(value.data);
                }
            })
            .catch(() => {
                console.error('error');
            });

    const isCardList = useMemo(() => {
        return context?.viewType === 0 && pageType !== PageTypes.imageGallery;
    }, [context?.viewType, pageType]);

    const valuesDictionaryByFileId = useRef<Dictionary<typeof values[number]>>({});

    const galleryItems = useMemo(() => {
        if (pageType !== PageTypes.imageGallery && pageType !== PageTypes.videoGallery) return undefined;
        const items = values
            ?.map((el) => {
                const elType = getPageType(el);
                if (elType !== PageTypes.file) return;
                if (el.file) valuesDictionaryByFileId.current[el.file.id] = el;
                return el.file as NonNullable<typeof el.file>;
            })
            .filter(Boolean);
        return items as NonNullable<Required<typeof items>>;
    }, [values, pageType]);

    const prepareItemLink = useCallback(
        (itemId: TValue<typeof valuesDictionaryByFileId.current>['id']) => {
            const pageItem = valuesDictionaryByFileId.current[itemId];
            if (!pageItem) return undefined;
            const outLink =
                (pageItem.url.indexOf('http') === 0 || pageItem.url.indexOf('//') === 0) &&
                !pageItem.url.includes(window.location.hostname);
            let href = outLink || pageItem.url[0] === '/' ? pageItem.url : '/' + pageItem.url;
            return href;
        },
        [pageTypeSettings]
    );

    const copyFileLink = useCallback((link: string) => {
        const linkData = generateLinkData(link);
        let linkToClipboard = link;
        if (linkData.type === 'rlink') {
            linkToClipboard = window.location.origin + linkToClipboard;
        }
        navigator.clipboard.writeText(linkToClipboard);
    }, []);

    const showImagesPreview = useCallback<
        NonNullable<NonNullable<ComponentProps<typeof ImagesCollage>['ItemProps']>['onClick']>
    >(
        (e, item) => {
            if (
                !galleryItems ||
                pageTypeSettings?.settings?.layouts.preview !== ContentViewTemplatePreview.previewer ||
                (pageType !== PageTypes.imageGallery && pageType !== PageTypes.videoGallery)
            )
                return;
            e.preventDefault();
            const initFileIndex = galleryItems.findIndex((el) => el.id === item.id);
            FilePreview({
                files: galleryItems || [],
                filesNewsIds:
                    galleryItems?.reduce(
                        (a, c) => ({
                            ...a,
                            [c.id]: valuesDictionaryByFileId.current[c.id].allowComments
                                ? valuesDictionaryByFileId.current[c.id].newsId
                                : '',
                        }),
                        {} as Parameters<typeof FilePreview>['0']['filesNewsIds']
                    ) || {},
                initFileIndex,
                onActiveChange: (file, index, action) => {
                    dispatch(analyticWikiPageView(valuesDictionaryByFileId.current[file.id]));
                },
                getActionForItem: (image, actions) => {
                    const pageActions = valuesDictionaryByFileId.current[image.id].actions?.map((el) => el.id);
                    const downloadIsAllow = pageActions
                        ? pageActions.includes('download') || pageActions.includes('file.download')
                        : true;
                    if (!downloadIsAllow) {
                        return actions.filter((el) => el !== 'download');
                    }
                    return actions;
                },
                additionalRightControls: (image) => {
                    let href = prepareItemLink(image.id);
                    if (!href) return {};
                    return (
                        <>
                            <Tooltip title={<Translate i18nKey="pryaniky.file.copyLink" />} disableInteractive>
                                <FilePreviewActionsIconButton
                                    className={'CopyLink'}
                                    onClick={() => copyFileLink(href!)}
                                >
                                    <FileCopy />
                                </FilePreviewActionsIconButton>
                            </Tooltip>
                            <Tooltip title={<Translate i18nKey="openFilePage" />} disableInteractive>
                                <FilePreviewActionsIconButton className={'OpenFilePage'} component={Link} {...{ href }}>
                                    <FileOpen />
                                </FilePreviewActionsIconButton>
                            </Tooltip>
                        </>
                    );
                },
            });
        },
        [pageType, pageTypeSettings, galleryItems, prepareItemLink]
    );

    const linksClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: typeof values[number]) => {
            const pageType = getPageType(item);
            if (pageType !== PageTypes.file) return;
            showImagesPreview(e, item.file!);
        },
        [showImagesPreview]
    );

    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites).r.then((response) => {
            if (!response) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
            else if (response.error_code === 0) {
                toast.success(
                    <Translate
                        i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`}
                    />
                );
                updateItem(data, { ...data, isFavorites: !data.isFavorites });
            }
        });
    };

    return (
        <Box className={cnWikiList(context.gId ? 'isGroup' : 'isWikilistBase')}>
            {(isAdmin || context.isGroupAdmin || hasRights || layoutWikilist?.editable) && (
                <DropUploader
                    allowTypes={
                        pageType === PageTypes.imageGallery
                            ? ['image/*']
                            : pageType === PageTypes.videoGallery
                            ? ['video/*']
                            : undefined
                    }
                    currentPageId={context.currentPageId}
                    onComplete={(values) => {
                        prependItems(values);
                    }}
                    collectionAlias={preapreCollectionAliasByContext(context)}
                />
            )}

            <HeaderWikiWithFilters
                layoutWikilist={layoutWikilist}
                dashFolderInfo={dashFolderInfo}
                context={context}
                hasRights={hasRights || layoutWikilist?.editable}
                isAdmin={isAdmin || hasRights}
                prependItems={prependItems}
                widget={widget}
            />
            <Box component="div">
                <InfinityListWithCardbox
                    className={cnWikiList(isCardList ? 'CardView' : 'ListView')}
                    isError={withError}
                    triggerRef={triggerRef}
                    isFinished={isFinished}
                    isLoading={isLoading}
                    itemsCount={values.length}
                    cardsList={isCardList}
                    titleEmpty={
                        !context.coll && !context.search
                            ? i18n.t('pryaniky.list.wiki.emptyList.withoutcollection.title')
                            : i18n.t('pryaniky.list.wiki.emptyList.title')
                    }
                    textEmpty={
                        !context.coll && !context.search
                            ? i18n.t('pryaniky.list.wiki.emptyList.withoutcollection.text', { query: searchQuery })
                            : searchQuery && searchQuery !== ''
                            ? i18n.t('pryaniky.list.wiki.emptyList.text')
                            : undefined
                    }
                    loadMore={loadMore}
                >
                    {context.viewType === 0 &&
                        values &&
                        (pageType === PageTypes.imageGallery ? (
                            <ImagesCollage
                                className={pageTypeSettings?.settings?.layouts?.preview}
                                images={galleryItems || []}
                                ItemProps={{
                                    ItemActionsComponent: ({ item }) => {
                                        const data = valuesDictionaryByFileId.current[item.id];
                                        const showControls = isAdmin || hasRights || context.isGroupAdmin;
                                        return (
                                            <>
                                                {useFavoriteWikiItems && (
                                                    <Box
                                                        sx={{
                                                            height: '36px',
                                                            width: '36px',
                                                            background: '#fff',
                                                            borderRadius: '50%',
                                                            opacity: data.isFavorites ? 1 : 0,
                                                        }}
                                                        className={cnItem(`Actions_Favorite`)}
                                                    >
                                                        <Tooltip
                                                            title={
                                                                <Translate
                                                                    i18nKey={`pryaniky.modal.wikipage.${
                                                                        data.isFavorites ? 'unFavorites' : 'toFavorites'
                                                                    }`}
                                                                />
                                                            }
                                                        >
                                                            <span>
                                                                <IconButton
                                                                    color={'primary'}
                                                                    sx={{
                                                                        padding: '5px',
                                                                        '&:hover': {
                                                                            background: '#fff',
                                                                        },
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        favoriteWikiPage(data);
                                                                    }}
                                                                >
                                                                    {data.isFavorites ? (
                                                                        <Bookmark />
                                                                    ) : (
                                                                        <BookmarkBorder />
                                                                    )}
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                                {(showControls || (layoutWikilist?.isEditable && data.editable)) && (
                                                    <div className={cnItem('Actions_Hedaer')}>
                                                        <ContextMenu
                                                            data={data}
                                                            context={{
                                                                ...context,
                                                                collectionAlias:
                                                                    preapreCollectionAliasByContext(context),
                                                                gId: context.gId,
                                                            }}
                                                            updateItemList={updateItem}
                                                            removeItems={removeItems}
                                                            isAdmin={showControls || layoutWikilist?.isEditable}
                                                            onEdit={() =>
                                                                DialogWikiEdit({
                                                                    updateItemList: updateItem,
                                                                    context: context,
                                                                    isShown: false,
                                                                    isAdmin: showControls,
                                                                    onClose: () => {},
                                                                    parentFolderModerators: layoutWikilist?.moderators,
                                                                    parentFolderViewers: layoutWikilist?.viewers,
                                                                    data: data,
                                                                    id: data.id,
                                                                })
                                                                    .then(() => {})
                                                                    .catch(() => {})
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        );
                                    },
                                    onClick: showImagesPreview,
                                    className: cnItem(),
                                    component:
                                        pageTypeSettings?.settings?.layouts?.preview === ContentViewTemplatePreview.page
                                            ? Link
                                            : undefined,
                                    getAdditionalProps: (image: any) => {
                                        let href = prepareItemLink(image.id);
                                        if (!href) return {};
                                        return {
                                            href,
                                        };
                                    },
                                }}
                            />
                        ) : (
                            values.map((item) => (
                                <WikiItem
                                    key={item.id}
                                    showControls={isAdmin || hasRights || context.isGroupAdmin}
                                    data={item}
                                    updateItemList={updateItem}
                                    removeItems={removeItems}
                                    parentFolderModerators={layoutWikilist?.moderators}
                                    parentFolderViewers={layoutWikilist?.viewers}
                                    isEditable={layoutWikilist?.isEditable}
                                    onItemLinkClick={(e: any) => linksClick(e, item)}
                                    context={{
                                        ...context,
                                        collectionAlias: preapreCollectionAliasByContext(context),
                                        gId: context.gId,
                                    }}
                                />
                            ))
                        ))}

                    {context.viewType === 1 &&
                        values &&
                        values.map((item) => (
                            <WikiItemList
                                key={item.id}
                                showControls={isAdmin || hasRights || context.isGroupAdmin}
                                data={item}
                                updateItemList={updateItem}
                                removeItems={removeItems}
                                parentFolderModerators={layoutWikilist?.moderators}
                                parentFolderViewers={layoutWikilist?.viewers}
                                isEditable={layoutWikilist?.isEditable}
                                onItemLinkClick={(e: any) => linksClick(e, item)}
                                context={{
                                    ...context,
                                    collectionAlias: preapreCollectionAliasByContext(context),
                                    gId: context.gId,
                                }}
                            />
                        ))}
                </InfinityListWithCardbox>
            </Box>
        </Box>
    );
};

export const ListWikiNew = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListWikiNewPresenter));
