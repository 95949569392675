import React, { FC, useState, useEffect } from 'react';
import { ComponentInjector } from 'utils/src/ComponentInjector';
import { WidgetDialogBox } from './EventsListWidgetSettingsDialog.styled';
import { IEventsListWidgetSettingsProps } from './EventsListWidgetSettingsDialog.index';
import { CalendarsSuggester, TextField } from 'muicomponents/src';
import { IEventsListWidgetProps } from '../EventsListWidget.index';
import { Translate } from 'localization';
import { validate_v2, ValidateRulesObject, ValidateResponse } from 'utils/src/validate_v2';
import { SCalendar } from 'utils';
import { pick } from 'lodash';

const validateRules: ValidateRulesObject<{
    title?: string | undefined;
    calendar?: SCalendar[];
}> = {
    calendar: {
        notNull: {
            value: true,
        },
    },
};

export const EventsListWidgetSettingsDialog: FC<IEventsListWidgetSettingsProps> = ({ onChange, setValid, data }) => {
    const [state, setState] = useState<NonNullable<IEventsListWidgetProps['settings']>>(
        data || { calendar: undefined, title: undefined }
    );
    const [errors, setErrors] = useState<ValidateResponse<NonNullable<IEventsListWidgetProps['settings']>>>({});

    useEffect(() => {
        onChange({
            settings: state,
            data: state,
        });
    }, [state]);

    useEffect(() => {
        setValid(Array.isArray(state.calendar) && state.calendar.length > 0);
    }, [state]);

    // useEffect(() => {
    //     setValid(errors.calendar === undefined);
    // }, [errors.calendar]);

    return (
        <WidgetDialogBox>
            <TextField
                value={state.title}
                onChange={(e) => {
                    setState({ ...state, title: e.target.value as typeof state.title });
                }}
                label={<Translate i18nKey={'pryaniky.eventslist.settings.label.title'} />}
            />
            <CalendarsSuggester
                onChange={(e, value) => {
                    setState({ ...state, calendar: value as typeof state.calendar });
                    setErrors({
                        ...errors,
                        ...validate_v2(
                            {
                                calendar: value,
                            },
                            pick(validateRules, 'calendar')
                        ),
                    });
                }}
                multiple
                value={(state.calendar as any) || undefined}
                placeholder={Translate.t({ i18nKey: 'pryaniky.eventslist.settings.label.calendar' })}
            />
        </WidgetDialogBox>
    );
};

export const initWidgetAddition = () => {
    ComponentInjector.getInstance().addComponent('WAddition', EventsListWidgetSettingsDialog, 'pryaniky/eventslist');
    ComponentInjector.getInstance().addComponent('WAddition', EventsListWidgetSettingsDialog, 'pryaniky/wiki');
    ComponentInjector.getInstance().addComponent('WAddition', EventsListWidgetSettingsDialog, 'pryaniky/html');
};
export const initWidgetSettings = () => {
    ComponentInjector.getInstance().addComponent('WSettings', EventsListWidgetSettingsDialog, 'pryaniky/eventslist');
    ComponentInjector.getInstance().addComponent('WSettings', EventsListWidgetSettingsDialog, 'pryaniky/wiki');
    ComponentInjector.getInstance().addComponent('WSettings', EventsListWidgetSettingsDialog, 'pryaniky/html');
};
