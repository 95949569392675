import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, styled } from 'muicomponents/src';
import { IContentItemListProps, cnHierarchyWidgetNew } from '../Widget_type_hierarchyNew.index';
import { Translate } from 'localization';
import { ContentItem } from './ContentItem';

const ButtonNoTransform = styled(Button)({
    textTransform: 'none',
    width: 'fit-content',
});

export const ContentItemsList = ({
    items,
    onDelete,
    countOfVisibleItemsInWidget,
    totalCount,
    onClickShowMore,
    ...props
}: IContentItemListProps) => {
    const { settings } = props;
    const hierarchyName = settings?.hierarchy.name;

    const itemsInContent: JSX.Element[] = useMemo(() => {
        const newItemsInContent: JSX.Element[] = [];
        if (Array.isArray(items)) {
            for (let index = 0; index < Math.min(items.length, countOfVisibleItemsInWidget); index++) {
                newItemsInContent.push(
                    <ContentItem
                        item={items[index]}
                        hierarchyName={hierarchyName}
                        onDelete={onDelete}
                        key={items[index].id}
                    />
                );
            }
        }
        return newItemsInContent;
    }, [items]);
    return (
        <Box className={cnHierarchyWidgetNew('Content')} sx={{ display: 'flex', gap: '18px', flexDirection: 'column' }}>
            {itemsInContent}
            {itemsInContent.length < (totalCount || items.length) && (
                <ButtonNoTransform variant="text" onClick={onClickShowMore}>
                    <Translate i18nKey={'pryaniky.widgets.users.participants.moex.more'} />
                </ButtonNoTransform>
            )}
        </Box>
    );
};
