import { VacanciesListItem } from 'muicomponents/src/Vacancies/ListItem/VacanciesListItem';
import React, { FC } from 'react';
import { useDSItem } from 'redux/dataStorage/hooks';
import { VacanciesListItemProps } from './VacanciesListItem.index';

export const VacanciesListItemWrapper: FC<VacanciesListItemProps> = ({ id, isAdmin, isAdminPanel, isLastItem }) => {
    const { item, changeItem, removeItem } = useDSItem('vacanciesShort', id);

    if (!item) return null;

    return <VacanciesListItem data={item} isAdmin={isAdmin} isLastItem={isLastItem} isAdminPanel={isAdminPanel} />;
};
