import { styled } from 'muicomponents/src';
import { UsersSuggester } from 'muicomponents/src/Suggester';

export const FilterNewsUserSuggesterMui = styled(UsersSuggester)({
    width: '100%',
    maxWidth: '400px',
    '.MuiAutocomplete-tag': {
        margin: 0,
    },
    ' .MuiAutocomplete-tag ': {
        margin: '5px',
    },
    '.MuiChip-root.MuiChip-outlined': {
        height: '21px',
        '.MuiChip-avatar': {
            width: '16px',
            height: '16px',
        },
    },
    '.MuiFormLabel-root': {
        lineHeight: '17px',
    },
    '.MuiInputLabel-shrink': {
        lineHeight: '23px',
    },
}) as typeof UsersSuggester;
