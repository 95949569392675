import { CellComponentProps, CellMeasurerCache, TableVirtualized, TableVirtualizedItemProps } from "muicomponents/src/TableVirtualized";
import React, { cloneElement, ComponentProps, FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { UsersEventsTableContextProvider, useUsersEventsTableContext } from "./UsersEventsTable.context";
import { MonthCell } from "./MonthCell";
import { MonthHeader } from "./MonthHeader";
import { BottomBorderedBox, WrapperBox } from "./UsersEventsTable.styled";
import { TableContentHeaderBox } from "./Headers/Header.styled";
import { TableContentBox } from "./Cells/Cell.styled";
import { TableHeaderDaysBox, TableHeaderDivisionBox, TableHeaderNamesHeaderBox } from "./Headers/Header";
import { TableCellDaysBox, TableCellDivisionBox, TableCellNamesHeaderBox } from "./Cells/Cell";
import { tableConstantProps } from "../UsersEvents.constants";
import { Tooltip } from "muicomponents/src/Tooltip";
import { Translate } from "localizations/Translate";
import { list } from "utils/src/requests/requests.users";
import { checkResponseStatus, mainUrls, ResponseError, SUserModel } from "utils/src";
import { getDivisionName, UserMention } from "muicomponents/src/UserMention/UserMention";
import { Link } from "muicomponents/src/Link";
import { appGeneratePath } from "utils/src/utils.path";
import { getCalendarEventsRequestV4 } from "utils/src/requests/requests.calendar";
import moment from "moment";
import { useUsersEventsContext } from "../UsersEvents.context";
import { CalendarEvents } from "./UsersEventsTable.index";
import { keyDateFormat } from "./UsersEventsTable.utils";
import { floatingDotRound } from "utils/src/utils.numbers";

type UserEventsTableType = {
};

const TableItem: FC<TableVirtualizedItemProps & {
    Component: FC<ComponentProps<typeof MonthHeader | typeof MonthCell>>,
    month: string
}> = ({
    Component,
    measure,
    registerChild,
    month,
    ...props
}) => {
    const context = useUsersEventsTableContext();

    const dates = useMemo(() => {
        return context.monthesDictionary[month];
    }, [context.monthesDictionary[month]]);

    useEffect(() => {
        measure();
    }, [context.monthesDictionary[month]]);

    return (
        <Component
            {...props as any}
            {...dates}
        />
    );
};

type UserCustomData = {
    remaining_vacations: number [];
}

const UserEventsTablePr: FC<UserEventsTableType> = ({
}) => {

    const blockContext = useUsersEventsContext();
    const blockContextRef = useRef(blockContext);
    blockContextRef.current = blockContext;

    const context = useUsersEventsTableContext();
    const contextRef = useRef(context);
    contextRef.current = context;

    const [users, setUsers] = useState<SUserModel<UserCustomData>[]>([]);
    const usersRef = useRef(users);
    usersRef.current = users;
    const [events, setEvents] = useState<{[s: string]: CalendarEvents}>({});
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const getUsers = useCallback(async function() {
        setIsError(false);
        setIsLoading(true);
        try {
            const response = await list({
                count: 10,
                userId: blockContext.userId,
                extended: true,
                usersFromMyUnits: true,
                // запрос для подчиненых
                // selectedHeadUser: guid
                /** @todo перевести в настройки */
                hierarchyName: 'Оргструктура',
                userCustomDataKeys: [
                    {
                        key: "remaining_vacations",
                        expression: "$..[?(@.VacType=='Основной')].VacRest"
                    }
                ]
            }).r;
    
            if(checkResponseStatus(response)) {
                setUsers(response.data);
            } else {
                throw new ResponseError('user list response error')
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.error(error);
            }
            setIsError(true);
        } finally {
            setIsLoading(false);
        };
    }, []);

    useLayoutEffect(() => {
        getUsers();
    }, [blockContext.userId]);

    const getEvents = useCallback(async function() {
        setIsError(false);
        setIsLoading(true);
        try {
            const start = moment(contextRef.current.dateStart);
            const end = moment(contextRef.current.dateEnd);
            if(!start.isValid() || !end.isValid()) {
                throw new Error('dates is invalid')
            }
            let preparedCIds = blockContextRef.current.cIds;
            if(
                !!blockContextRef.current.settings.generatedCalerdarSettings.calendarId
                && !preparedCIds.includes(blockContextRef.current.settings.generatedCalerdarSettings.calendarId)
            ) {
                preparedCIds = [
                    ...preparedCIds,
                    blockContextRef.current.settings.generatedCalerdarSettings.calendarId
                ];
            }
            const response = await getCalendarEventsRequestV4({
                start: keyDateFormat(start),
                end: keyDateFormat(end),
                cIds: preparedCIds
            });
    
            if(checkResponseStatus(response)) {
                const eventsDisc: typeof events = {};
                for(const event of response.data.events) {
                    if(!eventsDisc[event.calendarId]) eventsDisc[event.calendarId] = [];
                    eventsDisc[event.calendarId].push(event);
                }
                setEvents(eventsDisc);
            } else {
                throw new ResponseError('user list response error')
            }
        } catch (error) {
            if(error instanceof ResponseError) {
                console.error(error);
            }
            setIsError(true);
        } finally {
            setIsLoading(false);
        };
    }, []);

    useLayoutEffect(() => {
        getEvents();
    }, [context.dateStart, context.dateEnd, blockContext.cIds, blockContext.settings.generatedCalerdarSettings.calendarId]);

    const cache = useMemo(() => new CellMeasurerCache({
        minHeight: tableConstantProps.estimatedRowSize
    }), []);

    useLayoutEffect(() => {
    }, [context.monthesArray, context.monthesDictionary]);

    const table = useMemo(() => {
        cache.clearAll();
        const header = [
            <TableHeaderNamesHeaderBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.colleagues' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.colleagues'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderNamesHeaderBox>,
            <TableHeaderDaysBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.vacationRec' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.vacationRec'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderDaysBox>,
            <TableHeaderDivisionBox
                {...{} as any}
            >
                <Tooltip title={Translate.t({ i18nKey: 'pryaniky.usersevents.header.division' })} overflowOnly>
                    <TableContentHeaderBox variant="body1">
                        <Translate i18nKey={'pryaniky.usersevents.header.division'} />
                    </TableContentHeaderBox>
                </Tooltip>
            </TableHeaderDivisionBox>,
            ...context.monthesArray.map(month => {
                return <TableItem
                    Component={MonthHeader}
                    month={month}
                    events={events}
                    {...{} as any}
                />
            })
        ];
        const body = users.map(user => {
            let userVacationNumber = user.customUserData?.remaining_vacations.reduce((a, c) => a + c, 0) || 0;
            userVacationNumber = floatingDotRound(userVacationNumber, 10);
            const isHeadUser = blockContextRef.current.userId === user.id;
            return [
                <TableCellNamesHeaderBox
                    isHeadUser={isHeadUser}
                    {...{} as any}
                >
                    <UserMention id={user.id}>
                        <TableContentBox variant='body2' component={Link} href={appGeneratePath(mainUrls.user.id, { id: user.id })}>
                            {user.displayName}
                        </TableContentBox>
                    </UserMention>
                </TableCellNamesHeaderBox>,
                <TableCellDaysBox
                    isHeadUser={isHeadUser}
                    {...{} as any}
                >
                    <Tooltip title={'2'} overflowOnly>
                        <TableContentBox variant='body2'>
                            {userVacationNumber}
                        </TableContentBox>
                    </Tooltip>
                </TableCellDaysBox>,
                <TableCellDivisionBox
                    isHeadUser={isHeadUser}
                    {...{} as any}
                >
                    <Tooltip title={getDivisionName(user.division, user.userOrgChart)} overflowOnly>
                        <TableContentBox variant='body2'>
                            {getDivisionName(user.division, user.userOrgChart)}
                        </TableContentBox>
                    </Tooltip>
                </TableCellDivisionBox>,
                ...context.monthesArray.map(month => {
                    return <TableItem
                        isHeadUser={isHeadUser}
                        Component={MonthCell}
                        month={month}
                        events={events}
                        userId={user.id}
                        {...{} as any}
                    />
                })
            ] 
        })
        return [
            header,
            ...body
        ]
    }, [users, events, context.monthesArray, context.monthesDictionary]);

    return (
        <WrapperBox>
            <TableVirtualized
                cache={cache}
                {...tableConstantProps}
            >
                {table}
            </TableVirtualized>
        </WrapperBox>
    );
};

type UserEventsTableProps = Pick<ComponentProps<typeof UsersEventsTableContextProvider>, 'dateEnd' | 'dateStart'> & {
    userId?: string;
};

export const UserEventsTable: FC<UserEventsTableProps> = ({
    dateEnd,
    dateStart
}) => {

    return (
        <UsersEventsTableContextProvider dateStart={dateStart} dateEnd={dateEnd}>
            <UserEventsTablePr />
        </UsersEventsTableContextProvider>
    );
}