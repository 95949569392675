import React, { FC, useEffect, useState, memo } from 'react'
import {
    cnClassName,
    mapDispatchToProps,
    mapStateToProps,
    SkillsWidgetNew
} from './SkillsNew.interface'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
// import './style.scss'
import { createDefaultSkill } from 'redux/sagas/Skills/types'
import SkillsHeader from './Header/SkillsHeader'
import SkillsList from './List/SkillsList'
// import Create from './Create'
import { Participants } from 'blocks/Dialogs/Skills/Participants/Participants'
import { StyledPaper } from './SkillsNew.styled'
import { ISkill } from 'redux/sagas/Skills/types'

type IOwnProps = SkillsWidgetNew.IOwnProps
type IPresenterProps = SkillsWidgetNew.IPresenterProps
type IStateProps = SkillsWidgetNew.IStateProps
type IDispatchProps = SkillsWidgetNew.IDispatchProps
type IState = SkillsWidgetNew.IState

export const SkillsNew: FC<IPresenterProps> = ({
    className,
    userid: ruserid,
    loadUserSkills,
    skills,
    createUserSkill,
    confirmSkill,
    currentUser,
    deleteSkill,
    isFinished,
    isLoading,

    ...props
}) => {
    const userid = ruserid;
    // const userid = ruserid || currentUser.baseData.id
    const [create, setCreate] = useState(false)
    const [title, setTitle] = useState('')
    const withError = (Boolean(title)) && !/^[\s0-9A-Za-zА-Яа-яЁё_]+$/gi.test(title)
    useEffect(() => {
        loadUserSkills(userid)
    }, [userid])

    const skillsArray = Object.values(skills || {}).filter(v => v)

    const onColmpleteCreate = (createdTitle?: string, createArr?: ISkill[]) => {
        const withError = (Boolean(title)) && !/^[\s0-9A-Za-zА-Яа-яЁё_]+$/gi.test(title)

        if (withError) return;
        if (!createdTitle && !createArr) {

            setCreate(false)
            return;
        }
        if (createdTitle) {

            const skill = createDefaultSkill()
            skill.tag.displayName = createdTitle
            setTitle('')
            setCreate(false)
            createUserSkill(userid, skill)
        }
        if (createArr) {
            createArr.forEach((skill: ISkill) => createUserSkill(userid, skill))
        }

    }

    const deleteSkills = (deleteArr: ISkill[]) => {
        deleteArr.forEach((skill: ISkill) => deleteSkill(userid, skill.tag.id));
    }

    let showControls = currentUser.baseData.id === userid
    showControls = currentUser.baseData.isAdmin ? true : showControls;

    let showCreate = !isLoading

    return <StyledPaper>
        {/* <Participants data={false} isShown={!!show} tId={show || ''} userid={userid} onClose={() => setShow(null)} /> */}
        <SkillsHeader
            withError={withError}
            showControls={showControls}
            showCreate={showCreate}
            onCancle={() => setCreate(false)}
            onComplete={onColmpleteCreate}
            onDelete={deleteSkills}
            currentUser={userid}
            skills={skillsArray}
        />
        {/*  {(showCreate && create) && <Create withError={withError} currentUser={currentUser} title={title} setTitle={setTitle} onComplete={onColmleteCreate} />}
       */}
        <SkillsList
            create={create}
            isLoading={isLoading}
            isFinished={isFinished}
            showControls={showControls}
            deleteSkill={deleteSkill}
            skills={skillsArray}
            confirmSkill={confirmSkill}
            userid={userid}
        />
    </StyledPaper>
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IState>(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(memo(SkillsNew)));
