import { Translate } from 'localizations/Translate';
import { Button } from 'muicomponents/src/Button/Button';
import { FilterAlt } from 'muicomponents/src/Icons';
import { CheckButtonSelector } from 'muicomponents/src/ItemsListDialog/ListParamsSelector/Forms/CheckButtonForm';
import { SearchInput } from 'muicomponents/src/SearchInput/SearchInput';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ListLite } from 'uielements';
import { adminUrls } from 'utils/src';
import { VacancyFilter } from 'utils/src/BaseTypes/vacancy';
import { getCurrentUserIsAdmin, moduleIsEnabled } from 'utils/src/CommonRedux/base/selectors';
import { appGeneratePath } from 'utils/src/utils.path';
import { VacanciesListItemWrapper } from '../ListItem/VacanciesListItem';
import { getVacanciesReducerFields } from '../redux/selectors.vacancies';
import { useVacanciesList } from './VacanciesList.hooks';
import { cnVacanciesList } from './VacanciesList.Index';
import {
    VacanciesEmptyListTextBox,
    VacanciesHeaderBox,
    VacanciesHeaderItemBox,
    VacanciesListBox,
} from './VacanciesList.styled';

export const VacanciesList: FC<{}> = ({}) => {
    const { list, error, loading, isFinished, loadMore, search, setSearch, filter, setFilter } = useVacanciesList();

    const isAdmin = useSelector(getCurrentUserIsAdmin);
    const isFullAccess = useSelector(getVacanciesReducerFields('fullAccess')).fullAccess;
    const isEmptyList = list.length === 0;
    const isAllowedEndorsements = useSelector(moduleIsEnabled('endorsements'));
    const filterOptions = useMemo(() => {
        return Object.typedKeys(VacancyFilter)
            .filter(
                (e) =>
                    isNaN(Number(e)) &&
                    e !== 'NotSet' &&
                    e !== 'All' &&
                    e !== 'RecomendedForMe' /*&& e !== 'ManagedByMe'*/
            )
            .filter((e) => !isAllowedEndorsements && e !== 'RecomendedForMe')
            .map((e) => {
                return {
                    value: e,
                    label: Translate.t({ i18nKey: `pryaniky.vacancies.list.filter.${e}` }),
                };
            });
    }, []);

    return (
        <>
            <VacanciesHeaderBox className={cnVacanciesList('Header')}>
                <VacanciesHeaderItemBox>
                    <SearchInput
                        label={<Translate i18nKey={'search'} />}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    {(isAdmin || isFullAccess) && (
                        <Button variant="contained" href={appGeneratePath(`/administration${adminUrls.vacancy.root}`)}>
                            <Translate i18nKey="create" />
                        </Button>
                    )}
                </VacanciesHeaderItemBox>
                <CheckButtonSelector
                    icon={<FilterAlt />}
                    value={filter}
                    onChange={(newValue) => {
                        setFilter(newValue as typeof filter);
                    }}
                    options={filterOptions}
                    defaultText={Translate.t({ i18nKey: 'pryaniky.vacancies.list.filter.title' })}
                />
            </VacanciesHeaderBox>
            <VacanciesListBox className={cnVacanciesList('Header')}>
                <ListLite isFinished={isFinished} isLoading={loading} loadMore={loadMore}>
                    {list.map((id, index) => {
                        return (
                            <VacanciesListItemWrapper
                                key={id}
                                id={id}
                                isAdmin={isAdmin}
                                isAdminPanel={false}
                                isLastItem={index === list.length - 1}
                            />
                        );
                    })}
                    {isEmptyList && !loading && (
                        <VacanciesEmptyListTextBox>
                            <Translate i18nKey="pryaniky.list.nothingFound.title" />
                        </VacanciesEmptyListTextBox>
                    )}
                </ListLite>
            </VacanciesListBox>
        </>
    );
};
