import React, { useMemo, useState } from 'react';
import i18n from '../../../../localizations/i18n';
import { toast } from 'react-toastify';
import "./WikiEditNew.scss";
import { connect } from "react-redux";
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import {
    IWikiEditOwnProps,
    IWikiEditProps,
    cnWikiEditDialog,
    mapWikiEditDispatchToProps,
    mapWikiEditStateToProps,
    IWikiEditDispatchProps,
    IWikiEditStateProps,
} from './WikiEditNew.index'
import { IStateType as IState } from 'redux/store';
import { Translate } from '../../../../localizations/Translate';
import { createModal, TransitionComponent } from 'utils/src/DialogCreator';
import { DialogTitle } from 'muicomponents/src/DialogParts/DialogTitle'
import { DialogContent } from 'muicomponents/src/DialogParts/DialogContent'
import { DialogActions } from 'muicomponents/src/DialogParts/DialogActions'
import { Tooltip } from 'muicomponents/src/Tooltip';
import { useFileUploader } from 'utils/src/hooks'
import { styled } from 'muicomponents/src/mui/system';
import { UsersSuggester, TagsSuggester, PageCollectionSuggester } from 'muicomponents/src/Suggester';
import { TextField } from 'muicomponents/src/TextField'
import {
    Divider,
    Box,
    FormControlLabel,
    Checkbox,
    Button,
    FilesUploader,
} from 'muicomponents/src';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'utils/src/hooks';
import { PreviewSelector } from 'muicomponents/src/PreviewSelector/PreviewSelector'
import { ImageCropperDialog } from 'blocks/ImageLoaderWithMui/ImageCropper/ImageCropper'
import { Select } from 'muicomponents/src/Select/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl } from 'muicomponents/src/FormControl/FormControl'
import { getPageBaseType, getWikiUrl } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { PageTypes } from 'utils/src';
import { useWikiItemViewersTitle } from '../WikiDialogs.utils';
import { GridView, HelpIcon, ViewList } from 'muicomponents/src/Icons';
import { ButtonGroup } from 'muicomponents/src/ButtonGroup';
import { NumberTextField } from 'muicomponents/src/TextField/TextField';
import { LinearProgress } from 'muicomponents/src/LinearProgress';

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: 0,
    marginRight: 0,
    width: '100%'

});

const statusOption = [
    { value: 'published', title: Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status.published' }), },
    { value: 'isDraft', title: Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status.isDraft' }) },
    { value: 'archived', title: i18n.t('pryaniky.wikilist.item.param.status.isArchived'), },
];

export const WikiEditDialogPresenter: React.FC<IWikiEditProps> = ({
    data: pdata,
    context,
    isShown,
    onClose,
    onConfirm,
    onChange = () => { },
    id,
    handleAccept,
    handleClose,
    updateItemList,
    isAdmin,
    parentFolderModerators,
    parentFolderViewers,
    ...props
}) => {

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);
    const [actualData1, setActualData1] = React.useState<typeof pdata | null>(null);
    const [isInheritingRootRights, setIsInheritingRootRights] = React.useState(false);
    const [moderators, setModerators] = React.useState<any[]>([]);
    const [viewers, setViewers] = React.useState<any[]>([]);
    const [urlBeforChange, setUrlBeforChange] = React.useState<string>("");
    const [error, setError] = React.useState(false);
    const [imgChanged, setImgChanged] = React.useState(false);

    const pageType = getPageBaseType(actualData1?.type);

    const typePage = pageType === PageTypes.page;
    const typeWikilist = pageType === PageTypes.wikilist;
    const typeUrl = pageType === PageTypes.url;
    const typeFile = pageType === PageTypes.file;

    React.useEffect(() => {
        getActualData(id);
    }, []);

    const {
        isLoading,
        files,
        onFileChange,
        removeFile,
        dndRef,
        dragEntered,
        setFiles,
        dndTriggerRef,
        uploadError,
        uploadDisabled
    } = useFileUploader({
        // defaultAttachments,
        uploadOnAdd: true,
        maxFilesCount: 1
    })
    React.useEffect(() => {
        if (actualData1?.file) {
            setFiles([actualData1?.file])
        }
    }, [actualData1?.file])

    React.useEffect(() => {
        if (actualData1 && (files.length >= 1) && files[0].isUploaded) {
            setActualData1({ ...actualData1, file: files[0].response?.data[0] })
            if (actualData1.title === '' || !actualData1.title) {
                setActualData1({ ...actualData1, title: files[0].name, url: files[0].name, file: files[0].response?.data[0] })
            }
        }
    }, [files])


    const handleCancel = () => {
        handleClose();
    };

    const getActualData = (id: string) => {
        return (
            utils.API.pages.getByIdSkipLayout(id, { collection: context.collectionAlias ? context.collectionAlias : "dash" })
                .r
                .then((response) => {
                    if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                    else if (response.error_code === 0) {
                        if(!response.data) return ;

                        const pageType = getPageBaseType(response.data.type);

                        const typePage = pageType === PageTypes.page;
                        const typeWikilist = pageType === PageTypes.wikilist;
                        const typeUrl = pageType === PageTypes.url;
                        const typeFile = pageType === PageTypes.file;

                        setIsInheritingRootRights(response.data.isInheritingRootRights);

                        setModerators([...response.data.moderators]);
                        setViewers([...response.data.viewers]);

                        setActualData1({
                            ...response.data,
                        });

                        const preparedUrl = typePage
                            ? (
                                response.data.url.indexOf("/pages/") !== -1
                                ? response.data.url.replace("/pages/", "")
                                : response.data.url.replace("pages/", "")
                            )
                            : (
                                response.data.url.indexOf("/wikilist/") !== -1
                                ? response.data.url.replace("/wikilist/", "")
                                : response.data.url.replace("wikilist/", "")
                            );


                        setUrlBeforChange(preparedUrl);
                        
                        if(typePage || typeWikilist) {
                            setActualData1({
                                ...response.data,
                                url: preparedUrl
                            });
                        }
                    }
                }
                )
        )

    }



    const deleteWikiPage = () => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>

                utils.API.pages.deletePage(id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                        }
                    })
        });
        handleClose();
    }

    const sendDataForEditPage = (isArchived?: boolean) => {
        if (actualData1) {
            setLoading(true);
            // const pageUrl = actualData1.url.indexOf("/pages/") === -1 || actualData1.url.indexOf("pages/") === -1 ? "/pages/" + actualData1.url : actualData1.url;
            // const wikilistUrl = "/wikilist/" + actualData1.url;
            // const absoluteUrl = actualData1.url;

            let link = getWikiUrl(actualData1 as any);

            utilsProj.confirm({
                text: i18n.t("pryaniky.modal.wikipage.confirm.edit"),

                onConfirm: () => {

                    return utils.API.pages.setSettings({
                        ...actualData1,
                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                        id: actualData1.id,
                        url: link,
                        title: actualData1.title,
                        moderators: (isInheritingRootRights && parentFolderModerators) ? [] : moderators,
                        tags: actualData1.tags,
                        collections: actualData1.collections,
                        type: actualData1.type || PageTypes.page,
                        titleImageId: actualData1.titleImageId,
                        hasCustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                        titleImageUrl: actualData1.titleImageUrl,
                        showInMainMenu: actualData1.showInMainMenu,
                        isInheritingRootRights: actualData1.collections?.find((el: any) => el.alias === "dash") ? false : isInheritingRootRights,
                        allowComments: actualData1.allowComments,
                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                        positionInCollection: Number(actualData1.positionInCollection),
                        viewers: (isInheritingRootRights && parentFolderViewers) ? [] : viewers,
                        file: actualData1.file,
                        isDraft: actualData1.isDraft
                    })
                        .r
                        .then((response) => {
                            if (!response) {
                                toast.error(i18n.t('pryaniky.toast.error.server'));
                            }
                            if (response.error_code !== 0) {
                                toast.error(response.error_text);
                                setLoading(false);
                            }
                            else if (response.error_code === 0) {
                                toast.success(i18n.t("edited.success"));

                                setActualData1({ ...actualData1, isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived })
                                if (actualData1) {
                                    updateItemList(pdata, {
                                        ...actualData1,
                                        collectionAlias: context.collectionAlias ? context.collectionAlias : "dash",
                                        id: actualData1.id,
                                        url: link,
                                        title: actualData1.title,
                                        moderators: actualData1.moderators,
                                        tags: actualData1.tags,
                                        collections: actualData1.collections,
                                        type: actualData1.type || PageTypes.page,
                                        titleImageId: actualData1.titleImageId,
                                        hasCustomTitleImage: actualData1.titleImageId === null || actualData1.titleImageId === undefined || actualData1.titleImageId === "" ? false : true,
                                        titleImageUrl: actualData1.titleImageUrl,
                                        showInMainMenu: actualData1.showInMainMenu,
                                        allowComments: actualData1.allowComments,
                                        defaultViewType: actualData1.defaultViewType ? actualData1.defaultViewType : 0,
                                        isArchived: isArchived !== undefined ? isArchived : actualData1.isArchived,
                                        positionInCollection: Number(actualData1.positionInCollection),
                                        viewers: actualData1.viewers,
                                        file: actualData1.file
                                    });
                                }
                                if (history.location.pathname.includes("/pages") && pdata?.url !== link && (actualData1.type === PageTypes.page || actualData1.type === PageTypes.file || !actualData1.type)) {
                                    history.push(`/${link}`);
                                }
                                handleClose();
                            }
                        });

                },
                onCancel: () => {
                    setLoading(false)
                },
            });
        }

    }
    const onChangeShowInMainMenu = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showInMainMenu: !actualData1.showInMainMenu })
    }

    const onChangeShowCategoryAsSelector = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, showCategoryAsSelector: !actualData1.showCategoryAsSelector })
    }

    const onChangeAllowComments = (e: any) => {
        if (actualData1) setActualData1({ ...actualData1, allowComments: !actualData1.allowComments })
    }

    const onChangeHideFooter = () => {
        if (actualData1) setActualData1({ ...actualData1, isHideFooter: !actualData1.isHideFooter })
    };

    const archivedPage = () => {
        if (actualData1) sendDataForEditPage(!actualData1.isArchived)
    }

    const Addorment = (typePage || typeFile) ? <p className={cnWikiEditDialog("child")}>/pages/</p> : (typePage || typeWikilist) ? <p className={cnWikiEditDialog("child")}>/wikilist/</p> : (typeUrl) && undefined

    const onChangeLinkInput = (e: any) => {
        const pageUrl = "pages/" + e.currentTarget.value;
        const wikilistUrl = "wikilist/" + e.currentTarget.value;
        (!typeUrl && urlBeforChange !== e.currentTarget.value) && onChangeLinkInputUseDebounce(typePage ? pageUrl : wikilistUrl);
        actualData1 && setActualData1({ ...actualData1, url: e.currentTarget.value })
    }

    const checkUrlIsExist = (value: string) => {
        utils.API.pages.isExists(value)
            .r
            .then((response) => {
                if (!response) {

                    toast.error(i18n.t('pryaniky.toast.error.server'));
                } else if (response.data === true) {
                    setError(true)
                    toast.error(i18n.t('pryaniky.wikilist.error.url.isExist'))
                } else if (response.data === false) {
                    setError(false)
                }
            })
    }

    const onChangeLinkInputUseDebounce = useDebounce(checkUrlIsExist, 1000, []);

    const onChangeIsInheritingRootRights = (e: any) => {
        setIsInheritingRootRights(!isInheritingRootRights)
        setModerators(!isInheritingRootRights && parentFolderModerators ? [...parentFolderModerators] : moderators)
        setViewers(!isInheritingRootRights && parentFolderViewers ? [...parentFolderViewers] : viewers);
    }

    const StatusSelect = useMemo(() => {
        if (actualData1) {
            return <FormControl className={cnWikiEditDialog("Content-ItemSelect")} sx={{margin: "8px 0 0 0", width:"100%"}}>
                <InputLabel id="demo-simple-select-helper-label">{Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status' })}</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    options={statusOption}
                    label={Translate.t({ i18nKey: 'pryaniky.wikilist.item.param.status' })}
                    value={statusOption.find((el) => actualData1.isDraft ? el.value === "isDraft" : actualData1.isArchived ? el.value === "archived" : el.value === "published")?.value}
                    onChange={(event) => setActualData1({ ...actualData1, isDraft: event.target.value === "isDraft", isArchived: event.target.value === "archived" })}
                />
            </FormControl>
        }
    }, [actualData1, actualData1?.isArchived, actualData1?.isDraft]);

    const dialogTitle = useMemo(() => {
        return <Translate
            i18nKey={'pryaniky.wikilist.edit.type'}
            values={{
                type: Translate.t({
                    i18nKey: !actualData1 ? '' : `pryaniky.wikilist.item.${actualData1.type}.accusative`
                }).toLowerCase()
            }}
        />
    }, [actualData1?.type]);
    
    const viewersTitle = useWikiItemViewersTitle(actualData1?.type);

    return (
        <>
            <DialogTitle onClose={handleClose}>
                {dialogTitle}
            </DialogTitle>
            {actualData1 ? <DialogContent className={cnWikiEditDialog("")}>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    <TextField
                        name="title"
                        fullWidth
                        value={actualData1.title}
                        autoFocus
                        className={cnWikiEditDialog("Input")}
                        label={
                            <Translate
                                i18nKey='pryaniky.wikilist.item.param.title'
                                values={{
                                    type: Translate.t({
                                        i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                        count: 2
                                    }).toLowerCase()
                                }}
                            />
                        }
                        onChange={(e: any) => setActualData1({ ...actualData1, title: e.currentTarget.value })} />
                </Box>

                {typePage && StatusSelect}

                <Box className={cnWikiEditDialog("Content-Item")}>
                    <TextField
                        name="description"
                        fullWidth
                        multiline
                        variant='outlined'
                        value={actualData1.description}
                        className={cnWikiEditDialog("Input")}
                        sx={{
                            ".MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline": {
                                minHeight: "41px"
                            }
                        }}
                        label={
                            <Translate i18nKey={`pryaniky.wikilist.item.param.description`} />
                        }
                        onChange={(e: any) => {
                            setActualData1({ ...actualData1, description: e.currentTarget.value })
                        }}
                    />
                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    {
                        !typeFile && <TextField
                            id="wikilink"
                            name="url"
                            fullWidth
                            // variant='outlined'
                            value={actualData1.url}
                            // style={{ marginLeft: (ddata.type === "url" || typeUrl) ? "0px" : "47px" }}
                            className={cnWikiEditDialog(error ? "ErrorLink" : "InputLink")}
                            autoFocus={error}
                            error={error || actualData1.url === ""}
                            helperText={error ? i18n.t('pryaniky.wikilist.error.url.isExist') :
                                actualData1.url === "" ? i18n.t('pryaniky.wikilist.error.url.isEmpty') : ""}
                            label={
                                <Translate
                                    i18nKey='pryaniky.wikilist.item.param.url'
                                    values={{
                                        type: Translate.t({
                                            i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                            count: 2
                                        }).toLowerCase()
                                    }}
                                />
                            }
                            onChange={(e: any) => onChangeLinkInput(e)}
                            InputProps={{
                                startAdornment: Addorment

                            }}
                        />

                    }

                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    {
                        typeFile && <Box
                            ref={dndTriggerRef}
                            sx={{ position: 'relative' }}
                        >
                            <FilesUploader
                                files={files}
                                multiple={false}
                                showProgressOnNewFiles
                                selectButtonProps={{
                                    children: <Translate
                                        i18nKey='pryaniky.wikilist.select.type'
                                        variablesI18nKeys={{
                                            type: 'pryaniky.wikilist.item.file'
                                        }}
                                    />,
                                    variant: 'outlined'
                                }}
                                error={Boolean(uploadError)}
                                helperText={uploadError?.error?.error_text}
                                disabled={uploadDisabled}
                                // className={cnWikiEditDialog("FileInput")}
                                // inputAccept="image/jpeg,image/png"
                                onFileChange={onFileChange}
                                removeFile={removeFile}
                            />
                        </Box>
                    }
                </Box>
                <Box className={cnWikiEditDialog("InputFolder")}>
                    <PageCollectionSuggester
                        TextFieldProps={{
                            label: <Translate
                                i18nKey='pryaniky.wikilist.item.param.collections'
                            />,
                            InputProps: {
                                endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.collections.help')} disableInteractive>
                                    <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                </Tooltip>
                            }
                        }}
                        onChange={(event, value) => {
                            if (value) {
                                setActualData1({ ...actualData1, collections: [value] as any })
                            } else {
                                setActualData1({ ...actualData1, collections: [] })
                            }
                        }}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1?.collections?.length > 0 ? actualData1?.collections?.map((el: any) => ({
                            displayName: el?.name,
                            ...el
                        })) : null}
                    />
                </Box>
                <Box className={cnWikiEditDialog("Content-Item")}>
                    <TagsSuggester
                        TextFieldProps={{
                            label: <Translate
                                i18nKey='pryaniky.wikilist.item.param.tags'
                            />,
                        }}
                        multiple
                        onChange={(_, value) => {
                            setActualData1({ ...actualData1, tags: value as any })
                        }}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        value={actualData1.tags}
                    />
                </Box>
                <Divider variant="inset" sx={{ margin: '14px 0' }} />
                {
                    context.collectionAlias !== "dash"
                    && isAdmin
                    && <StyledFormControlLabel control={
                            <Checkbox
                                className={cnWikiEditDialog("CheckboxInput")}
                                checked={isInheritingRootRights}
                                onChange={onChangeIsInheritingRootRights} 
                            />
                        }
                        label={<Translate
                            i18nKey='pryaniky.wikilist.item.param.isInheritingRootRights'
                        />}
                    />
                }
                <Box className={cnWikiEditDialog("Content-Item")}>
                    <UsersSuggester
                        disabled={!isAdmin ? true : (isAdmin && isInheritingRootRights) ? true : false}
                        TextFieldProps={{
                            label: <Translate
                                i18nKey='pryaniky.wikilist.item.param.moderators'
                            />,
                        }}
                        value={moderators}
                        multiple
                        onChange={(_, value) => {
                            setModerators([...value as any])
                        }}
                        requestAdditionalParams={{
                            excludeMe: false,
                            groupid: pdata.gId || actualData1.context?.groupId
                        }}
                    />

                </Box>
                <Box className={cnWikiEditDialog("InputFolder")}>
                    <UsersSuggester
                        TextFieldProps={{
                            label: viewersTitle,
                            InputProps: {
                                endAdornment: <Tooltip title={i18n.t('pryaniky.wikilist.item.param.viewers.help')} >
                                    <HelpIcon sx={{ marginLeft: "10px" }} color="action" />
                                </Tooltip>
                            },
                        }}
                        value={viewers || []}
                        className={cnWikiEditDialog("InputFolder-Folder")}
                        multiple
                        disabled={!isAdmin ? true : (isAdmin && isInheritingRootRights) ? true : false}
                        requestAdditionalParams={{
                            excludeMe: false,
                            groupid: pdata.gId || actualData1.context?.groupId
                        }}
                        onChange={(_, viewers) => {
                            setViewers([...viewers as any]);
                        }}
                        isAdmin
                    />
                </Box>
                <Divider variant="inset" sx={{ margin: '14px 0' }} />
                {typeWikilist && <Box className={cnWikiEditDialog("ViewType")}>
                    <h4><Translate i18nKey={'pryaniky.wikilist.item.param.defaultViewType'} /></h4>
                    <ButtonGroup>
                        <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.grid')} >
                            <Button variant={actualData1.defaultViewType === 0 ? "contained" : "outlined"} onClick={() => {
                                setActualData1({ ...actualData1, defaultViewType: 0 })
                            }}>
                                <GridView />
                            </Button>
                        </Tooltip>
                        <Tooltip title={i18n.t('pryaniky.wikilist.item.param.defaultViewType.list')} >
                            <Button variant={actualData1.defaultViewType === 1 ? "contained" : "outlined"} onClick={() => {
                                setActualData1({ ...actualData1, defaultViewType: 1 })
                            }}>
                                <ViewList />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Box>}
                <Box className={cnWikiEditDialog("PositionInCollection")}
                    sx={{
                        marginTop: "8px",
                    }}>
                        <NumberTextField
                            fullWidth
                            hideApperance
                            className={cnWikiEditDialog("PositionInCollection")}
                            label={<Translate i18nKey='pryaniky.wikilist.item.param.positionInCollection' />}
                            value={actualData1.positionInCollection}
                            onChange={(e) => {
                                setActualData1({ ...actualData1, positionInCollection: e.currentTarget.value })
                            }}
                        />
                </Box>
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            className={cnWikiEditDialog("CheckboxInput")}
                            checked={actualData1.showInMainMenu}
                            onChange={onChangeShowInMainMenu}
                        />
                    }
                    label={<Translate i18nKey={'pryaniky.wikilist.item.param.showInMainMenu'}/>}
                />
                {(typePage || typeFile) && <StyledFormControlLabel
                    control={
                        <Checkbox
                            className={cnWikiEditDialog("CheckboxInput")}
                            checked={actualData1.allowComments}
                            onChange={onChangeAllowComments}
                        />
                    }
                    label={<Translate
                        i18nKey={'pryaniky.wikilist.item.param.allowComments'}
                        values={{
                            type: Translate.t({
                                i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                count: 2
                            }).toLowerCase()
                        }}
                    />}
                    />
                }
                {typeWikilist &&
                    <StyledFormControlLabel
                        control={
                            <Checkbox
                                className={cnWikiEditDialog("CheckboxInput")}
                                checked={actualData1.showCategoryAsSelector}
                                onChange={onChangeShowCategoryAsSelector}
                            />
                        }
                        label={<Translate i18nKey='pryaniky.wikilist.item.param.showCategoryAsSelector' />}
                    />
                }
                <StyledFormControlLabel
                    control={
                        <Checkbox
                            className={cnWikiEditDialog("CheckboxInput")}
                            checked={actualData1.isHideFooter}
                            onChange={onChangeHideFooter}
                        />
                    }
                    label={<Translate i18nKey='pryaniky.wikilist.item.param.isHideFooter' />}
                />
                <Divider variant="inset" sx={{ margin: '14px 0' }} />
                <PreviewSelector
                    {...props}
                    className={cnWikiEditDialog({}, [props?.className])}
                    onClick={(e: any) => {
                        ImageCropperDialog({
                            onLoaded: (file: any) => {
                                setActualData1({ ...actualData1, titleImageId: file.id, titleImageUrl: file.originalUrl })
                                setImgChanged(true)
                            }
                        })

                    }}
                    previewUrl={actualData1.titleImageUrl}
                    newImg={imgChanged}
                    label={
                        <Translate
                            i18nKey='pryaniky.wikilist.item.param.titleImage'
                            values={{
                                type: Translate.t({
                                    i18nKey: `pryaniky.wikilist.item.${actualData1.type}`,
                                    count: 2
                                }).toLowerCase()
                            }}
                        />
                    }
                    helperText={<Translate i18nKey="pryaniky.wikilist.item.param.titleImage.help" />}
                />

            </DialogContent> :
                <DialogContent>
                    <div className={cnWikiEditDialog("Loading")}>
                        <Translate i18nKey='pryaniky.data.actual.loading' />
                        <LinearProgress />
                    </div>
                </DialogContent>
            }

            <DialogActions
                acceptText={i18n.t("pryaniky.saveChanges")}
                closeText={i18n.t("cancel")}
                onAccept={() => sendDataForEditPage()}
                disableAccept={actualData1 && (actualData1.url === '' || actualData1.url === undefined) || isLoading || error}
                isLoading={loading || isLoading}
                onClose={handleCancel} />
        </>
    );

};

export const WikiEditDialog = connect<IWikiEditStateProps, IWikiEditDispatchProps, IWikiEditOwnProps, IState>(
    mapWikiEditStateToProps,
    mapWikiEditDispatchToProps
)(WikiEditDialogPresenter)

export const DialogWikiEdit = createModal(WikiEditDialog, {
    maxWidth: 'sm',
    fullWidth: true,
    PaperProps: {
        style: {
            backgroundColor: '#fff',
            // overflowY: 'unset'
        }
    },
    TransitionComponent,
    scroll: 'body'
});