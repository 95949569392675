import React, { FC, useEffect, memo, useState, useMemo } from "react";
import { useRefedCallback } from "utils/src/hooks";
import { ISkill } from "redux/sagas/Skills/types";

import i18n from "localizations/i18n";
import { TDialogProps } from "utils/src/DialogCreator";
import { IBaseUser } from "uielements/src/UsersList/UsersList.index";
import { getEventPartipants } from "utils/src/requests/requests.news";
import { IBasicResponse } from "utils/src/requests/models/api.base";
import { dialog } from "muicomponents/src/ItemsListDialog/ItemsListDialog";
import { UsersListItem } from "muicomponents/src/ItemsListDialog/UserListItem/UserListItem";
import { list } from "utils/src/requests/requests.users";
// requestOptions={{ tagid: tId, showcontext: 'endorsements', userid, count: 20 }}

const createParticipantsDialog = () => {
  return (
    {
      tId,
      userid,
    }: {
      tId: string;
      userid: string;
    },
    containerProps?: TDialogProps
  ) =>
    dialog(
      {
        method: async function (skipCount, { count, search }) {
          const response = await list({
            tagid: tId,
            showcontext: "endorsements",
            userid,
            count: 20,
          });
          const result = (await response.r) as {
            data: IBaseUser[];
          } & IBasicResponse;
          if (result.error_code !== 0) throw result;
          return result.data.map((item) => ({
            ...item,
            url: `/user/${item.id}`,
          }));
        },

        ItemComponent: UsersListItem,
        listBodyProps: {
          header: i18n.t(`pryaniky.modal.skills.title`),
        },
      },
      containerProps
    );
};

const participantsDialog = createParticipantsDialog()

type SkillsItemMethods = {
  confirmSkill: (
    userId: string,
    skillId: string,
    confirmedByMe: boolean
  ) => void;
  deleteSkill: (userId: string, skillId: string) => void;
};

export const useSkillsItem = (
  skill: ISkill,
  userid: string,
  { confirmSkill, deleteSkill }: SkillsItemMethods
) => {
  const [del, setDel] = useState(false);

  useEffect(() => {
    setDel(skill.confirmCount === 0);
  }, [skill]);

  const skillId = skill.tag.id,
    confirmedByMe = skill.confirmedByMe,
    confirmCount = skill.confirmCount;

  // пока не понял, почему замыкание сохраняется.
  // const onChangeConfirm = useRefedCallback(({ current }) => () => confirmSkill(current.userid, current.skillId, !current.confirmedByMe),
  //     { userid, skillId, confirmedByMe });
  const onChangeConfirm = () => confirmSkill(userid, skillId, !confirmedByMe);

  const onCancleDelete = useRefedCallback(
    ({ current }) =>
      () =>
        confirmSkill(
          current.userid,
          current.skillId,
          current.confirmCount === 0 ? true : current.confirmedByMe
        ),
    { userid, skillId, confirmedByMe, confirmCount }
  );

  const onDelete = useRefedCallback(
    ({ current }) =>
      () =>
        deleteSkill(current.userid, current.skillId),
    { userid, skillId }
  );

  const actionCallback = useRefedCallback(
    ({ current }) =>
      () =>
        participantsDialog({ tId: current.skillId, userid }), //setShow(current.skillId),
    { skillId }
  );

  return {
    del,
    onChangeConfirm,
    onCancleDelete,
    onDelete,
    actionCallback,
    setDel,
  };
};
