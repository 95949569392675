import React from 'react';
import { cnWidgetMui } from '../../Widget.index';
import * as utils from 'utils/src/utils';
import WidgetPresenter from '../../Widget';
import { widgets } from 'i.widgets';
import { IWidgetTypeEventslistProps } from './Widget_type_eventslist.index';
import './Widget_type_eventslist.scss';
import { EventsListWidget } from 'blocks/EventsListWidget/EventsListWidget';
const type = 'eventslist';

export default class WidgetTypeEventslistPresenter extends WidgetPresenter<IWidgetTypeEventslistProps> {
    public render() {
        if (!this.props.widget) return null;
        const subtype = utils.widgetSubtype(this.props.widget.type);
        return (
            <widgets.components.common {...this.props} className={cnWidgetMui({ type, subtype })}>
                <EventsListWidget
                    contexts={this.relations.concat(this.props.widget.id)}
                    isEditable={this.props.widget.isEditable}
                    isEdit={this.props.widget.HTMLWidgetContentEdit}
                    settings={this.props.widget.settings || this.props.widget.data}
                />
            </widgets.components.common>
        );
    }
}
