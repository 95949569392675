/**
 * @packageDocumentation
 * @module Widget_Settings_timeline
 */
import { Actions } from '@jsonforms/core';
import i18n from 'localizations/i18n';
import { store } from 'redux/store';
import { JFdictionary } from '../dictionary';
import defaultData from './data';
import uischema from './uischema';

const { types } = JFdictionary;

export let additionalSchemaData: any = {
    type: types.string,
    placeholder: 'select type',
    opts: [],
    data: [],
};

const timelineTypes = {
    my: 'my', // лента пользователя
    reward: 'reward', // награды пользователя
    feedback: 'feedback', // отзывы пользователя
    notices: 'notices', // лента объявлений
    ideas: 'ideas', // лента идеи
    creativetasks: 'creativetasks', // лента конкурсов
    events: 'events', // лента событий
    polls: 'Опросы', // лента опросов
};

// id(pin): "creativetasks"
// idNumber(pin): 0
// displayName(pin): "Конкурсы"
// enabled(pin): true
// allowed(pin): false
// ideaRenderParams(pin): null
// badgeRenderParams(pin): null
// eventRenderParams(pin): null
// achievementsRenderParams(pin): null

const relationsArray = ['mainTimeline', 'groupsTimeline', 'usersTimeline', 'tagsTimeline'];

const schema = {
    type: types.object,
    properties: {
        name: {
            type: types.string,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.name'),
        },
        selectedTags: {
            type: types.object,
            placeholder: i18n.t('ryaniky.widgets.settings.placeholder.chooseTag'),
        },
        selectedGroups: {
            type: types.object,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.selectGroup'),
            props: {
                additionalRequestOpts: {
                    excludeSystemGroups: 'true',
                },
            },
        },
        selectedNews: additionalSchemaData,
        range: {
            type: types.number,
            min: 1,
            placeholder: i18n.t('pryaniky.widgets.settings.placeholder.range'),
        },
        hideIfNoData: {
            type: types.boolean,
        },
    },
};

/**
 * аналогично hierarchy... но без саги
 * тут макароны из типов публикаций...
 */
export const prepareData = (data: any) => {
    const state = store.getState();
    // additionalSchemaData.data = state.appSettings.companyPostTypes
    const enabledTypes: string[] = state.appSettings.companyPostTypes
        .filter((e: any) => e.enabled)
        .map((e: any) => e.id);
    const usedTypes: string[] = additionalSchemaData.opts.map((e: any) => e.value);
    // получаем массив типов публикаций
    if (
        (enabledTypes.includes('thanks') || enabledTypes.includes('badges') || enabledTypes.includes('achievements')) &&
        !usedTypes.includes('reward')
    ) {
        additionalSchemaData.opts.push(prepareOption('Награды', 'reward'));
    }
    if (enabledTypes.includes('feedback') && !usedTypes.includes('feedback')) {
        additionalSchemaData.opts.push(prepareOption('Отзывы', 'feedback'));
    }
    if (enabledTypes.includes('notices') && !usedTypes.includes('notices')) {
        additionalSchemaData.opts.push(prepareOption('Объявления', 'notices'));
    }
    if (enabledTypes.includes('creativetasks') && !usedTypes.includes('creativetasks')) {
        additionalSchemaData.opts.push(prepareOption('Конкурсы', 'creativetasks'));
    }
    if (enabledTypes.includes('events') && !usedTypes.includes('events')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'events')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    if (enabledTypes.includes('ideas') && !usedTypes.includes('ideas')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'ideas')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    if (enabledTypes.includes('polls') && !usedTypes.includes('polls')) {
        const it = state.appSettings.companyPostTypes.filter((e: any) => e.id === 'polls')[0];
        additionalSchemaData.opts.push(prepareOption(it.displayName, it.id));
    }
    // additionalSchemaData.opts = additionalSchemaData.data.map((el: any) => prepareOption(el.displayName, el.id))
    schema.properties.selectedNews = additionalSchemaData;
    if (data) {
        store.dispatch(
            Actions.init(
                Object.keys(defaultData).reduce((acc, cur) => ({ ...acc, [cur]: data && data[cur] }), {} as any),
                schema,
                uischema
            )
        );
    } else {
        store.dispatch(Actions.init(defaultData, schema, uischema));
    }
};
// newsTypesSubscr();

export const prepareOption = (title: string, value: string) => ({
    title,
    value,
});

export default schema;
