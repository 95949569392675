import { IClassNameProps } from '@bem-react/core'
import { cn } from '@bem-react/classname'
import actions from 'redux/actionsTypes/Widgets';
import { bindActionCreators } from 'redux';
import { getCalendarCalFilters, getCalendarWorkflowFilters, clearCalendarWorkflowFilters } from 'redux/calendar/actions';

import { Dispatch } from 'redux'
import { selectworkflowFilters } from 'redux/calendar/selectors';
import { IStateType } from 'redux/store';

export interface IFiltersProps extends IClassNameProps, IFilterStateToProps, IFiltersDispatchProps {
  tag?: 'div'
  data: any[]
  context: IFilterContext
  relations: string[]
}

export type IFiltersDispatchProps = ReturnType<typeof mapDispatchToProps>

export type IFilterStateToProps = ReturnType<typeof mapStateToProps>

export interface IFilterContext {
  filter?: Record<string, any>
  cIds?: string[]
  statusIds?: string[]
  tagIds?: string[]
  gId?: number
  [s: string]: any
}

export const mapStateToProps = (state: IStateType) => ({
  edit: state.store.edit,
  calendars: state.calendar.calendars,
  workflow: selectworkflowFilters(state)
})

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeContextv3: (payload: Array<{ [s: string]: any }>) =>
    ({ type: actions.UPDATE_CONTEXTS, payload }),
  getCalendarCalFilters,
  getCalendarWorkflowFilters,
  clearCalendarWorkflowFilters
}, dispatch)

export const cnFilter = cn('Filter')
 