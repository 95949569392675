import { Box, Button, styled } from 'muicomponents/src';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
export const MainBox = styled(Box)({
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    alignSelf: 'stretch',
    gap: '24px',
}) as typeof Box;

export const HeaderBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
}) as typeof Box;

export const DatesBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '8px',
}) as typeof Box;

export const FooterBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '16px',
}) as typeof Box;

export const PageBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'rgba(97, 97, 97, 1)',
    gap: '6px',
}) as typeof Box;

export const PageButton = styled(Button)({
    minWidth: 'auto',
    padding: '6px',
}) as typeof Button;

export const RangeDatesBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
}) as typeof Box;
export const DateLabel = styled('label')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
});

export const StyleTable = styled(Table)({
    border: '1px solid rgba(238, 238, 238, 1)',
    borderRadius: '8px',
}) as typeof Table;

export const StyleTableHead = styled(TableHead)({
    backgroundColor: 'rgba(250, 250, 250, 1)',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '8px 8px 0 0',
}) as typeof TableHead;

export const StyleTableBody = styled(TableBody)({
    borderRadius: '0 0 8px 8px',
}) as typeof TableBody;

export const StyledHeadTableCell = styled(TableCell)({
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
}) as typeof TableCell;

export const StyledTableCell = styled(TableCell)({
    overflow: 'hidden',
    overflowWrap: 'break-word',
}) as typeof TableCell;

export const StyledTableRow = styled(TableRow)({
    display: 'grid',
    gridTemplateColumns: 'minmax(120px, 250px) minmax(120px, 1fr)  minmax(120px, 1fr)',
}) as typeof TableRow;
