import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators } from 'redux';
import { loadData, setAllNotificationsViewed } from 'redux/actions/Notification';
import { IStateType as IState } from 'redux/store';
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';

export interface INotificationsItem {
    id: number;
    newsDate: string;
    newsUid: string;
    msg: string;
    notViewed: boolean;
    eventType: number;
    objId: string;
    objType: 0;
}

export interface INotificationsProps
    extends IClassNameProps,
        INotificationsStateProps,
        INotificationsOwnProps,
        INotificationsActionsToProps {}

export interface INotificationsOwnProps {}

export interface INotificationsState {
    text?: string;
}

export type INotificationsActionsToProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            loadData,
            setAllNotificationsViewed,
        },
        dispatch
    );

export type INotificationsStateProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: IState) => ({
    dataArray: state.notifications.data,
    notificationCount: state.store.notificationCount,
    isLoading: state.notifications.isLoading,
    currentUserId: getAuthUser(state).baseData.id as string,
});

export const cnNotifications = cn('Notifications');
