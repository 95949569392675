import React, { FC, useState, forwardRef, useEffect, useRef } from "react";
import { IDropUploader } from "./DropUploader.index";
import { useFileUploader, useDropFile, ItemType } from "utils/src/hooks";
import {
  FileDropZone,
  DefaultChildren,
} from "muicomponents/src/FileDropZone/FileDropZone";
import { FilesList } from "muicomponents/src/FilesList/FilesList";
import { useLazyQueryEx } from "utils/src/hooks";
import {
  getByIdSkipLayout,
  deletePage,
} from "utils/src/requests/requests.pages";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createModal, IBodyProps } from "utils/src/DialogCreator";
import { DialogBody } from "muicomponents/src/DialogParts/DialogBody/DialogBody";
import { styled } from "@mui/material/styles";
import { UploadButton } from "muicomponents/src/UploadButton/UploadButton";
import { AlertMessage } from "muicomponents/src/AlertMessage/AlertMessage";
import Divider from "@mui/material/Divider";
import i18n from "../../../localizations/i18n";
import { toast } from "react-toastify";
import { Translate } from "localizations/Translate";
import { getAppSettings } from "utils/src/CommonRedux/base/selectors";
import { useSelector } from "react-redux";
import { useDidUpdateEffect } from "utils/src/hooks";
import { IResponseWithData } from "utils/src/requests/models/api.base";
import { NewsAttachV3WithPage } from "utils/src";

async function getPage(id: string, collectionAlias?: string) {
  const response = await getByIdSkipLayout(id, {
    collection: collectionAlias ? collectionAlias : "dash",
  });
  return await response.r;
}

export const openDndDialog = createModal(
  ({
    collectionAlias,
    currentPageId,
    allowTypes,
    handleAccept,
    handleClose,
    openFileSelectOnMount,
  }: IBodyProps<{ items: any[] }> & {
    collectionAlias: string;
    currentPageId?: string;
    openFileSelectOnMount?: boolean;
    allowTypes?: Parameters<typeof useFileUploader>["0"]["allowTypes"];
  }) => {
    const { maxFileSizeLimit } =
      useSelector<any, { maxFileSizeLimit: number }>(getAppSettings);
    const {
      isLoading: loadingPage,
      send: loadPage,
      result: pageData,
    } = useLazyQueryEx(getPage);

    const resultRef = useRef<any[]>([]);

    const {
      isLoading,
      startUpload,
      files,
      dragEntered,
      dndTriggerRef,
      removeFile,
      uploadError,
      appEndFiles,
      inputAccept,
      onFileChange,
      uploadDisabled,
      notUploadedCount,
      openSelectFile,
      setUploadError,
    } = useFileUploader({
      maxFilesCount: Infinity,
      allowTypes,
      getParams: { collectionAlias: collectionAlias || "dash" },
      callbacks: {
        loadFinish: async function (item, resp) {
          const response = resp as any as IResponseWithData<
            NewsAttachV3WithPage[]
          >;
          resultRef.current.push({
            ...response.data[0].page,
            file: {
              ...response.data[0].page.file!,
              action: response.data[0].action,
              originalUrl: response.data[0].originalUrl,
              previewUrl: response.data[0].previewUrl,
            },
            id: response.data[0].page.file!.id,
            description: "",
            collectionAlias,
            viewers: pageData?.viewers,
          });
        },
      },
    });
    useDidUpdateEffect(() => {
      switch (uploadError?.error?.error_code) {
        case 4050:
          toast.error(
            <Translate
              i18nKey={"pryaniky.file.size.error"}
              values={{
                fileName: uploadError.file?.name,
                maxSize: maxFileSizeLimit,
              }}
            />
          );
          break;
        default:
          if (uploadError?.error?.error_text)
            toast.error(uploadError?.error?.error_text);
          break;
      }
    }, [uploadError]);
    const { dragEntered: dragEntered2, dndTriggerRef: dndTriggerRef2 } =
      useDropFile(appEndFiles, {});
    const {
      dndRef: dndRef3,
      dragEntered: dragEntered3,
      dndTriggerRef: dndTriggerRef3,
    } = useDropFile(appEndFiles, {});

    useEffect(() => {
      if (openFileSelectOnMount) {
        openSelectFile();
      }

      if (currentPageId) {
        loadPage(currentPageId, collectionAlias);
      }
    }, []);

    async function sendSingle(item: typeof files[number], progress = 0) {
      const uploadResult = await startUpload([item]);
      if (uploadResult) {
      } else {
        return false;
      }
    }
    async function onSend() {
      const uploadResult = await startUpload(files);
    }

    async function onDelete(fileItem: ItemType) {
      removeFile(fileItem);
      if (fileItem.isUploaded && fileItem.response!.data[0]?.PageV2Id) {
        deletePage(fileItem.response!.data[0].PageV2Id);
        resultRef.current = resultRef.current.filter(
          (v: any) => v.file.id !== fileItem.response!.data[0].id
        );
      }
    }

    const onCancle = () => {
      files.forEach((item) => {
        removeFile(item);
      });
      handleClose();
    };

    const completedSuccess =
      notUploadedCount === 0 && files.length > 0 && !uploadError;

    const ha = ({ items }: any) => {
    //   console.log("items", items);
      handleAccept({ items });
    };
    return (
      <>
        <FileDropZone
          ref={dndTriggerRef}
          fullScreen
          disableTransition
          show={true}
          sx={{ color: "inherit", backgroundColor: "transparent", zIndex: -1 }}
        >
          <></>
        </FileDropZone>

        <DialogBody
          hiddenClose
          onClose={() => {
            completedSuccess ? ha({ items: resultRef.current }) : onCancle();
          }}
          header={i18n.t("pryaniky.list.wiki.dnduploader.header")}
          acceptText={
            completedSuccess
              ? i18n.t("pryaniky.list.wiki.dnduploader.closeText2")
              : i18n.t("pryaniky.list.wiki.dnduploader.acceptText")
          }
          isLoading={isLoading || loadingPage}
          onAccept={
            !!files.length
              ? () => {
                  completedSuccess
                    ? ha({ items: resultRef.current })
                    : onSend();
                }
              : undefined
          }
          closeText={
            completedSuccess
              ? i18n.t("pryaniky.list.wiki.dnduploader.closeText2")
              : i18n.t("pryaniky.list.wiki.dnduploader.closeText")
          }
        >
          <Box>
            <AlertMessage
              sx={{ marginBottom: "8px" }}
              severity="info"
              variant="outlined"
            >
              {i18n.t("pryaniky.list.wiki.dnduploader.help.alert")}
            </AlertMessage>

            {uploadError && (
              <AlertMessage
                sx={{ marginBottom: "8px" }}
                severity="error"
                variant="outlined"
                onClose={() => setUploadError(null)}
              >
                {i18n.t("pryaniky.list.wiki.dnduploader.uploadError")}
              </AlertMessage>
            )}

            <FilesFrame ref={dndTriggerRef2}>
              {files.length === 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    pointerEvents: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography component="span" variant="body2">
                    {i18n.t("pryaniky.list.wiki.dnduploader.dropfile")}
                  </Typography>
                  <DefaultChildren />
                </Box>
              ) : (
                <FilesList
                  sx={{
                    flex: "1",
                    height: "100%",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  ref={dndTriggerRef3}
                  refreshFile={sendSingle}
                  removeFile={onDelete}
                  files={files}
                />
              )}

              <FileDropZone
                fullScreen
                ref={dndRef3}
                show={dragEntered || dragEntered3 || dragEntered2}
              />
            </FilesFrame>

            <UploadButton
              sx={{ marginTop: "8px", marginBottom: "14px" }}
              disabled={uploadDisabled}
              variant={"outlined"}
              onChangeInput={onFileChange}
              multiple={true}
              accept={inputAccept}
            >
              {i18n.t("pryaniky.list.wiki.dnduploader.UploadButton")}
            </UploadButton>
          </Box>
          <Divider />
        </DialogBody>
      </>
    );
  },
  {
    scroll: "paper",
    fullWidth: true,
    maxWidth: "sm",
  }
);

const FilesFrame = styled(Box)<{}>((props) => ({
  display: "flex",
  minHeight: "188px",
  border: "1px dashed rgba(0, 0, 0, 0.54)",
  borderRadius: "8px",
}));

export const DropUploader = forwardRef<HTMLElement, IDropUploader>(
  (
    { collectionAlias = "", currentPageId, allowTypes, onComplete, ...props },
    ref
  ) => {
    const { dndTriggerRef, dragEntered } = useDropFile(() => {}, {});
    //
    (dndTriggerRef as any).current = document.body; //хз почему, ругается тс, что реадонли она
    //

    const [open, setOpen] = useState(false);

    useEffect(() => {
      if (dragEntered && !open) {
        setOpen(true);
        openDndDialog({
          collectionAlias,
          currentPageId,
          allowTypes,
        })
          .then(({ items }) => {
            onComplete && onComplete(items);
          })
          .finally(() => {
            setOpen(false);
          });
      }
    }, [dragEntered, open]);
    return <></>;
  }
);
