import { ExtractByParam } from 'utils/src';
import { Vacancy } from 'utils/src/BaseTypes/vacancy';

export const VACANCIES_SET_FULL_ACCESS = 'VACANCIES_SET_FULL_ACCESS';
export const VACANCIES_SET_LIST = 'VACANCIES_SET_LIST';
export const VACANCIES_APPEND_TO_LIST = 'VACANCIES_APPEND_TO_LIST';
export const VACANCIES_PREPEND_TO_LIST = 'VACANCIES_PREPEND_TO_LIST';

export namespace VacanciesActions {
    type SetList = {
        type: typeof VACANCIES_SET_LIST;
        payload: Vacancy['id'][];
    };

    type SetFullAccess = {
        type: typeof VACANCIES_SET_FULL_ACCESS;
        payload: boolean;
    };

    type AppendToList = {
        type: typeof VACANCIES_APPEND_TO_LIST;
        payload: Vacancy['id'][];
    };

    type PrependToList = {
        type: typeof VACANCIES_PREPEND_TO_LIST;
        payload: Vacancy['id'][];
    };

    export type Actions = SetList | SetFullAccess | AppendToList | PrependToList;

    export type Action<Type extends Actions['type']> = ExtractByParam<Actions, 'type', Type>;

    export type ActionFunction<Type extends Actions['type']> = (payload: Action<Type>['payload']) => Action<Type>;
}
