import React, { FC, useContext, useEffect, useState } from "react";
import { cnNewsTypeWorkflows } from "./Workflows.index";
import { connect } from "react-redux";
import { ComponentInjector } from "utils/src/ComponentInjector";
import "./Workflows.scss";
import { FieldsEditor } from "muicomponents/src/FieldRender";
import {
  changeField,
  getFieldsByProperty,
} from "muicomponents/src/FieldRender/utils";
import { useDidUpdateEffect, useLazyQueryEx } from "utils/src/hooks";
import { useWorkflowsEdit } from "./Workflows.hooks";
import { NewsCreateContext } from "News/creator/Creator/Creator.constants";
import {
  getCalendarEventsRequest,
  getCalendarCalFiltersRequest,
  setCalendarCalFilterColorRequest,
  getCalendarWorkflowFiltersRequest,
  getCalendarEventsRequestV4,
} from "utils/src/requests/requests.calendar";

const prepareI18nKey = (key: string) => `pryaniky.news.create.ideas.${key}`;

const NewsTypeWorkflowsPresenter: FC<{}> = ({}) => {
  const { creatorId } = useContext(NewsCreateContext);

  const { data, errors, clearEditor, postType, changeCreator, changeNews } =
    useWorkflowsEdit(creatorId);

  const { additionalFields } = data;

  const [fieldsErrors, setFieldsErrors] = useState<any>();

  const { result, send } = useLazyQueryEx(getCalendarEventsRequestV4);

  ///////
  let afv = additionalFields?.additionalFieldsValues;

  useEffect(() => {
    if (postType?.renderParams?.calendar?.id) {
      send({
        start: "1970-01-01T00:00:00.000Z",
        end: "2090-01-01T00:00:00.000Z",
        cIds: [postType.renderParams?.calendar?.id],
      });
    }
  }, [postType?.renderParams?.calendar?.id]);
  if (postType?.renderParams?.calendar?.id && afv) {
    const fields = getFieldsByProperty(afv, {
      property: "type",
      value: "DateTime",
    });
    fields.forEach((v) => {
      afv = changeField(afv!, {
        ...v,
        settings: {
          calendar: postType?.renderParams?.calendar,
          shouldDisableTime: result?.events.map((v) => ({
            start: new Date(v.start),
            end: new Date(v.end),
          })),
        },
      });
    });
  }
  ///////////

  useDidUpdateEffect(() => {
    changeCreator({
      errors: {
        ...errors,
        ...fieldsErrors,
      },
    });
  }, [fieldsErrors]);

  if (!additionalFields) {
    console.error(`workflow "additionalFields" is undefined`);
    return null;
  }

  return (
    <>
      <FieldsEditor
        fields={afv || []}
        onChange={(fields, newErrors) => {
          changeNews({
            additionalFields: {
              ...additionalFields,
              additionalFieldsValues: fields,
            },
          });
          setFieldsErrors(newErrors);
        }}
        globalTagsCollection={"workflows"}
        errors={errors}
      />
    </>
  );
};

export const NewsTypeWorkflows = NewsTypeWorkflowsPresenter;
// export const NewsTypeWorkflows = connect(
//     mapNewsTypeWorkflowsStateToProps,
//     mapNewsTypeWorkflowsActionsToProps
// )(NewsTypeWorkflowsPresenter);

export const initNewsWorkflowsCreator = () =>
  ComponentInjector.getInstance().addNode(
    "news_creator_mui5",
    <NewsTypeWorkflows />,
    "workflow"
  );
