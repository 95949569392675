import React, {
    ComponentProps,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
  } from "react";
  import {
    IMakeOrderDialogProps,
    mapStateToProps,
    mapDispatchToProps,
  } from "./ShopMakeOrder.index";
  import { connect } from "react-redux";
  import i18n from "localizations/i18n";
  import { cnDialog, Loading } from "uielements/src";
  import Characteristics from "uielements/src/Characteristics";
  import { pickSelected } from "blocks/Shop/utils";
  import { createModal, TransitionComponent } from "utils/src/DialogCreator";
  import { FieldsEditor, isEmptyMandatory } from "muicomponents/src/FieldRender";
  import { styled } from "muicomponents/src/mui/system";
  import { getProductRequest } from "utils/src/requests/requests.shop";
  import { useLazyQuery, dataExtractor, useReffedState } from "utils/src/hooks";
  import { DialogBody } from "muicomponents/src/DialogParts/DialogBody/DialogBody";
  import { Box, TextField, UsersSuggester } from "muicomponents/src";
  import { Typography } from "muicomponents/src";
  import { CountSelector } from "blocks/ShopMui/CountSelector";
  import * as utilsProj from "utils.project/utils.project";
  import { countMissingThanks } from "blocks/ShopMui/utils";
  import { checkResponseStatus, joinReactNodes } from "utils/src/utils";
  import { byIdPromise } from "utils/src/requests/requests.users";
  import {
    convertUserShortToSelectableObject,
    convertUserToShortUser,
  } from "utils/src";
  import { Translate } from "localization";
  import { IShopMakeShopOrderPayload } from "redux/shop/actions.interfaces";
  import * as utils from "utils/src/utils";
  import { IBasicResponse } from "utils/src/requests/models/api.base";
  import { toast } from "react-toastify";
  import { currentUserMinusMyThanksCount } from "utils/src/CommonRedux/users/actions";
  import { openMaybeIndependentPurchaseDialog } from "./Components/MaybeIndependentPurchaseDialog";
  import { Dispatch } from "redux";
  import { NumberTextField } from "muicomponents/src/TextField/TextField";
  import { isFinite } from "lodash";
  const StyledFieldsEditor = styled(FieldsEditor)({
    padding: 16,
  });
  
  const FlexBox = styled(Box)({
    display: "flex",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  }) as typeof Box;
  
  const additionalFieldsMapper = (additionalFields?: any) => {
    return (additionalFields || []).map((category: any) => {
      return {
        ...category,
        fields: category.fields.filter((el: any) => !el.defaultFieldName),
      };
    });
  };
  
  const ShopMakeOrderPresenter = ({
    data = {},
    preselectedUsersId,
    additionalInformationBeforeOrder,
    makeShopOrder,
    currencyFormats,
    userThanksCount,
    useRedux,
    withCount,
    selectedCount,
    maxCount,
    handleAccept,
    handleClose,
    multiOrderItem,
    purchaseOrganizer,
    typeForm = "makeOrder",
    minInvestedValue,
    maxInvestedValue,
    isWithRequest,
  }: IMakeOrderDialogProps) => {
    const [state, setState, stateRef] = useReffedState({
      ...data,
      inviteToGroupOrder: data.inviteToGroupOrder
        ?.filter((user: { id: string; [k: string]: any }) => {
          return !purchaseOrganizer || user.id !== purchaseOrganizer?.id;
        })
        .sort(
          (
            a: { id: string; [k: string]: any },
            b: { id: string; [k: string]: any }
          ) => {
            if (a.invested > b.invested) {
              return -1;
            }
            if (a.invested < b.invested) {
              return 1;
            }
            return 0;
          }
        ),
    });
  
    const [errors, setErrors] = useState<any>();
    const [count, setCount] = useState<number | undefined>(selectedCount || 1);
    const [disableAccept, setDisableAccept] = useState<boolean>(true);
  
    const [loading, setLoading] = useState<boolean>(false);
  
    const [fullCost, setFullCost] = useState<number>(
      (data.thanksPrice || Infinity) * (count || 1)
    );
  
    const currentMinInvestedValue = minInvestedValue || 1;
    const currentMaxInvestedValue = maxInvestedValue || fullCost;
    useEffect(() => {
      if (typeForm === "makeOrder") {
        setFullCost((state.thanksPrice || Infinity) * (count || 1));
      } else {
        setFullCost(state.thanksPrice || Infinity);
      }
    }, [count]);
  
    const fixInvestors = useMemo(() => {
      return state.inviteToGroupOrder
        ?.filter((investor: { invested: number; [k: string]: any }) => {
          return (
            investor.invested &&
            investor.invested > 0 &&
            (!purchaseOrganizer || investor.id !== purchaseOrganizer?.id)
          );
        })
        .map((investor: { id: string; [k: string]: any }) => {
          return investor.id;
        });
    }, [state.inviteToGroupOrder]) as typeof state.inviteToGroupOrder;
  
    const [
      loadAdditionalFields,
      isLoadingAdditionalFields,
      additionalFields,
      isError,
      _,
      setAdditionalFields,
    ] = useLazyQuery(getProductRequest, (value) => {
      const data = dataExtractor(value);
      return data.additionalFields;
    });
  
    useEffect(() => {
      if (!data.additionalFields?.additionalFields && data.id) {
        loadAdditionalFields(data.id);
      } else {
        setAdditionalFields(data.additionalFields);
      }
    }, []);
  
    const [presentUserLoading, setPresentUserLoading] = useState(false);
  
    useLayoutEffect(() => {
      async function getUser() {
        if (preselectedUsersId) {
          setPresentUserLoading(true);
          try {
            const response = await byIdPromise(preselectedUsersId, {
              fullData: false,
            });
            if (checkResponseStatus(response)) {
              setState({
                ...stateRef.current,
                presentFor: convertUserShortToSelectableObject(
                  convertUserToShortUser(response.data)
                ),
              });
            } else {
            }
          } catch (error) {
            console.error(error);
          } finally {
            setPresentUserLoading(false);
          }
        }
      }
      getUser();
    }, [preselectedUsersId]);
  
    const selectedParams =
      typeForm === "makeOrder"
        ? pickSelected(state.characteristics || [])
        : Array.isArray(data.characteristics)
        ? data.characteristics.map((characteristic) => {
            return {
              ...characteristic,
              tags: Array.isArray(characteristic.tags)
                ? characteristic.tags.map((tag) => {
                    return { ...tag, isSelected: true };
                  })
                : [],
            };
          })
        : [];
  
    const onConfirm = (isMultiOrder: boolean) => {
      const idArrPresentFor = state.presentFor
        ? [{ id: state.presentFor.id }]
        : [];
      const idsArrInviteToGroupOrder = Array.isArray(state.inviteToGroupOrder)
        ? state.inviteToGroupOrder.map((person) => {
            return {
              id: person.id,
            };
          })
        : state.inviteToGroupOrder
        ? [{ id: state.inviteToGroupOrder.id }]
        : [];
      if (purchaseOrganizer) {
        idsArrInviteToGroupOrder.push({ id: purchaseOrganizer.id });
      }
      const id = state.id || NaN;
      const opts: Omit<IShopMakeShopOrderPayload, "id" | "count"> = {
        comment: state.comment || "",
        presentFor: idArrPresentFor,
        characteristics: selectedParams,
        thanksPrice: state.thanksPrice || Infinity,
        additionalFields: additionalFields
          ? {
              displayName: additionalFields!.displayName,
              formId: additionalFields!.formId,
              additionalFieldsValues: additionalFields!.additionalFields,
              id: additionalFields!.id,
            }
          : null,
        investedValue: isMultiOrder ? state.investedValue : fullCost,
        inviteToGroupOrder: isMultiOrder ? idsArrInviteToGroupOrder : [],
      };
  
      if (data.groupOrderId && typeForm === "changeOrder") {
        opts.groupOrderId = +data.groupOrderId;
      }
  
      const result: IShopMakeShopOrderPayload = {
        id,
        count,
        ...opts,
      };
  
      if (useRedux) makeShopOrder(result);
      if (isWithRequest) {
        setLoading(true);
        utils.API.shop
          .orderV4(id, count || 1, opts)
          .r.then((response: IBasicResponse) => {
            setLoading(false);
            if (utils.checkResponseStatus(response)) {
              handleAccept(result);
            } else {
              toast.error(response.error_text);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      } else {
        handleAccept(result);
      }
    };
  
    const showAdditionalInformation =
      !data.doNotShowAdditionalInformation &&
      additionalInformationBeforeOrder &&
      additionalInformationBeforeOrder.length > 0;
  
    const header =
      typeForm === "makeOrder"
        ? i18n.t("pryaniky.shop.dialogTitle") +
          (showAdditionalInformation ? " *" : "")
        : Translate.t({ i18nKey: "pryaniky.shop.dialogTitle.typeChange" });
  
    useEffect(() => {
      const isDisable =
        (userThanksCount < fullCost && !state.isMultiOrder) ||
        (state.investedValue &&
          state.investedValue < fullCost &&
          !state.isMultiOrder) ||
        (state.isMultiOrder &&
          ((state.investedValue &&
            state.investedValue < currentMinInvestedValue) ||
            !state.investedValue)) ||
        (state.investedValue && state.investedValue > currentMaxInvestedValue) ||
        (state.isMultiOrder &&
          state.investedValue &&
          state.investedValue > userThanksCount) ||
        isEmptyMandatory(additionalFields?.additionalFields || []);
      setDisableAccept(
        withCount ? !count || count === 0 || isDisable : isDisable
      );
    }, [fullCost, state.isMultiOrder, state.investedValue]);
  
    const noOrderAcceptText = useMemo(() => {
      let newNoOrderAcceptText: React.ReactNode = "";
      if (!state.isMultiOrder && userThanksCount < fullCost) {
        const missingThanks = countMissingThanks(fullCost, userThanksCount);
        newNoOrderAcceptText = (
          <>
            {i18n.t("missing")} {missingThanks}{" "}
            {utilsProj.getFormattedCurrencyName(
              currencyFormats,
              missingThanks,
              "genitive"
            )}
          </>
        );
      }
      if (
        state.isMultiOrder &&
        state.investedValue &&
        state.investedValue > userThanksCount
      ) {
        newNoOrderAcceptText = (
          <>
            {i18n.t("missing")} {state.investedValue - userThanksCount}{" "}
            {utilsProj.getFormattedCurrencyName(
              currencyFormats,
              state.investedValue - userThanksCount,
              "genitive"
            )}
          </>
        );
      }
  
      if (
        state.investedValue &&
        state.investedValue < fullCost &&
        !state.isMultiOrder
      ) {
        newNoOrderAcceptText = (
          <Translate i18nKey="pryaniky.shop.order.field.investedValue.validator.noGroup" />
        );
      }
  
      if (
        state.isMultiOrder &&
        ((state.investedValue && state.investedValue < currentMinInvestedValue) ||
          !state.investedValue)
      ) {
        newNoOrderAcceptText = (
          <Translate
            i18nKey="pryaniky.shop.order.field.investedValue.validator.min"
            values={{
              minValue: `${currentMinInvestedValue} ${utilsProj.getFormattedCurrencyName(
                currencyFormats,
                currentMinInvestedValue,
                "nominative"
              )}`,
            }}
          />
        );
      }
      if (state.investedValue && state.investedValue > currentMaxInvestedValue) {
        newNoOrderAcceptText = (
          <Translate
            i18nKey="pryaniky.shop.order.field.investedValue.validator.max"
            values={{
              maxValue: `${currentMaxInvestedValue} ${utilsProj.getFormattedCurrencyName(
                currencyFormats,
                currentMaxInvestedValue,
                "nominative"
              )}`,
            }}
          />
        );
      }
      return newNoOrderAcceptText;
    }, [
      state.investedValue,
      fullCost,
      state.isMultiOrder,
      currentMaxInvestedValue,
      currentMinInvestedValue,
      userThanksCount,
    ]);
  
    const onChangeInvestedValue: NonNullable<
      ComponentProps<typeof NumberTextField>["onChange"]
    > = (e) => {
      setState((prev) => {
        return { ...prev, investedValue: e.target.value };
      });
    };
  
    const onAccept = () => {
      if (
        state.isMultiOrder &&
        state.investedValue === fullCost &&
        typeForm === "makeOrder"
      ) {
        openMaybeIndependentPurchaseDialog({
          onAccept: () => {
            onConfirm(false);
          },
        });
      } else {
        onConfirm(multiOrderItem || false);
      }
    };
    const normalFullCost = isFinite(fullCost) ? fullCost : 0;
    return (
      <DialogBody
        isLoading={loading}
        header={header}
        disableAccept={disableAccept}
        acceptText={
          noOrderAcceptText
            ? noOrderAcceptText
            : typeForm === "makeOrder"
            ? i18n.t("pryaniky.shop.order")
            : Translate.t({ i18nKey: "pryaniky.shop.order.textButton.change" })
        }
        closeText={i18n.t("pryaniky.shop.cancel")}
        onAccept={onAccept}
        onClose={handleClose}
        contentProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          },
        }}
      >
        {withCount && (
          <FlexBox>
            <Translate i18nKey={"count"} />
            <CountSelector
              size={"small"}
              onChange={(e) => {
                setCount(e.target.value);
              }}
              value={count}
              inputProps={{
                max: maxCount,
                min: 1,
              }}
            />
          </FlexBox>
        )}
        {(withCount || typeForm === "makeOrder") && (
          <FlexBox>
            {maxCount !== undefined && withCount && maxCount !== 9999 ? (
              <div>
                <Translate i18nKey={"pryaniky.shop.avaliableCount"} />
                :&nbsp;
                <Typography
                  fontSize="14px"
                  component={"b"}
                  sx={{ fontWeight: "bold" }}
                >
                  {maxCount}
                  <Translate i18nKey={"pryaniky.shop.pc"} />
                </Typography>
              </div>
            ) : (
              <div></div>
            )}
            {typeForm === "makeOrder" ? (
              <div>
                <Translate i18nKey={"pryaniky.shop.total"} />
                :&nbsp;
                <Typography
                  fontSize="14px"
                  component={"b"}
                  sx={{ fontWeight: "bold" }}
                >
                  {`${normalFullCost} ${utilsProj.getFormattedCurrencyName(
                    currencyFormats,
                    normalFullCost,
                    "nominative"
                  )}`}
                </Typography>
              </div>
            ) : (
              <div></div>
            )}
          </FlexBox>
        )}
        {isLoadingAdditionalFields && <Loading />}
        {}
        {multiOrderItem && (
          <>
            <NumberTextField
              fullWidth
              hideApperance
              label={Translate.t({
                i18nKey: "pryaniky.shop.order.field.investedValue",
              })}
              value={state.investedValue}
              inputProps={{
                min: currentMinInvestedValue,
                max:
                  userThanksCount < currentMaxInvestedValue
                    ? userThanksCount
                    : currentMaxInvestedValue,
              }}
              onChange={onChangeInvestedValue}
            />
            <UsersSuggester
              multiple={true}
              requestAdditionalParams={{ excludeme: true }}
              value={state.inviteToGroupOrder || []}
              TextFieldProps={{
                label: i18n.t("pryaniky.shop.order.field.inviteToGroupOrder"),
              }}
              onChange={(event, value) => {
                if (
                  (Array.isArray(value) && value.length > 0) ||
                  (!Array.isArray(value) && value)
                ) {
                  setState({
                    ...state,
                    isMultiOrder: true,
                    inviteToGroupOrder: value,
                  });
                } else {
                  setState({
                    ...state,
                    isMultiOrder: false,
                    inviteToGroupOrder: value,
                  });
                }
              }}
              disablePortal={true}
              fixedOptions={fixInvestors}
            />
          </>
        )}
        {additionalFields ? (
          <StyledFieldsEditor
            fields={additionalFieldsMapper(additionalFields.additionalFields)}
            onError={(errors) => setErrors(errors)}
            errors={errors}
            onChange={(newAdditionalFields: any) =>
              setAdditionalFields({
                ...additionalFields,
                additionalFields: newAdditionalFields,
              })
            }
          />
        ) : (
          <TextField
            value={state.comment}
            placeholder={i18n.t("pryaniky.shop.comment")}
            label={i18n.t("pryaniky.shop.comment")}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setState({ ...state, comment: event.target.value })
            }
            multiline
            fullWidth
          />
        )}
  
        {data.characteristics && data.characteristics.length > 0 && (
          <Box>
            <Typography variant="h6" className={cnDialog("CharacteristicsTitle")}>
              {i18n.t("pryaniky.shop.dialog.characteristics")}
            </Typography>
            <Characteristics
              readonly
              characteristics={selectedParams}
              onChange={() => {}}
            />
          </Box>
        )}
        {!isLoadingAdditionalFields && data.isGift && (
          <>
            <Typography variant="subtitle1" color="textSecondary">
              {i18n.t("pryaniky.shop.for", { text: data.name })}
            </Typography>
            <UsersSuggester
              multiple={false}
              requestAdditionalParams={{ excludeme: true }}
              value={state.presentFor}
              TextFieldProps={{
                label: i18n.t("pryaniky.shop.selectFor"),
              }}
              onChange={(event, value) =>
                setState({ ...state, presentFor: value })
              }
              loading={presentUserLoading}
              disabled={presentUserLoading}
              disablePortal={true}
            />
          </>
        )}
  
        {showAdditionalInformation && (
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              {joinReactNodes(
                additionalInformationBeforeOrder.split("\n"),
                <br />
              )}
            </Typography>
          </Box>
        )}
      </DialogBody>
    );
  };
  
  export const ShopMakeOrder = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShopMakeOrderPresenter);
  
  export const openShopMakeOrder = createModal(ShopMakeOrder, {
    maxWidth: "sm",
    fullWidth: true,
    PaperProps: {
      style: {
        backgroundColor: "#fff",
      },
    },
    TransitionComponent,
    scroll: "body",
  });
  
  export const openShopMakeOrderWithMultiOrder = (
    dispatch: Dispatch<any>,
    ...arg: Parameters<typeof openShopMakeOrder>
  ) => {
    const [props] = arg;
    const { multiOrderItem } = props;
    return openShopMakeOrder({ ...props, isWithRequest: true }).then(
      (value: any) => {
        const { thanksPrice, investedValue } = value as IShopMakeShopOrderPayload;
        const { typeForm, data: shopData } = props;
        const prevInvestedValue = shopData?.investedValue;
        const currentTypeForm = typeForm || "makeOrder";
        if (
          currentTypeForm === "makeOrder" &&
          (!multiOrderItem || !investedValue)
        ) {
          dispatch(
            currentUserMinusMyThanksCount(thanksPrice * (value.count || 1))
          );
          toast.success(i18n.t("your order is successfully placed"));
        }
        if (currentTypeForm === "makeOrder" && multiOrderItem && investedValue) {
          dispatch(currentUserMinusMyThanksCount(investedValue));
          toast.success(i18n.t("your order is successfully placed"));
        }
        if (currentTypeForm === "changeOrder" && investedValue) {
          dispatch(
            currentUserMinusMyThanksCount(
              prevInvestedValue
                ? investedValue - prevInvestedValue
                : investedValue
            )
          );
          toast.success(i18n.t("your order is successfully placed"));
        }
      }
    );
  };
  