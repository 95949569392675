import { Box } from 'muicomponents/src/Box/Box';
import { styled } from 'muicomponents/src/mui/system';

export const VacanciesHeaderItemBox = styled(Box)({
    display: 'flex',
    gap: 16,
});

export const VacanciesListBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    gap: 24,
    background: '#FFFFFF',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%)',
    borderRadius: 4,
});

export const VacanciesEmptyListTextBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

export const VacanciesHeaderBox = VacanciesListBox;
