import React, { FC, useState, useEffect } from 'react';
import { IBadge, cnProfileBadgeTabList } from '../UserBadges.index';
import { Avatar } from 'muicomponents/src/Avatar';
import { Link } from 'muicomponents/src/Link/Link';
import { BadgeCountChip, Box, Divider, Typography } from 'muicomponents/src';
import { ListItem as ListItemMui } from 'muicomponents/src/ListItem/ListItem';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import BaseRequests from 'utils/src/requests/requests.base';
import { defaultBadgeIconUrl } from 'utils/src/constants.prn';
import { GridItemDescription, GridItemTitle } from '../GridItem/GridItem.styled';
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import { Button } from 'muicomponents/src';
import { Translate } from 'localization';

export const ListItem: FC<{ badge: IBadge; onRemove?: (arg: string) => void }> = ({ badge, onRemove }) => {
    const [badgeCount, setbBdgeCount] = useState(badge.count);

    useEffect(() => {
        setbBdgeCount(badge.count);
    }, [badge.count]);

    return (
        <>
            <ListItemMui alignItems="center" className={cnProfileBadgeTabList('ListItemBox')}>
                <ListItemAvatar>
                    <Link href={badge.url}>
                        <Avatar alt={badge.name} src={badge.badgeImgUrl || BaseRequests.getUrl(defaultBadgeIconUrl)} />
                    </Link>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <GridItemTitle href={badge.url}>
                            <Typography variant="body2">
                                <Tooltip title={badge?.name?.length > 75 ? badge.name : ''}>
                                    <span>{badge.name}</span>
                                </Tooltip>
                            </Typography>
                        </GridItemTitle>
                    }
                    secondary={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                gap: '4px',
                                height: 'auto',
                            }}
                        >
                            <GridItemDescription>
                                <Tooltip title={badge?.description?.length > 160 ? badge.description : ''}>
                                    <span>{badge.description}</span>
                                </Tooltip>
                            </GridItemDescription>
                            {Boolean(badge.count) && badge.count > 1 && <BadgeCountChip countNumber={badgeCount} />}
                        </Box>
                    }
                    // `${badge.description}`}
                />
                {onRemove && (
                    <Button
                        // sx={{ }}
                        onClick={() => onRemove(badge.badgeUid)}
                        variant={'outlined'}
                        color={'primary'}
                        className={cnProfileBadgeTabList('ListItemBox-DeleteButton')}
                        children={<Translate i18nKey={'pryaniky.profile.badge.tab.list.badge.delete'} />}
                    />
                )}
            </ListItemMui>
            <Divider variant="fullWidth" light={true} />
        </>
    );
};
