import { pick } from 'lodash';
import { IStateType } from 'redux/store';
import { VacanciesReducer } from './reducer.vacancies.types';

type ReduxState = IStateType & {
    vacancies: VacanciesReducer;
};

export const getVacanciesReducer = (state: ReduxState) => state.vacancies;

export const getVacanciesReducerFields =
    <Keys extends (keyof VacanciesReducer)[]>(...fields: Keys) =>
    (...args: Parameters<typeof getVacanciesReducer>) => {
        return pick(getVacanciesReducer(...args), fields) as Pick<
            ReturnType<typeof getVacanciesReducer>,
            typeof fields[number]
        >;
    };
